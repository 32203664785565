import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Slide } from '../../types/Slide';
import { Playlist } from '../../types/Playlist';
import { Schedule } from '../../types/Schedule';
import { Unit } from '../../types/Unit';
import { UnitGroup } from '../../types/UnitGroup';
import { Tag } from '../../types/Tag';
import {
  ContentUploadShowProgressModalComponent
} from "./content-upload-modal/content-upload-show-progress-modal.component";

@Injectable({
  providedIn: 'root',
})
/**
 * This service allows you to view progress as you upload content to one or many units. You can do multiple type of uploads as 1 process as well (for example
 * you can upload slides and playlists to many units, and all those actions will be represented by 1 progress bar - useful for setting a schedule that includes
 * slides and playlists)
 */
export class ContentUploadShowProgressModalService {
  constructor(private modalController: ModalController) {}

  showUploadAssetsModal(payload: ContentUploadActionsPayload): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.modalController
        .create({
          component: ContentUploadShowProgressModalComponent,
          componentProps: {
            payload: payload,
          },
          backdropDismiss: false,
        })
        .then((modal) => {
          modal.onDidDismiss().then((result) => {
            resolve(!!result);
          });
          modal.present();
        });
    });
  }
}

export interface ContentUploadActionsPayload {
  modalSettings?: {
    title?: string;
    doneButtonText: string;
    descriptionText: string;
    successMessage: string;
    doneWithErrorsMessage?:string;
  };
  addSlide?: {
    //simply to create a slide but not to upload to any units
    slide: Slide;
  };
  addSlideToPlayer?: {
    slide: Slide;
    playerType: PlayerType;
  };
  editSlide?: {
    //this will auto detect which units have the slide, and update them
    slideUploadPayload: SlideUploadPayload;
  };

  addPlaylist?: {
    playlist: Playlist;
  };
  addPlaylistToPlayer?: {
    playlist: Playlist;
    playerType: PlayerType;
  };
  editPlaylist?: {
    playlist: Playlist;
  };
  setSchedule?: {
    schedule: Schedule;
    playerType: PlayerType;
    slidesNotExpiredReferencedInSchedule: SlideUploadPayload[]; //provided since it's hard to calculate this, and the DHTMLX library does this for us
    playlistsNotExpiredReferencedInSchedule: Playlist[]; //provided since it's hard to calculate this, and the DHTMLX library does this for us
  };
}

export interface PlayerType {
  //the types of "players" either a unit or a virtual unit i.e. a group
  type: 'unit' | 'group';
  object_id?: string;
  object?: Unit | UnitGroup;
}

export interface SlideUploadPayload {
  //slides are sometimes uploaded with only specific media assets (like when editing only 1 file)
  slide: Slide;
  onlyUploadSpecificMedia?: string[] | 'all';
  tags?: Tag[];
}

export interface UploadTarget {
  //where files may get uploaded to
  target_type: 'unit' | 'group' | 'local' | 'local_cloud' | 'public_cloud';
  target_id?: string;
  target?: Unit | UnitGroup | any; //todo remove 'any' and create types for each target type and use here
}

export interface ContentUploadProgressUpdate {
  initialized?: {
    totalRequests: number;
  };
  progress?: {
    uploadTarget: UploadTarget;
    content?: Slide | Playlist | Schedule;
    status: 'started' | 'done' | 'failed';
    message?: string;
  };
  result?: any;
}
