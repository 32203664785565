import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class BetterAlertsService {
  alerts: BetterAlert[] = [];
  maxalerts: number = 20;
  alertsUpdated: BehaviorSubject<BetterAlert[]> = new BehaviorSubject<BetterAlert[]>(undefined);
  defaultIcon: string = 'notifications-outline';
  defaultGroupName: string = 'default';
  defaultCloseButton: boolean = true;
  alertIncrement: number = 0;

  constructor() {}

  // add a alert
  public add(alert: BetterAlert): BetterAlert {
    console.log('add ', alert);

    if (this.alerts.length > this.maxalerts) this.alerts.shift();
    alert.id = this.alertIncrement++;
    if (!alert.group) alert.group = this.defaultGroupName;
    if (!alert.icon) alert.icon = this.defaultIcon;
    alert.time = Date.now();


    alert.timeoutRef = setTimeout(() => {
      console.log('timeout called', alert);
      this.close(alert);
      console.log('this.alerts', this.alerts);
      this.alertsUpdated.next(this.alerts);
    }, alert.duration);

    if (alert.close === undefined) {
      alert.close = this.defaultCloseButton;
    }
    if (alert.reset) {
      this.reset();
    } else {
      if (alert.clear) {
        this.clear(alert.group);
      }
      if (alert.replace) {
        this.remove(alert.label);
      }
    }
    this.alerts.push(alert);
    this.pushUpdate();
    return alert;
  }

  // remove a label
  public remove(label: string): void {
    this.alerts.filter((alert) => alert.label == label).forEach((alert: BetterAlert) => this.removeByAlert(alert));
    this.pushUpdate();
  }

  public close(alert: BetterAlert) {
    alert.closing = true;
    setTimeout(() => {
      this.removeByAlert(alert);
      this.pushUpdate();
    }, 300);
  }

  // remove a group from the queue
  public clear(group: string) {
    this.alerts.filter((alert: BetterAlert) => alert.group == group).forEach((alert: BetterAlert) => this.removeByAlert(alert));
    this.pushUpdate();
  }

  // remove all alerts
  public reset() {
    this.alerts.forEach((alert: BetterAlert) => this.removeByAlert(alert));
    this.pushUpdate();
  }

  public listenToAlerts() {
    return this.alertsUpdated.asObservable();
  }

  public removeByAlert(alert: BetterAlert) {
    if (alert.timeoutRef) {
      clearTimeout(alert.timeoutRef);
    }
    this.alerts = this.alerts.filter((alertFromList) => alert.id !== alertFromList.id);
  }

  public pushUpdate() {
    this.alertsUpdated.next(this.alerts);
  }
}

export interface BetterAlert {
  // appearance
  group?: string; // groups alerts by string
  icon?: string;
  label: string;
  description: string;
  close?: boolean; // offers an option to close the alert
  // operational
  duration?: number; // ms delay before auto close
  color?: string;
  cssClass?: string;
  replace?: boolean; // removes any alert with the same label before its added
  clear?: boolean; // clears the group queue
  reset?: boolean; // resets the queue
  // programmatically added
  time?: number; // ms timestamp of event
  timeoutRef?: ReturnType<typeof setTimeout>;
  id?: number;
  closing?: boolean;
}
