import { Component, OnInit } from '@angular/core';
import { ImportTrackService } from '../../../services/editor/import-track.service';
import { Platform } from '@ionic/angular';
import { EditorService } from '../../../services/editor/editor.service';
import { DebugLogService } from '../../../services/debug-log.service';
import { FileTypeDialog } from '../../../common/global-constants';

@Component({
  selector: 'app-import-track',
  templateUrl: './import-track.component.html',
  styleUrls: ['./import-track.component.scss'],
})
export class ImportTrackComponent implements OnInit {
  song: any;
  editor: any;

  constructor(
    private importTrackService: ImportTrackService,
    public platform: Platform,
    private editorService: EditorService,
    private debugLogService: DebugLogService
  ) {}

  ngOnInit() {
    this.song = this.importTrackService.getSong();
    this.editor = this.editorService.getEditor();
  }

  async importSong(event?: any) {
    /* TODO if (this.platform.is('electron')) {
      this.fileSystemProvider
        .pickFilePathDialog(FileTypeDialog.AUDIO)
        .then((path) => {
          if (path) {
            // false if clicked on cancel
            let i = path.lastIndexOf('\\');
            let name = path.slice(i + 1);
            this.song = {
              name: name,
              src: path,
              loop: true,
              playing: false,
            };
            this.importTrackService.setSong(this.song);
            this.addAudioComponent();
            this.editor.registerAssetUpload(this.song.src);
          }
        })
        .catch((e) => console.log(e));
    } else {
      // web ionic app - ionic serve - for test
      let file = event.srcElement.files[0];
      this.song = {
        name: file.name,
        src: './../../../assets/media/Post Malone - Circles.mp3',
        loop: true,
        playing: false,
      };
      this.importTrackService.setSong(this.song);
      this.addAudioComponent();
    }*/
  }

  //adding audio to editor
  addAudioComponent() {
    let importedTrack = this.editor.getWrapper().find('#importedTrack');
    if (!importedTrack.length) {
      let loop = this.song.loop ? 'loop' : '';
      //autoplay attribut is added just before sending the slide to the unit (slide unit service - uploadSlideToUnit)
      this.editor.addComponents(`<audio id="importedTrack" src="${this.song.src}" ${loop} autoplay></audio>`);
    }
  }

  setLoop(): void {
    let importedTrack = this.editor.getWrapper().find('#importedTrack');
    let attributes = importedTrack[0].getAttributes();
    if (this.song.loop) {
      delete attributes.loop;
    } else {
      attributes['loop'] = 'loop';
    }
    importedTrack[0].setAttributes(attributes);
    this.song.loop = !this.song.loop;
  }

  removeSong() {
    this.editor.unregisterAssetUpload(this.song.src);
    this.song = undefined;
    this.importTrackService.setSong(this.song);
    //remove audio component from editor
    let importedTrack = this.editor.getWrapper().find('#importedTrack');
    if (importedTrack.length) importedTrack[0].remove();
  }
}
