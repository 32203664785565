import { Component, OnInit } from '@angular/core';
import { isoDatetimeToString, isUnit, Unit } from '../../../../types/Unit';
import { isUnitGroup, UnitGroup } from '../../../../types/UnitGroup';
import { ModalController, NavParams } from '@ionic/angular';
import { AlertService } from '../../../alert.service';
import { Slide } from '../../../../types/Slide';
import { cities } from '../../../../../assets/data/cities-array';
import { AppErrors } from '../../../../types/AppError';
import { AppSettingKey } from '../../../../common/global-constants';
import {AppSettingsService} from "../../../app-settings.service";
import {UnitsService} from "../../../units.service";
import {API789Service} from "../../../api-789.service";
import {UnitGroupsService} from "../../../unitGroups.service";
import {TranslateService} from "@ngx-translate/core";
import {UnitTimezoneService} from "../../unit-timezone.service";
import {PlayableContentSelectorModalService} from "../../../schedules/playable-content-selector-modal.service";
import {ContentUploadShowProgressModalService} from "../../content-upload-show-progress-modal.service";


@Component({
  selector: 'edit-add-unit-modal',
  templateUrl: './edit-unit-modal.component.html',
  styleUrls: ['./edit-unit-modal.component.scss'],
})
export class EditUnitModalComponent implements OnInit {
  unit: Unit;
  editingUnit: Unit;
  unitGroup: UnitGroup;
  currentTab: string = 'general';
  isSaving = false;
  showSearchTimezone = false;
  timezoneSearchTerms = '';
  timezoneSearchResults: CityData[] = [];
  cities: CityData[] = cities;
  unitDateTime: any;
  syncingDatetime = false;
  showPassword: boolean = false;

  constructor(
      private navParams: NavParams,
      private modalController: ModalController,
      private appSettings: AppSettingsService,
      private alertService: AlertService,
      private unitsService: UnitsService,
      private playableContentSelectorModalService: PlayableContentSelectorModalService,
      private contentUploadShowProgressModalService: ContentUploadShowProgressModalService,
      private api_789: API789Service,
      private unitGroupsService: UnitGroupsService,
      private translateService: TranslateService
  ) {}

  ngOnInit() {
    this.unit = new Unit('', '', 'admin', 'admin', '', '', true);
  }

  ionViewWillEnter() {
    if (this.navParams.get('unit')) {
      this.editingUnit = this.navParams.get('unit');
      this.unit = this.editingUnit;

      //get unit group info
      if (this.unit.group_id) {
        this.unitGroupsService.getUnitGroup(this.unit.group_id).then((res) => {
          this.unitGroup = res;
        });
      }

      //get time info
      this.api_789.getUnitInfo(this.unit).then((res) => {
        let unitInfo = JSON.parse(res.status_message);
        this.unit.timezone = this.editingUnit.timezone = unitInfo.timezone;
        this.unitDateTime = isoDatetimeToString(unitInfo.datetime, this.unit.timezone);
      });
    } else {
      this.unit.dhcp_on = false;
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  public showHidePass(): void {
    this.showPassword = !this.showPassword;
  }

  saveUnit() {
    let unitToSave = new Unit(
      this.unit.id,
      this.unit.ip,
      this.unit.username,
      this.unit.password,
      this.unit.name,
      '',
      this.unit.dhcp_on,
      this.unit.network_mask,
      this.unit.network_gateway,
      this.unit.dns_primary,
      this.unit.dns_secondary,
      this.unit.timezone,
      undefined,
      this.unit.location
    );

    unitToSave.id = this.editingUnit.id;
    if (this.editingUnit.group_id) {
      unitToSave.group_id = this.editingUnit.group_id;
    }

    this.isSaving = true;
    return this.unitsService.saveUnit(unitToSave).then(() => {}).catch(() => {
    }).finally(() => {
      this.isSaving = false;

      this.saveTimeSettings(unitToSave);
      this.modalController.dismiss();
    });
  }

  selectedTabChanged($event) {
    this.currentTab = $event.detail.value;
  }

  blinkUnit() {
    this.isSaving = true;

    this.api_789
        .setScreenBlink(this.editingUnit, true, '')
        .then((res) => {
          setTimeout(() => {
            this.api_789
                .setScreenBlink(this.editingUnit, false, '')
                .then((res) => {
                  this.isSaving = false;
                })
                .catch((e) => {
                  this.isSaving = false;
                });
          }, 10000);
        })
        .catch((e) => {
          this.isSaving = false;
        });
  }

  setDefaultScreen() {
    this.isSaving = true;

    this.playableContentSelectorModalService
        .showSelectPlayableContentModal(true)
        .then(async (slide: Slide) => {
          if (slide === null) {
            this.isSaving = false;
          } else {
            try {
              await this.contentUploadShowProgressModalService
                .showUploadAssetsModal({
                  modalSettings: {
                    title: this.translateService.instant('Slides.PreparingToPlay'),
                    descriptionText: this.translateService.instant('Slides.CheckingIfUnitsNeedTheContent'),
                    successMessage: this.translateService.instant('Slides.UnitsReady'),
                    doneButtonText: this.translateService.instant('General.Play'),
                  },
                  addSlideToPlayer: {
                    slide: slide,
                    playerType: {
                      'type': 'unit',
                      'object': this.editingUnit,
                    },
                  },
                });
            } catch (e) {}


            this.api_789
                .setDefaultScreen(this.editingUnit, slide)
                .then((res: any) => {
                  console.log('res', res);
                  this.isSaving = false;

                  if (res?.status === 'success') {
                    this.alertService.alert(this.translateService.instant('General.Success'), this.translateService.instant('Units.AddUnitModal.DefaultScreenSet'));
                  } else {
                    this.alertService.alert(this.translateService.instant('General.Error') + AppErrors.U_SC001.code, this.translateService.instant('AppErrors.' + AppErrors.U_SC001.title));
                    console.error(new Error(AppErrors.U_SC001.code));
                  }
                })
                .catch((e) => {
                  console.log('e', e);
                  this.isSaving = false;
                  this.alertService.alert(this.translateService.instant('General.Error') + AppErrors.U_SC001.code, this.translateService.instant('AppErrors.' + AppErrors.U_SC001.title));
                  console.error(new Error(AppErrors.U_SC001.code));
                });
          }
        })
        .catch((e) => {
          this.isSaving = false;
          console.log('eerr ', e);
        });
  }

  saveTimeSettings(unitToSave) {
    this.api_789.setTimezone(unitToSave).then(res => {
      console.log('res', res);
    }).catch((err) => {
      console.log(err);
    });
  }

  syncDateTime() {
    this.syncingDatetime = true;
    this.api_789
        .setCurrentTime(this.editingUnit, new Date().toISOString())
        .then(() => {
          this.api_789
              .getUnitInfo(this.unit)
              .then((res) => {
                let unitInfo = JSON.parse(res.status_message);
                this.syncingDatetime = false;
                this.unitDateTime = isoDatetimeToString(unitInfo.datetime, this.unit.timezone);
              })
              .catch((err) => {
                this.syncingDatetime = false;
              });
        })
        .catch((err) => {
          this.syncingDatetime = false;
          console.log(err);
        });
  }

  trackTimeZoneSearchResults(index, city) {
    return city.city_ascii;
  }

  searchTimezone($event, timezoneSearchTerms) {
    this.timezoneSearchResults = UnitTimezoneService.searchTimezone($event, timezoneSearchTerms);
  }

  selectTimezone(city: CityData) {
    this.showSearchTimezone = false;
    this.unit.timezone = city.timezone;
  }

  rotate() {
    this.api_789.changeDisplayOrientation(this.editingUnit);
  }

}

interface CityData {
  city: string;
  city_ascii: string;
  country: string;
  province: string;
  timezone: string;
}
