import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SecondsToHoursMinutesSecondsPipe } from '../seconds-to-hours-minutes-seconds.pipe';
import { PrettyTimePipe } from '../pretty-time.pipe';
import { TargetDictionaryKeywordPipe } from '../target-dictionary-keyword.pipe';

@NgModule({
  declarations: [SecondsToHoursMinutesSecondsPipe, PrettyTimePipe, TargetDictionaryKeywordPipe],
  imports: [CommonModule],
  exports: [SecondsToHoursMinutesSecondsPipe, PrettyTimePipe, TargetDictionaryKeywordPipe],
})
export class CustomPipesModule {}
