import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { Slide } from '../../../../types/Slide';
import { Playlist } from '../../../../types/Playlist';
import {SlidesService} from "../../../slides.service";
import {PlaylistsService} from "../../../playlists.service";

@Component({
  selector: 'app-playable-content-selector-modal',
  templateUrl: './playable-content-selector-modal.component.html',
  styleUrls: ['./playable-content-selector-modal.component.scss'],
})
export class PlayableContentSelectorModalComponent implements OnInit {
  currentTab = 'slides';
  selectedItem: any = {
    item: null,
    type: null,
  };
  slides: any;
  playlists: any;
  showSlidesOnly = false;

  constructor(private modalController: ModalController, private slidesService: SlidesService, private playlistsService: PlaylistsService, private navParams: NavParams) {
    this.getSlides();
    this.getPlaylists();
  }

  ngOnInit() {}

  ionViewDidEnter() {
    if (this.navParams.get('slidesOnly')) {
      this.showSlidesOnly = this.navParams.get('slidesOnly');
    }
  }

  selectedTabChanged($event) {
    this.currentTab = $event.detail.value;
  }

  getSlides() {
    this.slidesService.getAllSlidesUnwatched().then((slides) => {
      this.slides = slides;
    });
  }

  getPlaylists() {
    this.playlistsService.getAllPlaylistsUnwatched().then((playlists) => {
      this.playlists = playlists;
    });
  }

  selectItem(type, item) {
    this.selectedItem.type = type;
    this.selectedItem.item = item;
  }

  isItemSelected(type, item) {
    if (this.selectedItem.type == type && this.selectedItem.item && this.selectedItem.item.id == item.id) {
      return true;
    }
    return false;
  }

  dismiss(data): null | {} | { id: string; type: string; item: Slide | Playlist } {
    if (data) {
      return this.modalController.dismiss(data);
    }

    if (!!this.selectedItem.item && !!this.selectedItem.item.id && !!this.selectedItem.type) {
      return this.modalController.dismiss(this.selectedItem);
    }

    return this.modalController.dismiss(null);
  }
}
