import { Component, OnInit } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { isSlide, Slide } from '../../../types/Slide';
import { isPlaylist, Playlist } from '../../../types/Playlist';
import { isSchedule, Schedule } from '../../../types/Schedule';
import { ContentUploadActionsPayload, ContentUploadProgressUpdate } from '../content-upload-show-progress-modal.service';
import {SchedulesService} from "../../schedules.service";
import {SlidesService} from "../../slides.service";
import {PlaylistsService} from "../../playlists.service";
import {TranslateService} from "@ngx-translate/core";
import {AngularFireAnalytics} from "@angular/fire/compat/analytics";

@Component({
  selector: 'app-upload-assets-modal',
  templateUrl: './content-upload-show-progress-modal.component.html',
  styleUrls: ['./content-upload-show-progress-modal.component.scss'],
})
export class ContentUploadShowProgressModalComponent implements OnInit {
  payload: ContentUploadActionsPayload;

  messages: ContentUploadProgressUpdate[] = [];
  percent: number = 0;
  buffer: number = 0.75;
  startTime: string;
  endTime: string;
  totalUploads: number = 0;
  totalCompletedUploads: number = 0;
  hasErrors = false;
  hasBandwidthInsufficientError = false;
  taskStatuses: { string: boolean }[] = [];

  constructor(
    private schedulesService: SchedulesService,
    private modalController: ModalController,
    private slidesService: SlidesService,
    private playlistsService: PlaylistsService,
    private navParams: NavParams,
    private fireAnalytics: AngularFireAnalytics,
    private translateService: TranslateService
  ) {

  }

  ngOnInit() {
    if (this.navParams.get('payload')) {
      this.payload = this.navParams.get('payload');
      console.log(' this.payload ', this.payload);
    } else {
      this.modalController.dismiss(false);
      return false;
    }

    const defaultModalSettings = {
      title: '',
      doneButtonText: this.translateService.instant('General.Done'),
      descriptionText: this.translateService.instant('General.Saving'),
      successMessage: this.translateService.instant('General.Success'),
      doneWithErrorsMessage: this.translateService.instant('General.Done'),
    };
    if (!this.payload.modalSettings) {
      this.payload.modalSettings = defaultModalSettings;
    }
    this.payload.modalSettings = { ...defaultModalSettings, ...this.payload.modalSettings };

    let isValidPayload = false;


    if (this.payload.addSlide && this.payload.addSlide.slide && isSlide(this.payload.addSlide.slide)) {
      isValidPayload = true;
      this.registerTask('addSlide');
    }

    if (this.payload.addSlideToPlayer && this.payload.addSlideToPlayer.slide && isSlide(this.payload.addSlideToPlayer.slide)) {
      isValidPayload = true;
      this.registerTask('addSlideToPlayer');
    }

    if (this.payload.editSlide && this.payload.editSlide.slideUploadPayload && this.payload.editSlide.slideUploadPayload.slide && isSlide(this.payload.editSlide.slideUploadPayload.slide)) {
      console.log('payload is editslide');
      isValidPayload = true;
      this.registerTask('editSlide');
    }

    if (this.payload.addPlaylist && this.payload.addPlaylist.playlist && isPlaylist(this.payload.addPlaylist.playlist)) {
      isValidPayload = true;
      this.registerTask('addPlaylist');
    }

    if (this.payload.addPlaylistToPlayer && this.payload.addPlaylistToPlayer.playlist && isPlaylist(this.payload.addPlaylistToPlayer.playlist)) {
      isValidPayload = true;
      this.registerTask('addPlaylistToPlayer');
    }

    if (this.payload.editPlaylist && this.payload.editPlaylist.playlist && isPlaylist(this.payload.editPlaylist.playlist)) {
      isValidPayload = true;
      this.registerTask('editPlaylist');
    }

    if (this.payload.setSchedule && isSchedule(this.payload.setSchedule.schedule)) {
      isValidPayload = true;
      this.registerTask('setSchedule');
    }

    if (!isValidPayload) {
      this.modalController.dismiss(false);
    } else {
      this.doUpload();
    }


  }

  doUpload() {
    this.startTime = this.formatTime(new Date());

    //handle the different actions that could be

    //setting a schedule
    if (this.payload.setSchedule && isSchedule(this.payload.setSchedule.schedule)) {
      this.schedulesService
        .uploadScheduleForPlayerType(
          this.payload.setSchedule.playerType,
          this.payload.setSchedule.schedule.dhtmlx_json_data,
          this.payload.setSchedule.slidesNotExpiredReferencedInSchedule,
          this.payload.setSchedule.playlistsNotExpiredReferencedInSchedule,
          this.payload.setSchedule.schedule.draft
        )
        .subscribe(
          (progressMessage: ContentUploadProgressUpdate) => {
            this.handleProgressMessage(progressMessage);
          },
          (e) => {
            console.log('e', e);
            //error
            if (e && e.toString) {
              //this.messages.push(e.toString());//TODO show this error
              console.warn('contentupload uploadScheduleForPlayerType failed ', e);

            }
          },
          () => {
            //done
            this.markTaskAsDone('setSchedule');
            this.doneActions();
          }
        );
    }

    if (this.payload.addSlide && this.payload.addSlide.slide) {
      this.slidesService.addSlide(this.payload.addSlide.slide).subscribe(
        (progressMessage: ContentUploadProgressUpdate) => {
          this.handleProgressMessage(progressMessage);
        },
        (e) => {
          //error
          console.warn('contentupload addslide failed ', e);

          if (e && e.toString) {
            //this.messages.push(e.toString());//TODO show this error
          }
        },
        () => {
          //done
          this.markTaskAsDone('addSlide');
          this.doneActions();
        }
      );
    }

    if (this.payload.addSlideToPlayer && this.payload.addSlideToPlayer.slide && isSlide(this.payload.addSlideToPlayer.slide)) {
      this.slidesService.addSlideToPlayer(this.payload.addSlideToPlayer.slide, this.payload.addSlideToPlayer.playerType).subscribe(
        (progressMessage: ContentUploadProgressUpdate) => {
          this.handleProgressMessage(progressMessage);
        },
        (e) => {
          //error
          if (e && e.toString) {
            //this.messages.push(e.toString());//TODO show this error
            console.warn('contentupload addSlideToPlayer failed ', e);

          }
        },
        () => {
          //done
          this.markTaskAsDone('addSlideToPlayer');
          this.doneActions();
        }
      );
    }

    if (this.payload.editSlide && this.payload.editSlide.slideUploadPayload && this.payload.editSlide.slideUploadPayload.slide && isSlide(this.payload.editSlide.slideUploadPayload.slide)) {
      this.slidesService.updateSlide(this.payload.editSlide.slideUploadPayload.slide).subscribe(
        (progressMessage: ContentUploadProgressUpdate) => {
          this.handleProgressMessage(progressMessage);
        },
        (e) => {
          //error
          console.warn('updateSlide failed ', e);

          if (e && e.toString) {
            this.messages.push(e.toString());//TODO show this error

          }
        },
        () => {
          //done
          this.markTaskAsDone('editSlide');
          this.doneActions();
        }
      );
    }

    if (this.payload.addPlaylist && this.payload.addPlaylist.playlist && isPlaylist(this.payload.addPlaylist.playlist)) {
      this.playlistsService.addPlaylist(this.payload.addPlaylist.playlist).subscribe(
        (progressMessage: ContentUploadProgressUpdate) => {
          this.handleProgressMessage(progressMessage);
        },
        (e) => {
          //error
          if (e && e.toString) {
            //this.messages.push(e.toString());//TODO show this error
          }
        },
        () => {
          //done
          this.markTaskAsDone('addPlaylist');
          this.doneActions();
        }
      );
    }

    if (this.payload.addPlaylistToPlayer && this.payload.addPlaylistToPlayer.playlist && isPlaylist(this.payload.addPlaylistToPlayer.playlist)) {
      this.playlistsService.addPlaylistToPlayer(this.payload.addPlaylistToPlayer.playlist, this.payload.addPlaylistToPlayer.playerType).subscribe(
        (progressMessage: ContentUploadProgressUpdate) => {
          this.handleProgressMessage(progressMessage);
        },
        (e) => {
          //error
          if (e && e.toString) {
            //this.messages.push(e.toString());//TODO show this error
          }
        },
        () => {
          //done
          this.markTaskAsDone('addPlaylistToPlayer');
          this.doneActions();
        }
      );
    }

    if (this.payload.editPlaylist && this.payload.editPlaylist.playlist && isPlaylist(this.payload.editPlaylist.playlist)) {
      this.playlistsService.updatePlaylist(this.payload.editPlaylist.playlist).subscribe(
        (progressMessage: ContentUploadProgressUpdate) => {
          this.handleProgressMessage(progressMessage);
        },
        (e) => {
          //error
          if (e && e.toString) {
            //this.messages.push(e.toString());//TODO show this error
          }
        },
        () => {
          //done
          this.markTaskAsDone('editPlaylist');
          this.doneActions();
        }
      );
    }
  }

  handleProgressMessage(progressMessage: ContentUploadProgressUpdate) {
    if (progressMessage.initialized && progressMessage.initialized.totalRequests) {
      //message returned on init
      this.totalUploads += progressMessage.initialized.totalRequests;
    }

    if (progressMessage.progress) {
      //message returned on upload complete

      (<any>progressMessage).messageTime = this.formatTime(new Date());

      this.messages.unshift(progressMessage);

      if (progressMessage.progress.status === 'done' || progressMessage.progress.status === 'failed') {
        this.totalCompletedUploads++;
      }

      if (progressMessage.progress.status === 'failed') {
        this.hasErrors = true;

        if (typeof progressMessage.progress.message !== 'undefined' && progressMessage.progress.message.toLowerCase().includes('bandwidth')) {
          this.hasBandwidthInsufficientError = true;

          try {
            this.fireAnalytics.logEvent('reachedBandwidthLimit');
          } catch (e) {}
        }
      }

      if (this.totalUploads > 0) {
        this.percent = this.totalCompletedUploads / this.totalUploads;
      }
    }
  }

  doneActions() {
    //mark the end time
    this.endTime = this.formatTime(new Date());
  }

  dismiss() {
    this.modalController.dismiss(true);
  }

  formatTime(date) {
    return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0') + ':' + date.getSeconds().toString().padStart(2, '0');
  }

  allDone(): boolean {
    let allDone = true;

    for (let key in this.taskStatuses) {
      if (this.taskStatuses.hasOwnProperty(key)) {
        if (!this.taskStatuses[key]) {
          allDone = false;
        }
      }
    }

    return allDone;
  }

  registerTask(task: string) {
    this.taskStatuses[task] = 'started';
  }

  markTaskAsDone(task: string) {
    this.taskStatuses[task] = 'done';
  }

  isTaskRegistered(task: string) {
    if (typeof this.taskStatuses[task] === 'undefined') {
      return true;
    }

    return false;
  }

  isTaskPending(task: string) {
    if (typeof this.taskStatuses[task] === 'undefined' || this.taskStatuses[task] === 'done') {
      return false;
    }

    return true;
  }

  getContentType(content: any): 'slide' | 'playlist' | 'schedule' | '' {
    if (isSlide(content)) {
      return 'slide';
    }
    if (isPlaylist(content)) {
      return 'playlist';
    }
    if (isSchedule(content)) {
      return 'schedule';
    }
    return '';
  }
}
