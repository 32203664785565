import { Pipe, PipeTransform } from '@angular/core';
import {KeywordDictionaryEntry, KeywordDictionaryTarget} from "../types/KeywordDictionary";

@Pipe({
  name: 'targetDictionaryKeyword',
  pure: false,
})
export class TargetDictionaryKeywordPipe implements PipeTransform {
  transform(items: KeywordDictionaryEntry[], filter: { target: keyof typeof KeywordDictionaryTarget; target_id?: string }): any {
    return items.filter((item) => item.dictionary_target === filter.target && item.dictionary_target_id === filter.target_id);
  }
}
