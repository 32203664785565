import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from '../services/alert.service';
import { EditorService } from '../services/editor/editor.service';
import {TranslateService} from "@ngx-translate/core";

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LeaveEditorGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private alertService: AlertService, private editorService: EditorService, private translateService: TranslateService) {}

  async canDeactivate(component: CanComponentDeactivate, route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var isSaved = await this.editorService.isSaved(route.paramMap.get('id'));
    var leave: boolean;

    //skips confirm pop up if a slide was deleted
    if (this.editorService.deleteSlide) return true;
    if (isSaved) {
      leave = await this.alertService.confirm(this.translateService.instant('Editor.LeaveEditor'), '', this.translateService.instant('Common.Yes'), this.translateService.instant('Common.No'));
      return leave;
    } else {
      leave = await this.alertService.confirm(
        this.translateService.instant('Editor.AreYouSure'),
        this.translateService.instant('Editor.LeaveWithoutSaving'),
        this.translateService.instant('Common.Yes'),
        this.translateService.instant('Common.No')
      );
    }
    return leave;
  }
}
