import { Component, OnInit } from '@angular/core';
import {ModalController} from "@ionic/angular";
import {UnitsService} from "../../units.service";
import {SubscriptionsService} from "../../subscriptions.service";
import {WorkspacesService} from "../../workspaces.service";
import {Router} from "@angular/router";
import {AngularFireAnalytics} from "@angular/fire/compat/analytics";
import {API789Service} from "../../api-789.service";
import {Unit} from "../../../types/Unit";
import {DistributorLicenseKey} from "../../../types/DistributorLicenseKey";

@Component({
  selector: 'app-add-unit-modal',
  templateUrl: './add-unit-modal.component.html',
  styleUrls: ['./add-unit-modal.component.scss'],
})
export class AddUnitModalComponent implements OnInit {

  pairingCode: string;
  isLoading: boolean = false;
  billingEnabled: boolean = true;

  licenseKeysInWorkspace: DistributorLicenseKey[] = [];
  licenseKey: string;
  licenseKeyIsValid: boolean = undefined;
  isRefreshingLicenseKeys = true;

  numUnitsInWorkspace: number = 0;

  constructor(private modalController: ModalController,
              private router: Router,
              private workspacesService: WorkspacesService,
              private api_789: API789Service,
              private fireAnalytics: AngularFireAnalytics,
              private unitService: UnitsService) { }

  ngOnInit() {}

  ionViewWillEnter() {
    this.billingEnabled = this.workspacesService.getCurrentWorkspace().billingEnabled;

    //sometimes the workspace object hasn't been refreshed yet even though billing was recently enabled, so we need to listen for changes
    this.workspacesService.listenToWorkspaceChanges().subscribe(resWorkspace => {
      this.billingEnabled = this.workspacesService.getCurrentWorkspace().billingEnabled;
    });
    this.workspacesService.refreshCurrentWorkspace();

    this.refreshWorkspaceLicenseKeys();
    this.unitService.getAllUnitsUnwatched().then(res => {
      this.numUnitsInWorkspace = res.length;
    });
  }

  refreshWorkspaceLicenseKeys() {
    this.isRefreshingLicenseKeys = true;
    this.unitService.getLicenseKeysAppliedToWorkspace().then(res => {
      this.licenseKeysInWorkspace = res;
      this.isRefreshingLicenseKeys = false;
    });
  }

  dismissModal(unit?:Unit) {
    this.modalController.dismiss(unit);
  }

  save() {
    this.isLoading = true;
    this.unitService.pairUnit(this.pairingCode.replace(/\s/g, '')).then((resUnitID) => {
      try {
        this.fireAnalytics.logEvent('pairedUnit', {unitID: resUnitID});
      } catch (e) {}

      //post-pairing actions
      this.unitService.getUnit(resUnitID).then(resUnit => {
        let allPromises = [];
        try {//set time
          allPromises.push(this.api_789.setCurrentTime(resUnit, new Date().toISOString()));
        } catch (err) {}

        try {//set timezone
          resUnit.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          allPromises.push(this.api_789.setTimezone(resUnit));
        } catch (err) {}

        Promise.all(allPromises).then().finally(() => {
          this.isLoading = false;
          this.api_789.doReboot(resUnit);
          this.dismissModal(resUnit);
        });

      }).catch(err => {});


    }).catch(err => {
      this.isLoading = false;
      console.log('err', err);
      this.dismissModal();
    });

  }

  goToBillingPage() {
    this.router.navigateByUrl('/billing');
    this.dismissModal();
  }

  checkLicenseKey() {
    return this.unitService.checkLicenseKeyValidity(this.licenseKey).then(res => {
      console.log('res', res);
      this.licenseKeyIsValid = (res === 'available');
      if (this.licenseKeyIsValid) {
        this.unitService.applyLicenseKeysToWorkspace([this.licenseKey]).then(res => {
          this.refreshWorkspaceLicenseKeys();
        });
      }
    });
  }

}
