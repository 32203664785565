import {Component, OnInit, Type} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppComponent } from '../../../app.component';
import { TemplatesService } from '../../../services/editor/templates.service';
import { Template } from '../../../types/Template';
import { EditorService } from '../../../services/editor/editor.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {TranslateService} from "@ngx-translate/core";
import {StaticInjector} from "../../../utils/StaticInjector";
import {SlidePreviewComponent} from "../../../pages/editor/slide-preview/slide-preview.component";

@Component({
  selector: 'app-tutorials',
  templateUrl: './tutorials.component.html',
  styleUrls: ['./tutorials.component.scss'],
})
export class TutorialsComponent implements OnInit {
  static editor: any;
  templates: any[] = [];

  constructor(
    public modalCtrl: ModalController,
    private editorService: EditorService,
    private templatesServices: TemplatesService,
    private sanitizer: DomSanitizer,
    private translateService: TranslateService
  ) {
  }

  static openModal() {
    const modalController = StaticInjector.getInjector().get<ModalController>(ModalController as Type<ModalController>)
    modalController
      .create({
        component: TutorialsComponent,
        cssClass: 'templates-modal',
      })
      .then((modal) => {
        modal.present().then(() => {
          modal.onDidDismiss().then((data) => {});
        });
      });
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.loadTutorials();
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  loadTutorials() {
    this.templatesServices
      .getTemplates()
      .then((templatesSource) => {
        this.templates = templatesSource.filter((template) => {
          return template.isTutorial;
        });
      })
      .catch((e) => {
        console.error('Failed to load templates');
      });
  }

  async loadTutorial(template: Template) {
    let slideData = {};

    slideData['dimensions'] = [1920, 1080];
    if (template.dimensions) {
      slideData['dimensions'] = template.dimensions;
    }

    try {
      let slideHTML = await this.editorService.generateSlideRenderedHTML(
        template.html,
        template.css,
        slideData,
        { target: this.translateService.instant('Slides.Slide').toUpperCase(), target_id: '1' },
        'preview'
      );

      this.modalCtrl.create({
        component: SlidePreviewComponent,
        cssClass: 'slide-preview-modal',
        animated: false,
        componentProps: {
          editor: {},
          slideData: slideData,
          html: slideHTML,
          css: template.css,
        },
      }).then(modal => {
        modal.present();
      });

    } catch (e) {
      console.log('failed to preview ', e);
    }

    return this.modalCtrl.dismiss(template);
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
}
