import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AlertController} from "@ionic/angular";
import {AlertService} from "./alert.service";
import {TranslateService} from "@ngx-translate/core";
import {FireSessionService} from "./fire-session.service";
import {Playlist} from "../types/Playlist";
import {AngularFirestoreCollection} from "@angular/fire/compat/firestore/collection/collection";
import {Slide} from "../types/Slide";
import {Unit} from "../types/Unit";
import {
  ContentUploadProgressUpdate,
  PlayerType,
  SlideUploadPayload
} from "./units/content-upload-show-progress-modal.service";
import {Observable, Subject} from "rxjs";
import {SlidesService} from "./slides.service";
import {UploadTask} from "./schedules.service";
import {API789Service} from "./api-789.service";
import {getIndividualUnitsFromObjectList, UnitsService} from "./units.service";

@Injectable({
  providedIn: 'root'
})
export class PlaylistsService {

  constructor(private fireDB: AngularFirestore,
              private fireAuth: AngularFireAuth,
              private alertCtrl: AlertController,
              private alertService: AlertService,
              private api_789: API789Service,
              private unitsService: UnitsService,
              private slidesService: SlidesService,
              private translate: TranslateService,
              private fireSessionService: FireSessionService) {

  }

  getPlaylist(id):Promise<Playlist> {
    return this.fireDB.doc<Playlist>(this.getPathToPlaylistInWorkspace(id)).get().toPromise().then(res => {
      if (res.data()) {
        let data = res.data();
        return Promise.resolve(this.fireSessionService.deserializePlaylist(data));
      } else {
        return Promise.reject();
      }
    }).catch(err => {
      return Promise.reject();
    });
  }

  deletePlaylist(Playlist: Playlist) {
    return this.fireDB.doc(this.getPathToPlaylistInWorkspace(Playlist.id)).delete();
  }

  addPlaylist(playlist: Playlist): Observable<ContentUploadProgressUpdate> {
    playlist.modified = Date.now();
    let progressUpdateSubject = new Subject<ContentUploadProgressUpdate>();

    setTimeout(() => {
      progressUpdateSubject.next({ initialized: { totalRequests: 1 } });

      progressUpdateSubject.next({
        progress: {
          status: 'started',
          content: playlist,
          uploadTarget: { target_type: 'local' },
        },
      });

      try {//we don't want to store the actual slide objects here
        delete playlist['slides'];
      } catch (e) {}


      this.fireDB.doc(this.getPathToPlaylistInWorkspace(playlist.id)).set(this.fireSessionService.serializeObject(playlist))
        .then((res) => {
          progressUpdateSubject.next({
            result: playlist,
            progress: {
              status: 'done',
              content: playlist,
              uploadTarget: { target_type: 'local' },
            },
          });
          progressUpdateSubject.complete();
        }).catch(
        (err) => {
          progressUpdateSubject.next({
            result: err,
            progress: {
              status: 'failed',
              content: playlist,
              uploadTarget: { target_type: 'local' },
            },
          });
          progressUpdateSubject.complete();
        }
      );
    });

    return progressUpdateSubject;
  }

  updatePlaylist(playlist: Playlist): Observable<ContentUploadProgressUpdate> {
    playlist.modified = Date.now();
    let progressUpdateSubject = new Subject<ContentUploadProgressUpdate>();

    let totalRequests = 1; //for local storage is at least 1

    let unitsHavingPlaylist: Unit[] = [];

    this.getUnitsHavingPlaylist(playlist.id).then((units) => {
      console.log('units', units);
      if (units && units.length) {
        unitsHavingPlaylist = units;
      }
      totalRequests += unitsHavingPlaylist.length; //the playlist itself

      totalRequests += unitsHavingPlaylist.length * playlist.getSlides().length; //each slide needs an upload too (potentially)

      progressUpdateSubject.next({ initialized: { totalRequests: totalRequests } });

      let playlistToSaveToCloudDB = JSON.parse(JSON.stringify(playlist));
      try {//we don't want to store the actual slide objects here
        delete playlistToSaveToCloudDB['slides'];
      } catch (e) {}

      this.fireDB.doc(this.getPathToPlaylistInWorkspace(playlist.id)).set(this.fireSessionService.serializeObject(playlistToSaveToCloudDB))
        .then(() => {
          progressUpdateSubject.next({
            progress: {
              status: 'done',
              uploadTarget: { target_type: 'local' },
              content: playlist,
              message: 'Saved successfully',
            },
          });
          //upload to units if necessary

          new Promise((resolveSlidesUploaded) => {
            if (playlist.getSlides().length) {
              let slides = playlist.getSlides();

              this.slidesService.publishSlidesToUnits(units, false, slides).subscribe(
                (progress) => {
                  //when each upload has news (started, completed)
                  progressUpdateSubject.next(progress);
                },
                (err) => {
                  progressUpdateSubject.next(err);
                },
                () => {
                  resolveSlidesUploaded(true);
                }
              );
            } else {
              resolveSlidesUploaded(true);
            }
          }).then(() => {
            return new Promise((resolveUnitsUploaded) => {
              //upload slide to any units necessary, if necessary

              if (unitsHavingPlaylist.length) {
                this.publishPlaylistsToUnits(units, [playlist]).subscribe(
                  (progress) => {
                    //when each upload has news (started, completed)
                    progressUpdateSubject.next(progress);
                  },
                  (err) => {
                    progressUpdateSubject.next(err);
                  },
                  () => {
                    resolveUnitsUploaded(true);
                  }
                );
              } else {
                //no units to upload to
                resolveUnitsUploaded(true);
              }
            }).then((res) => {
              //return results and complete
              progressUpdateSubject.next({ result: playlist });
              progressUpdateSubject.complete();
            });
          });
        }).catch((err) => {
        progressUpdateSubject.next({
          progress: {
            status: 'failed',
            uploadTarget: { target_type: 'local' },
            content: playlist,
            message: 'Failed to save',
          },
          result: null,
        });
        progressUpdateSubject.complete();
      });
    });

    return progressUpdateSubject;
  }

  getUnitsHavingPlaylist(playlist_id: string): Promise<Unit[]> {
    return new Promise<Unit[]>((resolveUnitsHavingPlaylist, rejectUnitsHavingPlaylist) => {
      this.unitsService.getAllUnitsUnwatched()
        .then((units) => {
          let allUnitHasPlaylistPromises = [];
          for (let i = 0; i < units.length; i++) {
            allUnitHasPlaylistPromises.push(
              new Promise((resolveUnitHasPlaylist) => {
                if (typeof units[i].is_disconnected !== 'undefined' && units[i].is_disconnected) {
                  resolveUnitHasPlaylist(false);
                } else {
                  this.api_789
                    .getPlaylist(units[i], playlist_id)
                    .then((resPlaylistCommand) => {
                      let resPlaylist = resPlaylistCommand.payload;
                      if (resPlaylist && resPlaylist.id) {
                        resolveUnitHasPlaylist(units[i]);
                      } else {
                        resolveUnitHasPlaylist(false);
                      }
                    })
                    .catch((res) => {
                      resolveUnitHasPlaylist(false);
                    });
                }

              })
            );
          }

          Promise.all(allUnitHasPlaylistPromises)
            .then((results) => {
              let unitsHavingPlaylist = [];
              for (let i = 0; i < results.length; i++) {
                if (results[i] && results[i].id) {
                  unitsHavingPlaylist.push(results[i]);
                }
              }

              resolveUnitsHavingPlaylist(unitsHavingPlaylist);
            })
            .catch((e) => {
              console.log(e);
              resolveUnitsHavingPlaylist([]);
            });
        })
        .catch((e) => {
          console.log(e);
          resolveUnitsHavingPlaylist([]);
        });
    });
  }

  addPlaylistToPlayer(playlist: Playlist, playerType: PlayerType): Observable<ContentUploadProgressUpdate> {
    return this.addPlaylistsToPlayer(playerType, [playlist]);
  }

  addPlaylistsToPlayer(playerType: PlayerType, playlists: Playlist[]): Observable<ContentUploadProgressUpdate> {
    let progressObservable = new Subject<ContentUploadProgressUpdate>();
    let units: Unit[] = []; //all the units to push content to

    getIndividualUnitsFromObjectList([playerType])
      .then((individualUnits) => {
        units = individualUnits;

        let totalRequests = units.length * playlists.length;
        progressObservable.next({ initialized: { totalRequests: totalRequests } });
        return units;
      })
      .then((result) => {
        //playlist assets
        return new Promise((resolve) => {
          this.publishPlaylistsToUnits(units, playlists).subscribe(
            (progress) => {
              //when each upload has news (started, completed)
              progressObservable.next(progress);
            },
            (err) => {
              progressObservable.next(err);
            },
            () => {
              resolve(true);
            }
          );
        });
      })
      .then((result) => {
        progressObservable.complete(); //when all uploads are done
      });

    return progressObservable;
  }

  publishPlaylistsToUnits(units: Unit[], playlists: Playlist[], update_where_exists = true): Observable<ContentUploadProgressUpdate> {
    let progressObservable = new Subject<ContentUploadProgressUpdate>();

    if (!units || !units.length) {
      units = [];
    }

    let uploadTasks = []
    for (let i = 0; i < units.length; i++) {
      for (let j = 0; j < playlists.length; j++) {
        let uploadTask: UploadTask = { unit: units[i], uploadMode: (update_where_exists ? 'downloadFromCloud' : 'downloadFromCloudIfExists'), content: playlists[j] };
        uploadTasks.push(uploadTask);

      }
    }

    if (!units.length) {
      progressObservable.complete(); //when all uploads are done
    } else {
      Observable.from(uploadTasks)
        .mergeMap((uploadTask) => this.api_789.executeUploadContentToUnit(uploadTask), 4)
        .subscribe(
          (progress) => {
            //when each upload has news (started, completed)
            progressObservable.next(progress);
          },
          (err) => {
            progressObservable.next(err);
          },
          () => {
            progressObservable.complete(); //when all uploads are done
          }
        );
    }

    return progressObservable;
  }

  getAllPlaylistsUnwatched():Promise<Playlist[]> {
    return new Promise<Playlist[]>((resolve, reject) => {
      return this.getAllPlaylistsCollection().get().toPromise().then(res => {
        let Playlists:Playlist[] = [];
        for (let i = 0; i < res.docs.length; i++) {
          Playlists.push(this.fireSessionService.deserializePlaylist(res.docs[i].data()));
        }
        resolve(Playlists);
      }).catch(() => {
        reject();
      });
    });
  }

  getAllPlaylistsCollection():AngularFirestoreCollection<Playlist> {
    return this.fireDB.collection<Playlist>(this.getPathToPlaylistsInWorkspace());
  }

  getPathToPlaylistsInWorkspace() {
    return this.fireSessionService.getPathToCurrentWorkspace() + '/playlists';
  }
  getPathToPlaylistInWorkspace(id: string) {
    return this.fireSessionService.getPathToCurrentWorkspace() + '/playlists/' + id;
  }

  getPlaylistsHavingSlide(slide: Slide):Promise<Playlist[]> {
    return this.fireDB.collection<Playlist>(this.getPathToPlaylistsInWorkspace(), ref => ref.where('slide_ids', 'array-contains', slide.id)).get().toPromise().then(res => {
      let playlists:Playlist[] = [];
      for (let i = 0; i < res.docs.length; i++) {
        playlists.push(res.docs[i].data());
      }

      return playlists;
    }).catch(err => {
      return [];
    });
  }
}
