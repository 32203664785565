import { Injectable } from '@angular/core';
import {UserSetting} from "../types/UserSetting";
import {WorkspaceUsersService} from "./workspace-users.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FireSessionService} from "./fire-session.service";

export type UserSettingsKeyTypes = 'default_template_ratio' | 'language' | 'hide-proofofplay-instructions' | 'hide-dictionary-instructions';

@Injectable({
  providedIn: 'root',
})
export class UserSettingsService {
  private email: string;

  constructor(private workspaceUsersService: WorkspaceUsersService,
              private fireDB: AngularFirestore,
              private fireSessionService: FireSessionService){
  }


  async setValue(key: UserSettingsKeyTypes, value: string, email: string = this.email): Promise<any> {
    let dataToSave = {};
    dataToSave[key] = value;
    return this.fireDB.doc(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUsers/' + this.workspaceUsersService.getCurrentLoggedInUser().email + '/settings/' + key).set(dataToSave)
  }

  getValue(key: UserSettingsKeyTypes) {
    return new Promise<any>((resolve, reject) => {
      this.fireDB.doc(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUsers/' + this.workspaceUsersService.getCurrentLoggedInUser().email + '/settings/' + key).get().toPromise().then(res => {
        let data = res.data();
        if (data && data[key]) {
          resolve(data[key]);
        } else {
          reject();
        }
      }).catch(err => {
        reject();
      })
    });
  }

  getAll():Promise<UserSetting[]> {
    return new Promise<any>((resolve, reject) => {
      this.fireDB.collection(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUsers/' + this.workspaceUsersService.getCurrentLoggedInUser().email + '/settings').get().toPromise().then(res => {

        let data = res.docs;
        if (data && data.length) {
          let settings = [];
          for (let i = 0; i < data.length; i++) {
            settings.push(data[i].data());
          }
          resolve(settings);
        } else {
          reject();
        }
      }).catch(err => {
        reject();
      })
    });
  }

  getUserSettingByKey(key: UserSettingsKeyTypes) {
    return this.getValue(key);
  }
}
