import { Injectable } from '@angular/core';
import {ModalController, Platform} from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { KeywordDictionaryEntry, KeywordDictionaryTarget } from '../../types/KeywordDictionary';
import { isArray, contains } from 'underscore';
import {SlidesService} from "../slides.service";
import {DimensionsComponent} from "../../common/editor/dimensions/dimensions.component";
import {TemplatesComponent} from "../../common/editor/templates/templates.component";
import * as jQuery from 'jquery';

const SLIDE_THUMBNAIL_HEIGHT = 200;

@Injectable({
  providedIn: 'root',
})
export class EditorService {
  constructor(private platform: Platform, private slidesService: SlidesService,
              private modalCtrl: ModalController,
              //private keywordDictionaryService: KeywordDictionaryService,
              private http: HttpClient) {
    this.platform
      .ready()
      .then(() => {
        this.userScript = '';
        this.isAndroid = this.platform.is('android');
        if ((<any>window).require) {
          try {
            this.remote = (<any>window).require('electron').remote;
          } catch (e) {
            throw e;
          }
        }
      })
      .catch((e) => {
        console.log('Failed to load cordova \n', e);
      });
  }
  editor: any = null;
  clonesMultiplier: number;
  cloneTimeStamp: any;
  compMoved = false;
  comp: any;
  isAndroid: boolean;
  userScript: string;
  private remote: any;
  saveAsTemplate = false;
  saveAsDraft = false;
  saveAs = false;
  optionsChanged = false;
  parent: any;
  deleteDraft = false;
  moreOptionsPopOver: any = null;
  deleteSlide = false;
  highestZIndex = 0;
  public showkeywordDictionaryValues = false;
  editorCanvasDependencies = {
    scripts: ['assets/axios.min.js', 'assets/parser.min.js', 'assets/swiper/swiper.min.js', 'assets/hls.min.js', 'assets/interactiveModeFunctions.js'],
    styles: ['assets/swiper/swiper.min.css', 'assets/styles/canvas-edit-mode.css', 'assets/fonts/googlefonts/googlefontsstyles.css'],
  };

  static scaleMaxDimensions(dimensions) {
    const minmax = 1920;
    let width = dimensions[0];
    let height = dimensions[1];
    if (width < minmax || height < minmax) {
      const ratio = Math.max(minmax / width, minmax / height);
      width *= ratio;
      height *= ratio;
          }
    if (width > minmax || height > minmax) {
      const ratio = Math.max(width / minmax, height / minmax);
      width /= ratio;
      height /= ratio;
        }
    return [width, height];
  }

  setEditor(editor) {
    this.editor = editor;
  }

  getEditor() {
    return this.editor;
  }

  componentMoved(moved: boolean): void {
    console.log('MOVED');
    this.compMoved = moved;
  }

  addText(editor, text: string) {
    const comp1 = editor.DomComponents.addComponent({
      tagName: 'div',
      removable: true, // Can't remove it
      draggable: true, // Can't move it
      copyable: true, // Disable copy/past
      content: text, // Text inside component
      style: { padding: '10px' },
      attributes: { title: 'here' },
    });
  }

  incrementClonesMultiplier(comp: any): number {
    // if clone is being called from the same component, increment
    // Otherwise set comp to current component and reinitialise clone counter
    if (this.componentsEqual(comp, this.comp) && !this.compMoved) {
      this.clonesMultiplier++;
      return this.clonesMultiplier;
    } else {
      this.comp = comp;
      this.compMoved = false;
      this.clonesMultiplier = 1;
      return this.clonesMultiplier;
    }
  }

  componentsEqual(comp1: any, comp2: any): boolean {
    if (comp1 === undefined || comp2 === undefined) {
      return false;
    } else {
      return comp1.getTrait().cid === comp2.getTrait().cid;
    }
  }

  openSettings() {
    const settings = document.querySelector('#custom-settings') as any;
    settings.style.display = settings.style.display === 'none' ? 'block' : 'none';
  }

  /**
   * This shows bars horizontally and vertically when the users scrolls, in order to warn them that they are scrolling beyond what will
   * be visible of the content when played on a unit.
   **/
  setBoundaryLimitsWarning() {
    this.editor.on('load', () => {
      let component = {
        type: false,
        classes: ['boundary-bar-warning', 'boundary-bar-warning-horizontal'],
        content: 'Content Boundary',
        removable: false,
        draggable: false,
        droppable: false,
        badgable: false,
        stylable: false,
        highlightable: false,
        copyable: false,
        resizable: false,
        editable: false,
        layerable: false,
        traits: [],
        toolbar: [],
        attributes: '',
      };
      this.editor.addComponents(component);
      component.classes = ['boundary-bar-warning', 'boundary-bar-warning-vertical'];
      this.editor.addComponents(component);
    });
  }

  async isSaved(id: string): Promise<boolean> {
    if (!id) {
      return false;
    }
    //todo temporary fix. Suggested fix: on save put id in route, instead of 'new'
    else if (id === 'new' || this.deleteSlide) {
      return true;
    }

    const savedSlide = await this.slidesService.getSlide(id);
    if (!savedSlide) return false;

    if (this.optionsChanged) return false;

    const currentHtml = this.editor.getHtml();
    const currentCss = this.editor.getCss();

    if (savedSlide.draft !== null && savedSlide.draft !== undefined) {
      if (savedSlide.draft.html === currentHtml && savedSlide.draft.css === currentCss) {
        return true;
      }
    }

    return savedSlide.html === currentHtml && savedSlide.css === currentCss;
  }

  openTemplatesModal(): void {
    TemplatesComponent.openModal(this.editor);
  }

  openDimensionsModal(): void {
    this.modalCtrl.create({
      component: DimensionsComponent,
      componentProps: {
        editor: this.editor,
        parent: this.parent
      },
      cssClass: 'dimensions-modal',
    })
      .then((modal) => {
        // Show modal
        modal.present();
      });
  }

  fill() {
    if (this.editor.getSelected().getStyle().height === '100%' || this.editor.getSelected().getStyle() === undefined || Object.keys(this.editor.getSelected().getStyle()).length === 0 ) {
      let style = this.editor.getSelected().getStyle();
      this.editor.getSelected().setStyle({ ...style, height: 'auto', width: 'auto' } as any);
      console.log('Set to px');
    } else {
      let style = this.editor.getSelected().getStyle();
      this.editor.getSelected().setStyle({ ...style, height: '100%', width: '100%', top: 0, left: 0 } as any);
      console.log('%%%%%');
    }
  }

  absClone(editor) {
    editor.Commands.run('core:copy');
    const ed = editor;

    const em = ed.getModel();
    const clp = em.get('clipboard');
    const selected = ed.getSelected();
    let added = null;

    // reset clones multiplier after 60 seconds
    let secondsTranscurred = Math.floor(Date.now() / 1000) - this.cloneTimeStamp;

    if (secondsTranscurred > 60) {
      this.clonesMultiplier = 0;
    }

    // last time a component was cloned
    this.cloneTimeStamp = Math.floor(Date.now() / 1000);

    if (clp && selected) {
      ed.getSelectedAll().forEach((comp) => {
        if (!comp) return;

        const coll = comp.collection;
        const at = coll.indexOf(comp) + 1;

        const copyable = clp.filter((cop) => cop.get('copyable'));

        if (contains(clp, comp) && comp.get('copyable')) {
          added = coll.add(comp.clone(), { at });
        } else {
          added = coll.add(
            copyable.map((cop) => cop.clone()),
            { at }
          );
        }

        var compStyle = added.getStyle();

        // on clone add space so the component doesnt overlap with the currently selected comp
        if (compStyle.position === 'absolute') {
          let marginTop = 40;
          let marginLeft = 40;
          // get height, top position and how many clones have been made previous to this one
          var component = jQuery('.gjs-frame')
            .contents()
            .find('body #wrapper')
            .find('#' + comp.getId());

          var height = component.innerHeight();
          var top = +compStyle.top.split('px')[0];
          var left = +compStyle.left.split('px')[0];
          var clones = this.incrementClonesMultiplier(comp);

          height = marginTop * clones + top;
          left = marginLeft * clones + left;

          // get the maximum top and left position permitted
          var body = jQuery('.gjs-frame').contents().find('body');
          let maxTop = body.innerHeight() - component.innerHeight() / 2;
          let maxLeft = body.innerWidth() - component.innerWidth() / 2;

          if (height > maxTop) {
            this.clonesMultiplier = 0;
          }

          if (left > maxLeft) {
            this.clonesMultiplier = 0;
          }

          compStyle.top = height + 'px';
          compStyle.left = left + 'px';
          compStyle['z-index'] = ++this.highestZIndex;
        }

        added.setStyle(compStyle);
        added = isArray(added) ? added : [added];
        added.forEach((add) => ed.trigger('component:paste', add));
      });

      selected.emitUpdate();
    }
    return added[0];
  }

  cleanCSS(css: string): string {
    return `${Array.from(new Set(css.split('}'))).join('}')}}`;
  }

  setUserScript(script: string): void {
    this.userScript = script;
  }

  getUserScript(): string {
    return this.userScript;
  }

  /**
   * Enables the tab function in custom scripts
   */
  enableTab() {
    const userJsInput = (<HTMLInputElement>document.getElementById('userJSInput')) as any;
    userJsInput.onkeydown = function (e) {
      if (e.keyCode === 9) {
        // tab was pressed

        // get caret position/selection
        var val = this.value,
          start = this.selectionStart,
          end = this.selectionEnd;

        // set textarea value to: text before caret + tab + text after caret
        this.value = val.substring(0, start) + '\t' + val.substring(end);

        // put caret at right position again
        this.selectionStart = this.selectionEnd = start + 1;

        // prevent the focus lose
        return false;
      }
    };

    const userCssInput = (<HTMLInputElement>document.getElementById('userCSSInput')) as any;
    userCssInput.onkeydown = function (e) {
      if (e.keyCode === 9) {
        // tab was pressed

        // get caret position/selection
        var val = this.value,
          start = this.selectionStart,
          end = this.selectionEnd;

        // set textarea value to: text before caret + tab + text after caret
        this.value = val.substring(0, start) + '\t' + val.substring(end);

        // put caret at right position again
        this.selectionStart = this.selectionEnd = start + 1;

        // prevent the focus lose
        return false;
      }
    };
  }

  /**
   * returns thumbnail data url
   */
  screenCapture(): Promise<string> {
    if (!this.platform.is('electron')) {
      let genericImage = 'assets/screenshots/generic.png';
      return new Promise((resolve, reject) => {
        resolve(genericImage);
      });
    } else {
      let genericImage = 'assets/screenshots/generic.png';
      const frame = jQuery('.gjs-frame');
      const wrapper = frame.contents().find('body #wrapper')[0];
      let toolbarHeight;
      if (document.getElementById('toolbar').offsetHeight) {
        toolbarHeight = document.getElementById('toolbar').offsetHeight;
      } else {
        toolbarHeight = 0;
      }
      const panelHeight = document.getElementsByClassName('gjs-pn-panel')[0].clientHeight;
      const styleManagerWidth = document.getElementsByClassName('styleManager')[0].clientWidth;
      let sidebarWidth = 0;
      try {
        let el = document.getElementsByClassName('split-pane-side menu-type-overlay menu-enabled menu-side-start menu-pane-visible')[0];
        let styles = getComputedStyle(el);
        sidebarWidth = parseFloat(styles.marginLeft) + parseFloat(styles.marginRight) + parseFloat(styles.width);
      } catch (e) {}

      const height = (wrapper as any).clientHeight,
        width = (wrapper as any).clientWidth,
        transform = frame[0].style.transform,
        scaleMatch = transform.match(/scale\(([0-9.]+)\)/i),
        scale = parseFloat(scaleMatch[1]);
      // DEBUG
      console.log(height + ' ' + width + ' tlb height: ' + toolbarHeight);
      console.log('panel ' + panelHeight + ' sm width: ' + styleManagerWidth);
      console.log('window ' + window.outerWidth);
      console.log('document.getElementsByClassName(\'gjs-pn-panel\') ', document.getElementsByClassName('gjs-pn-panel'));
      console.log('document.getElementsByClassName(\'styleManager\') ', document.getElementsByClassName('styleManager'));

      return new Promise((resolve, reject) => {
        try {
          setTimeout(() => {
            this.remote
              .getCurrentWebContents()
              .capturePage({
                x: styleManagerWidth + sidebarWidth,
                y: toolbarHeight + panelHeight,
                width: Math.floor(width * scale),
                height: Math.floor(height * scale),
              })
              .then((img) => {
                //resize the image to a thumbnail size
                resolve(
                  img
                    .resize({
                      //aspect ratio is automatic
                      height: SLIDE_THUMBNAIL_HEIGHT,
                      quality: 'better',
                    })
                    .toDataURL()
                );
              });
          }, 300);
        } catch (e) {
          resolve(genericImage);
        }
      });
    }
  }

  /**
   * DEPRECATION! IMPORTANT RenderFor Unit has been removed: For the cloud version of the 799, the generating of rendered HTML happens
   * on the unit side due to Firebase limitation max 1MB per document (and with renderedHTML the slide can exceed this limit)
   *
   * @param replaceKeywords - use this only for previews. Otherwise this replacement happens on the unit
   */
  generateSlideRenderedHTML(html, css, slideData, replaceKeywords?: { target: keyof typeof KeywordDictionaryTarget; target_id?: string }, renderFor:'preview' = 'preview'): Promise<string> {
    const scaledDimensions = EditorService.scaleMaxDimensions(slideData.dimensions),
      slideX = scaledDimensions[0],
      slideY = scaledDimensions[1];
    return new Promise((resolve, reject) => {
      new Promise((resolveHTML) => {
        if (replaceKeywords) {
          this.replaceKeywordDictionaryEntries(html, replaceKeywords).then((resp) => {
            resolveHTML(resp.html);
          });
        } else {
          resolveHTML(html);
        }
      }).then((resultHTML) => {
        html = resultHTML;

        let renderedHTML = '';

        renderedHTML += '<html style="width:' + slideX + 'px; height:' + slideY + 'px; overflow: hidden; transform-origin: 0 0"><head><title></title>';

        // allows special chars such as É
        renderedHTML += `   <meta charset="UTF-8">`;
        renderedHTML += `   <base href="https://cloud.muxlab.com/digisign/" />`;

        // give slide time to load before showing
        renderedHTML += `
                            <style>.hideUntilLoaded { opacity: 0;} </style>
                            <script src="https://www.youtube.com/iframe_api" defer></script>
                            <script src="https://player.vimeo.com/api/player.js" defer></script>
                            <script>
                                try {
                                        window.addEventListener('load', function() { document.body.classList.remove('hideUntilLoaded'); });

                                        setTimeout(function() {
                                            if(document.body.classList.contains('hideUntilLoaded'))
                                                document.body.classList.remove('hideUntilLoaded');

                                    }, 500)
                                } catch(e) { console.warn('slide load ran before time out. Thats good ', e)}

                            </script>
                            `;

        /**
         * font fix: in editor and on the unit, the css file for googlefonts references font files that exist at
         * the right location. In the preview window, font files are not resolved in the path of the googlefonts css file
         * so include the css file here to adjust
         */
        if (renderFor === 'preview') {
          // TODO renderedHTML += '<base href="' + appPath + '/app/" />';
          renderedHTML += '<link href="assets/fonts/googlefonts/googlefontsstyles.css" rel="stylesheet" />';
        } else {
          // for play slide now
          //Android player
          //DOESNT WORK renderedHTML += '<link href="/assets/fonts/googlefonts/googlefontsstyles.css" rel="stylesheet" />';


          //windows player
          renderedHTML += '<link href="../../shared_assets/fonts/googlefontsstyles.css" rel="stylesheet" />'; //TODO this will be a problem when storing html files on external storage. relative path will no longer be valid

          // for default slide
          renderedHTML += '<link href="../shared_assets/fonts/googlefontsstyles.css" rel="stylesheet" />'; //TODO this will be a problem when storing html files on external storage. relative path will no longer be valid
        }

        //insert JS dependencies here
        for (let i = 0; i < this.editorCanvasDependencies.scripts.length; i++) {
          renderedHTML += '<script src="https://cloud.muxlab.com/digisign/' + this.editorCanvasDependencies.scripts[i] + '"></script>';
        }

        //insert CSS here
        for (let i = 0; i < this.editorCanvasDependencies.styles.length; i++) {
          renderedHTML += '<link rel="stylesheet" href="https://cloud.muxlab.com/digisign/' + this.editorCanvasDependencies.styles[i] + '" />';
        }

        renderedHTML += '<style>' + css + '</style>';
        var matchBackgroundColor = css.match(/body *{ *background-color *: *#([0-9a-f]+)/);
        let backgroundColor = 'fff';
        if (matchBackgroundColor) backgroundColor = matchBackgroundColor[1];
        renderedHTML +=
          '</head><body style="position:absolute;background-color:#000;height: ' +
          slideY +
          'px; width: ' +
          slideX +
          'px; transform-origin: 0 0" class="hideUntilLoaded">' +
          '<div id="topDivFromGJSPlayer" data-gjs-type="wrapper" ' +
          'style="height: ' +
          slideY +
          'px; ' +
          'width: ' +
          slideX +
          'px; ' +
          'overflow: hidden; ' +
          'border: none;' +
          'margin: 0;' +
          'display: block;' +
          'position: absolute;' +
          'background-color:#' +
          backgroundColor +
          ';' +
          'top: 0;' +
          'left:0;"><div>';

        //body here
        renderedHTML += html;

        renderedHTML +=
          `<script>
                                        function getRatio() {
                                            const height = window.innerHeight;
                                            const width = window.innerWidth;
                                            return Math.min(height / ` +
          slideY +
          `, width / ` +
          slideX +
          `);
                                        }
                                        function display16by9() {
                                            const gjs = document.body;
                                            if (gjs && gjs.clientHeight > 0 && gjs.clientWidth > 0) {
                                                const ratio = getRatio();
                                                gjs.style.transform = 'scale(' + ratio + ')';

                                            } else {

                                                setTimeout(display16by9, 10);
                                            }
                                        }
                                        function centerObject() {
                                            const height = window.innerHeight;
                                            const width = window.innerWidth;
                                            let divWidth = ` +
          slideX +
          `;
                                            let divHeight = ` +
          slideY +
          `;
                                            const ratio = getRatio();
                                            divWidth *= ratio;
                                            divHeight *= ratio;
                                            const widthDif = width - divWidth;
                                            const heightDif = height - divHeight;
                                            const widthSide = widthDif / 2;
                                            const heightSide = heightDif / 2;
                                            const gjs = document.body;
                                            gjs.style.left = widthSide + 'px';
                                            gjs.style.top = heightSide + 'px';
                                        }
                                        centerObject();
                                        setTimeout(centerObject, 10);
                                        window.onload = centerObject;
                                        document.addEventListener('DOMContentLoaded', centerObject);
                                        window.addEventListener('resize', centerObject);
                                        window.addEventListener('click', centerObject);
                                        display16by9();
                                        window.addEventListener('resize', display16by9);
                                        window.addEventListener('click', display16by9);
                                    </script>`;
        renderedHTML += '</div></div></body></html>';

        resolve(renderedHTML);
      });
    });
  }

  public replaceKeywordDictionaryEntries(
    html: string,
    replaceKeywords: { target: keyof typeof KeywordDictionaryTarget; target_id?: string }
  ): Promise<{ html: string; replacedKeywords?: { key: string; value: string }[] }> {
    return Promise.resolve({html: html});
    /* TODO return new Promise((resolve, reject) => {
      if (html && html.length != 0) {
        let keywordsToReplace: string[] = [];

        var regex = /{{/gi,
          result,
          startIndicies = [];
        while ((result = regex.exec(html))) {
          startIndicies.push(result.index);
        }

        for (let i = 0; i < startIndicies.length; i++) {
          let endIndex = html.indexOf('}}', startIndicies[i]);
          if (endIndex >= 0) {
            keywordsToReplace.push(html.substring(startIndicies[i] + 2, endIndex));
          }
        }

        this.keywordDictionaryService
          .getDictionary()
          .then((dictionary) => {
            var replacedKeywords = [];

            for (let i = 0; i < keywordsToReplace.length; i++) {
              let replacementWord: KeywordDictionaryEntry;
              let slideKeyword: KeywordDictionaryEntry;

              if (replaceKeywords.target_id) {
                slideKeyword = dictionary.find((item) => item.dictionary_target === 'SLIDE' && item.dictionary_target_id === replaceKeywords.target_id && item.key === keywordsToReplace[i]);
              }

              let globalKeyword: KeywordDictionaryEntry = dictionary.find((item) => item.dictionary_target === 'GLOBAL' && item.key === keywordsToReplace[i]);

              if (globalKeyword) {
                replacementWord = globalKeyword;
              }
              if (slideKeyword) {
                replacementWord = slideKeyword; //this has priority over global
              }

              if (replacementWord) {
                html = html.replace('{{' + keywordsToReplace[i] + '}}', replacementWord.value);
                replacedKeywords.push({ key: keywordsToReplace[i], value: replacementWord.value });
              }
            }

            resolve({ html: html, replacedKeywords: replacedKeywords });
          })
          .catch((e) => {
            resolve({ html: html });
          });
      }
    });*/
  }

  public formatKeywordDictionaryEntries(html: string): string {
    if (html && html.length != 0) {
      let keywordsToReplace: string[] = [];

      var regex = /{{/gi,
        result,
        startIndicies = [];
      while ((result = regex.exec(html))) {
        startIndicies.push(result.index);
      }

      for (let i = 0; i < startIndicies.length; i++) {
        let endIndex = html.indexOf('}}', startIndicies[i]);
        if (endIndex >= 0) {
          keywordsToReplace.push(html.substring(startIndicies[i] + 2, endIndex));
        }
      }

      for (let i = 0; i < keywordsToReplace.length; i++) {
        html = html.replace('{{' + keywordsToReplace[i] + '}}', '<span class="dictionary-keyword-replace" data-dictionary-keyword="' + keywordsToReplace[i] + '">' + keywordsToReplace[i] + ' </span>');
      }
    }

    return html;
  }

  toggleKeywords(slide_id) {
    return new Promise<any>((resolve, reject) => {
      if (this.showkeywordDictionaryValues) {
        //convert kw dictionnary keys to values
        this.editor.getComponents().models.forEach((component) => {
          if (component.is('text') && component.getAttributes().keywords !== undefined && component.getAttributes().keywords.showingValues === false) {
            //if comp is text and is'nt showing value
            this.replaceKeywordDictionaryEntries(component.view.el.innerHTML, { target: 'SLIDE', target_id: slide_id })
              .then((resp) => {
                //replace html
                component.view.el.innerHTML = `${resp.html}`;

                //set Attributes
                component.setAttributes({
                  keywords: { showingValues: true, replacedKeywords: resp.replacedKeywords },
                  'data-gjs-drag-mode': component.getAttributes()['data-gjs-drag-mode'],
                  id: component.getAttributes()['id'],
                });

                resolve('');
              })
              .catch((e) => {
                console.log('replaceKeywordDictionaryEntries failed: ', e);
              });
            reject();
          }
        });
      } else {
        //convert kw dictionnary values back to {{key}} format
        this.editor.getComponents().models.forEach((component) => {
          if (component.is('text') && component.getAttributes().keywords !== undefined && component.getAttributes().keywords.showingValues === true) {
            //if comp is text and is showing value
            //replace html
            if (component.getAttributes().keywords.replacedKeywords.length > 0) {
              var replacedKeywords = component.getAttributes().keywords.replacedKeywords;
              let html = component.view.el.innerHTML;
              replacedKeywords.forEach((keyword) => {
                html = html.toString().replace(keyword.value, `{{${keyword.key}}}`);
              });

              component.view.el.innerHTML = html;
            }

            //set Attributes
            component.setAttributes({
              keywords: { showingValues: false, replacedKeywords: [] },
              'data-gjs-drag-mode': component.getAttributes()['data-gjs-drag-mode'],
              id: component.getAttributes()['id'],
            });
            resolve('');
          }
        });
      }
    });
  }

  public showBlocksByCategory(category: string, delay: number = 0) {
    const blocks = this.editor.BlockManager.getAll();
    const filtered = blocks.filter((block) => block.get('category').id === category);
    setTimeout(() => {
      this.editor.BlockManager.render(filtered, { ignoreCategories: true });
    }, delay);
  }
}
