import {Component, OnInit, Type} from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';
import { AppComponent } from '../../../app.component';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import {StaticInjector} from "../../../utils/StaticInjector";
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Component({
  selector: 'editor-slide-dimensions',
  templateUrl: './dimensions.component.html',
  styleUrls: ['./dimensions.component.scss'],
})
export class DimensionsComponent implements OnInit {
  editorPage: any;
  editor: any;
  isCustomDimensions = false;
  dimensions: number[];
  valueX: number;
  valueY: number;
  selectionGroup: string;
  form = new FormGroup({
    customX: new FormControl(
      {
        value: '48',
        disabled: true,
      },
      [Validators.required, Validators.min(8)]
    ),
    customY: new FormControl(
      {
        value: '28',
        disabled: true,
      },
      [Validators.required, Validators.min(8)]
    ),
  });
  customRatio: string;

  constructor(private modalCtrl: ModalController,
              private navParams: NavParams,
              private translate: TranslateService) {}

  ngOnInit() {
    if (this.navParams.get('editor')) {
      this.editor = this.navParams.get('editor');
    }

    if (this.navParams.get('parent')) {
      this.editorPage = this.navParams.get('parent');
    }

    this.updateView();
    this.calculateCustomRatio();
  }

  enableCustomDimensions() {
    this.isCustomDimensions = true;
    this.form.controls.customX.enable();
    this.form.controls.customY.enable();
  }

  disableCustomDimensions() {
    this.isCustomDimensions = false;
    this.form.controls.customX.disable();
    this.form.controls.customY.disable();
  }

  updateView() {
    let dimensions = this.editorPage.slideData.dimensions,
      inchesX = this.pixelsToInches(dimensions[0]),
      inchesY = this.pixelsToInches(dimensions[1]),
      inchValue = inchesX + 'X' + inchesY;
    if (inchValue != '9X16' && inchValue != '16X9' && inchValue != '10X16' && inchValue != '16X10') {
      this.enableCustomDimensions();
      this.form.get('customX').setValue(inchesX.toString());
      this.form.get('customY').setValue(inchesY.toString());
      this.calculateCustomRatio();
      inchValue = 'custom';
    } else {
      this.disableCustomDimensions();
    }
    this.selectionGroup = inchValue;
  }

  answer(x, y?) {
    if (x == 'custom') {
      this.enableCustomDimensions();
    } else {
      this.disableCustomDimensions();
      this.valueX = x;
      this.valueY = y;
    }
  }

  numberOnlyValidation(event: any) {
    const pattern = /[0-9.]/;
    let inputChar = String.fromCharCode(event.charCode);
    if (!pattern.test(inputChar)) {
      // invalid character, prevent input
      event.preventDefault();
    }
  }

  inchesToPixels(inches) {
    return inches * 120;
  }
  pixelsToInches(inches) {
    return Math.floor(inches / 120);
  }

  close() {
    this.modalCtrl.dismiss();
  }

  save() {
    if (this.isCustomDimensions) {
      this.valueX = parseInt(this.form.get('customX').value);
      this.valueY = parseInt(this.form.get('customY').value);
    }
    this.editorPage.slideData.dimensions[0] = this.inchesToPixels(this.valueX);
    this.editorPage.slideData.dimensions[1] = this.inchesToPixels(this.valueY);
    this.editorPage.setDimensions(true);
    this.modalCtrl.dismiss();
  }

  // greatest common divisor: used to caluclate ratio
  gcd(x, y) {
    return y == 0 ? x : this.gcd(y, x % y);
  }

  getRatio(x, y) {
    const r = this.gcd(x, y),
      x2 = x / r,
      y2 = y / r;
    if (x2 == 5 && y2 == 8) return [10, 16];
    if (x2 == 8 && y2 == 5) return [16, 10];
    return [x2, y2];
  }

  calculateCustomRatio() {
    const x = this.form.get('customX').value,
      y = this.form.get('customY').value,
      ratio = this.getRatio(x, y);
    this.customRatio = ratio[0] + ':' + ratio[1];
  }
}
