import {StaticInjector} from "../utils/StaticInjector";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Type} from "@angular/core";

export class Slide {
  id: string;
  name: string;
  renderedHTML: undefined;
  durationInSeconds: number;
  components: any[];
  html: string;
  css: string;
  highest_zIndex?: number;
  thumbnail?: string;
  assets?: any[];
  styles?: any[];
  dimensions?: number[];
  draft?: Slide;
  interactiveMode: boolean;
  modified?: number;

  constructor(name: string,
              renderedHTML: undefined,//DEPRECATED, see editor.service.ts:generateSlideRenderedHTML function comment block
              durationInSeconds: number,
              components: any[],
              html: string,
              css: string,
              id?: string,
              highest_zIndex?: number,
              thumbnail?: string,
              assets?: any[],
              styles?: any[],
              dimensions?: number[],
              draft?: Slide,
              interactiveMode?: boolean,
              modified?: number) {

    if (!id) {
      id = StaticInjector.getInjector().get<AngularFirestore>(AngularFirestore as Type<AngularFirestore>).createId();
    }

    this.id = id;
    this.name = name;
    this.renderedHTML = renderedHTML;
    this.name = name;
    this.durationInSeconds = durationInSeconds;
    this.highest_zIndex = highest_zIndex;
    this.assets = assets;
    this.styles = styles;
    this.components = components;
    this.html = html;
    this.css = css;
    this.thumbnail = thumbnail;
    this.name = name;
    this.renderedHTML = renderedHTML;
    if (dimensions) this.dimensions = dimensions;
    this.draft = draft;
    this.interactiveMode = interactiveMode;
    if (modified) this.modified = modified;
  }


  getId(): string {
    return this.id;
  }

  getName(): string {
    return this.name;
  }
  setName(name: string): void {
    this.name = name;
  }

  getDurationInSeconds(): number {
    return this.durationInSeconds;
  }

  setDurationInSeconds(seconds: number): void {
    this.durationInSeconds = seconds;
  }
}

export function isSlide(obj: any): obj is Slide {
  return !!obj && typeof (obj as any).slide_ids == 'undefined' && (obj as Slide).durationInSeconds !== undefined && (obj as Slide).id !== undefined && (obj as Slide).name !== undefined;
}
