import { getTemplateAssetDirectory, Template } from '../../app/types/Template';

export const TemperatureControl: Template = {
  isDefaultTemplate: true,
  isInteractiveModeOn: true,
  defaultTemplateKeywords: ['interactive', 'interactive mode', 'temperature'],
  id: 'interactive-temperature-control-1',
  renderedHTML: undefined,
  name: 'Temperature Control',
  durationInSeconds: 30,
  highest_zIndex: 4,
  assets: [],
  styles: [
    { selectors: [{ name: 'wrapper', label: 'wrapper', type: 2, active: true, private: 1, protected: false }], style: { 'background-color': '#369ce6' }, wrapper: 1 },
    {
      selectors: [{ name: 'shape', label: 'shape', type: 1, active: true, private: 1, protected: false }],
      style: {
        'overflow-x': 'hidden',
        'overflow-y': 'hidden',
        width: '400px',
        height: '400px',
        'max-height': '100%',
        'font-family': 'Helvetica, sans-serif',
        'font-size': '32px',
        color: 'rgb(0, 0, 0)',
      },
    },
    { selectors: [], selectorsAdd: '.shape svg', style: { fill: 'currentcolor', 'max-height': '100%', 'max-width': '100%', 'pointer-events': 'none' } },
    {
      selectors: [{ name: 'iy6g', label: 'iy6g', type: 2, active: true, private: 1, protected: false }],
      style: { left: '285px', top: '333px', position: 'absolute', 'z-index': 0, en: '', width: '225px', height: '225px' },
    },
    {
      selectors: [{ name: 'i0ewb', label: 'i0ewb', type: 2, active: true, private: 1, protected: false }],
      style: { left: '285px', top: '660', position: 'absolute', 'z-index': 1, en: '', width: '225px', height: '225px' },
    },
    {
      selectors: [{ name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false }],
      style: { 'font-size': '45px', 'padding-top': '35px', 'padding-right': '35px', 'padding-bottom': '35px', 'padding-left': '35px' },
    },
    {
      selectors: [{ name: 'i7z53', label: 'i7z53', type: 2, active: true, private: 1, protected: false }],
      style: { left: '148px', top: '58px', position: 'absolute', 'z-index': 2, width: '515px', height: '242px', en: '', color: 'white', 'font-family': 'arconregular', 'font-size': '55px' },
    },
    {
      selectors: [{ name: 'ianxd', label: 'ianxd', type: 2, active: true, private: 1, protected: false }],
      style: { left: '1308.8969824002222px', top: '296.6141560421287px', position: 'absolute', 'z-index': 3, color: 'white', en: '', 'font-size': '55px' },
    },
    {
      selectors: [{ name: 'text_degrees', label: 'text_degrees', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '1300px',
        top: '398px',
        position: 'absolute',
        'z-index': 4,
        color: 'white',
        'font-size': '300px',
        en: '',
        'font-family': 'arconregular',
        height: '350px',
        width: '556px',
        'text-align': 'left',
      },
    },
  ],
  components: [
    { content: '', attributes: { 'data-gjs-drag-mode': false }, dmode: 0 },
    {
      type: 'shape',
      editable: true,
      content: '',
      classes: [
        { name: 'shape', label: 'shape', type: 1, active: true, private: 1, protected: false },
        { name: 'fill-dropzone-when-dropped', label: 'fill-dropzone-when-dropped', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { 'data-gjs-drag-mode': false, id: 'iy6g' },
      dmode: 0,
      shape:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-32 252c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92H92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z"/></svg>',
      components: [
        {
          tagName: 'svg',
          type: 'svg',
          resizable: true,
          content: '',
          attributes: { xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 448 512', 'data-gjs-drag-mode': false },
          dmode: 0,
          components: [
            {
              tagName: 'path',
              type: 'svg',
              content: '',
              attributes: {
                d: 'M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-32 252c0 6.6-5.4 12-12 12h-92v92c0 6.6-5.4 12-12 12h-56c-6.6 0-12-5.4-12-12v-92H92c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h92v-92c0-6.6 5.4-12 12-12h56c6.6 0 12 5.4 12 12v92h92c6.6 0 12 5.4 12 12v56z',
                'data-gjs-drag-mode': false,
              },
              dmode: 0,
              isInteractiveModeOn: 'true',
            },
          ],
          isInteractiveModeOn: 'true',
        },
      ],
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      actionsIndexes: [0],
      event0: 'click',
      action0: 'script',
      transition0: 'none',
      script0: null,
      actions: [
        {
          event: 'click',
          action: 'script',
          transition: 'none',
          script:
            'var DEFAULT_DEGREES_VALUE = 73;#IMLB#if (!window.degrees) {window.degrees = DEFAULT_DEGREES_VALUE;}#IMLB#degrees = degrees + 1;#IMLB#document.getElementById(#IMSQ#text_degrees#IMSQ#).innerHTML = degrees + #IMSQ#°#IMSQ#;',
        },
      ],
      customScript0:
        "var DEFAULT_DEGREES_VALUE = 73;\nif (!window.degrees) {window.degrees = DEFAULT_DEGREES_VALUE;}\ndegrees = degrees + 1;\ndocument.getElementById('text_degrees').innerHTML = degrees + '°';",
    },
    {
      type: 'shape',
      editable: true,
      content: '',
      classes: [
        { name: 'shape', label: 'shape', type: 1, active: true, private: 1, protected: false },
        { name: 'fill-dropzone-when-dropped', label: 'fill-dropzone-when-dropped', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { 'data-gjs-drag-mode': false, id: 'i0ewb' },
      dmode: 0,
      shape:
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM92 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H92z"/></svg>',
      components: [
        {
          tagName: 'svg',
          type: 'svg',
          resizable: true,
          content: '',
          attributes: { xmlns: 'http://www.w3.org/2000/svg', viewBox: '0 0 448 512', 'data-gjs-drag-mode': false },
          dmode: 0,
          components: [
            {
              tagName: 'path',
              type: 'svg',
              content: '',
              attributes: {
                d: 'M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zM92 296c-6.6 0-12-5.4-12-12v-56c0-6.6 5.4-12 12-12h264c6.6 0 12 5.4 12 12v56c0 6.6-5.4 12-12 12H92z',
                'data-gjs-drag-mode': false,
              },
              dmode: 0,
              isInteractiveModeOn: 'true',
            },
          ],
          isInteractiveModeOn: 'true',
        },
      ],
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      view: '',
      actionsIndexes: [0],
      event0: 'click',
      action0: 'script',
      transition0: 'none',
      script0: null,
      actions: [
        {
          event: 'click',
          action: 'script',
          transition: 'none',
          script:
            'var DEFAULT_DEGREES_VALUE = 73;#IMLB#if (!window.degrees) {window.degrees = DEFAULT_DEGREES_VALUE;}#IMLB#if (degrees >= 1) {#IMLB#degrees = degrees - 1;#IMLB#document.getElementById(#IMSQ#text_degrees#IMSQ#).innerHTML = degrees + #IMSQ#°#IMSQ#;#IMLB#}',
        },
      ],
      customScript0:
        "var DEFAULT_DEGREES_VALUE = 73;\nif (!window.degrees) {window.degrees = DEFAULT_DEGREES_VALUE;}\nif (degrees >= 1) {\ndegrees = degrees - 1;\ndocument.getElementById('text_degrees').innerHTML = degrees + '°';\n}",
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">TEMPERATURE CONTROLS</div></div></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'i7z53' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">Set To</div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'ianxd' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">7<span style="text-align: inherit;">3°</span></div></div></div></div></div></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'text_degrees' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
    },
  ],
  html: '',
  css: '* { box-sizing: border-box; } body {margin: 0;}body{background-color:#369ce6;}.shape{overflow-x:hidden;overflow-y:hidden;width:400px;height:400px;max-height:100%;font-family:Helvetica, sans-serif;font-size:32px;color:rgb(0, 0, 0);}.shape svg{fill:currentcolor;max-height:100%;max-width:100%;pointer-events:none;}#iy6g{left:285px;top:333px;position:absolute;z-index:0;width:225px;height:225px;}#i0ewb{left:285px;top:660;position:absolute;z-index:1;width:225px;height:225px;}.texts{font-size:45px;padding-top:35px;padding-right:35px;padding-bottom:35px;padding-left:35px;}#i7z53{left:148px;top:58px;position:absolute;z-index:2;width:515px;height:242px;color:white;font-family:arconregular;font-size:55px;}#ianxd{left:1308.8969824002222px;top:296.6141560421287px;position:absolute;z-index:3;color:white;font-size:55px;}#text_degrees{left:1300px;top:398px;position:absolute;z-index:4;color:white;font-size:300px;font-family:arconregular;height:350px;width:556px;text-align:left}',
  thumbnail: getTemplateAssetDirectory() + '/TemperatureControl/thumbnail.png',
};
