import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {FireSessionService} from "../services/fire-session.service";

@Injectable({
  providedIn: 'root'
})
export class IsUserMuxlabAdminGuard implements CanActivate {

  constructor(private fireSessionService:FireSessionService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let currentFireUser = this.fireSessionService.getCurrentFireUser();
    if (currentFireUser) {
      return currentFireUser.getIdTokenResult(true).then(token => {
        console.log('token', token);
        if (token && typeof token.claims !== 'undefined' && typeof token.claims.muxlabAdmin !== 'undefined' && token.claims.muxlabAdmin) {
          return true;
        } else {
          return false;
        }
      }).catch(err => {
        return false;
      });

    }

    return false;
  }

}
