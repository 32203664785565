import { Component, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { UnitGroup } from '../../../types/UnitGroup';
import { Unit } from '../../../types/Unit';
import {UnitsService} from "../../units.service";
import {UnitGroupsService} from "../../unitGroups.service";
import {WorkspaceUsersService} from "../../workspace-users.service";


@Component({
  selector: 'app-select-player-modal',
  templateUrl: './select-player-modal.component.html',
  styleUrls: ['./select-player-modal.component.scss'],
})
export class SelectPlayerModalComponent implements OnInit {
  currentTab = 'units';
  selectedItem: any = {
    item: null,
    type: null,
  };
  units: Unit[];
  unitGroups: UnitGroup[];

  constructor(private popoverCtrl: PopoverController, private unitsService: UnitsService, private unitGroupsService: UnitGroupsService,
              private workspaceUsersService: WorkspaceUsersService) {
    this.getUnits();
    this.getUnitGroups();
  }

  ngOnInit() {
    this.currentTab = 'units';
  }

  selectedTabChanged($event) {
    this.currentTab = $event.detail.value;
  }

  getUnits() {
    this.unitsService.getAllUnitsUnwatched().then(async (units) => {
      this.units = units.filter((u) => {
        (console.log('u', u)); return !u.group_id || u.group_id === '';

      });
      // for offline development
      // this.units.push(new Unit('0', '192.168.0.22', 'admin', 'admin', 'admin'))
      // this.units.push(new Unit('1', '192.168.0.22', 'admin1', 'admin', 'admin1'))
      // this.units.push(new Unit('2', '192.168.0.22', 'admin2', 'admin', 'admin2'))
      // this.units.push(new Unit('3', '192.168.0.22', 'admin3', 'admin', 'admin3'))
      // this.units.push(new Unit('4', '192.168.0.22', 'admin4', 'admin', 'admin4'))

      this.units = await this.workspaceUsersService.getUnitsWithUserPermissions(['EDIT_UNITS', 'VIEW_UNITS'], 'OR', this.units)
    });
  }

  getUnitGroups() {
    this.unitGroupsService.getAllUnitGroupsUnwatched().then((unitGroups) => {
      this.unitGroups = unitGroups;
    });
  }

  selectItem(type, item) {
    this.selectedItem.type = type;
    this.selectedItem.item = item;
  }

  isItemSelected(type, item) {
    if (this.selectedItem.type == type && this.selectedItem.item && this.selectedItem.item.id == item.id) {
      return true;
    }
    return false;
  }

  dismiss(data): null | {} | { id: string; type: string; item: Unit | UnitGroup } {
    if (data) {
      return this.popoverCtrl.dismiss(data);
    }

    if (!!this.selectedItem.item && !!this.selectedItem.item.id && !!this.selectedItem.type) {
      return this.popoverCtrl.dismiss(this.selectedItem);
    }

    return this.popoverCtrl.dismiss(null);
  }
}
