import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import {WorkspaceUsersService} from "../services/workspace-users.service";

@Injectable({
  providedIn: 'root',
})
export class UserHasPermissionGuard implements CanActivate {
  constructor(private usersService: WorkspaceUsersService) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let currentUser = this.usersService.getCurrentLoggedInUser();
    if (currentUser && currentUser.email && currentUser.role) {
      if (typeof next.data && next.data['routeRequiredPermissions']) {
        let requiredPermissions: routeRequiredPermissions = next.data['routeRequiredPermissions'];

        if (typeof requiredPermissions['AND'] !== 'undefined' && requiredPermissions['AND'].length) {
          return this.usersService.currentUserHasPermissions(requiredPermissions.AND, 'AND');
        } else if (typeof requiredPermissions['OR'] !== 'undefined' && requiredPermissions['OR'].length) {
          return this.usersService.currentUserHasPermissions(requiredPermissions.OR, 'OR');
        } else {
          return true;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }
}

interface routeRequiredPermissions {
  AND?: string[];
  OR?: string[];
}
