import { getTemplateAssetDirectory, Template } from '../../app/types/Template';

export const Retail1: Template = {
  isDefaultTemplate: true,
  defaultTemplateKeywords: ['retail', 'sales', 'store'],
  id: 'default-retail-1',
  dimensions: [1080, 1920],
  renderedHTML: undefined,
  name: 'Retail 1',
  durationInSeconds: 30,
  highest_zIndex: 7,
  assets: [],
  styles: [
    { selectors: [{ name: 'wrapper', label: 'wrapper', type: 2, active: true, private: 1, protected: false }], wrapper: 1 },
    { selectors: [{ name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false }], style: { width: '400px', height: '400px', 'background-color': 'rgb(10, 44, 52)' } },
    { selectors: [], selectorsAdd: '.box *', style: { 'pointer-events': 'none' } },
    {
      selectors: [
        { name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false },
        { name: 'fadeTop', label: 'fadeTop', type: 1, active: true, private: 1, protected: false },
      ],
      style: { '-webkit-mask-image': '-webkit-gradient(linear, 0% 100%, 0% 0%, from(rgb(0, 0, 0)), color-stop(0.75, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))' },
    },
    {
      selectors: [
        { name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false },
        { name: 'fadeLeft', label: 'fadeLeft', type: 1, active: true, private: 1, protected: false },
      ],
      style: { '-webkit-mask-image': '-webkit-gradient(linear, 100% 0%, 0% 0%, from(rgb(0, 0, 0)), color-stop(0.75, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))' },
    },
    {
      selectors: [
        { name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false },
        { name: 'fadeBottom', label: 'fadeBottom', type: 1, active: true, private: 1, protected: false },
      ],
      style: { '-webkit-mask-image': '-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(0, 0, 0)), color-stop(0.75, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))' },
    },
    {
      selectors: [
        { name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false },
        { name: 'fadeRight', label: 'fadeRight', type: 1, active: true, private: 1, protected: false },
      ],
      style: { '-webkit-mask-image': '-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgb(0, 0, 0)), color-stop(0.75, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))' },
    },
    { selectors: [], selectorsAdd: '.box .box-widget-container', style: { width: '100%', height: '100%' } },
    {
      selectors: [{ name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false }],
      style: { 'font-size': '45px', 'padding-top': '35px', 'padding-right': '35px', 'padding-bottom': '35px', 'padding-left': '35px' },
    },
    {
      selectors: [{ name: 'i1n4', label: 'i1n4', type: 2, active: true, private: 1, protected: false }],
      style: { left: 0, top: 0, position: 'absolute', 'z-index': 0, height: '100%', width: '100%' },
    },
    {
      selectors: [{ name: 'iy0l', label: 'iy0l', type: 2, active: true, private: 1, protected: false }],
      style: { left: '350px', top: '1520px', position: 'absolute', 'z-index': 1, en: '', color: 'white', 'font-family': 'Open Sans, sans-serif', 'font-size': '70px', width: '380px' },
    },
    {
      selectors: [{ name: 'iggw', label: 'iggw', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '400px',
        top: '1650px',
        position: 'absolute',
        'z-index': 2,
        en: '',
        'font-family': 'Open Sans, sans-serif',
        'font-size': '110px',
        width: '300px',
        color: '#ffffff',
        'background-color': '#f42727',
        height: '120px',
      },
    },
    {
      selectors: [{ name: 'id9l', label: 'id9l', type: 2, active: true, private: 1, protected: false }],
      style: { left: '255px', top: '1780px', position: 'absolute', 'z-index': 3, en: '', color: 'white', 'font-family': 'Open Sans, sans-serif', 'font-size': '45px', width: '580px' },
    },
    {
      selectors: [{ name: 'il5b', label: 'il5b', type: 2, active: true, private: 1, protected: false }],
      style: { left: '250px', top: '185px', position: 'absolute', 'z-index': 4, en: '', color: '#4B4B4B', 'font-family': 'Open Sans, sans-serif', 'font-size': '110px', width: '580px' },
    },
    {
      selectors: [{ name: 'ia9x', label: 'ia9x', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '160px',
        top: '320px',
        position: 'absolute',
        'z-index': 5,
        'font-size': '250px',
        en: '',
        'font-family': 'signpainter_housescriptRg',
        width: '690px',
        height: '275px',
        color: '#4B4B4B',
        transform: 'rotateX(0) rotateY(0) rotateZ(-5deg) scaleX(1) scaleY(1) scaleZ(1)',
      },
    },
    {
      selectors: [{ name: 'iizl', label: 'iizl', type: 2, active: true, private: 1, protected: false }],
      style: { left: '282px', top: '547px', position: 'absolute', 'z-index': 6, width: '500px', en: '' },
    },
    { selectors: [{ name: 'i0kb', label: 'i0kb', type: 2, active: true, private: 1, protected: false }], style: { left: '390px', top: '21px', position: 'absolute', 'z-index': 7, en: '' } },
  ],
  components: [
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'i1n4', src: getTemplateAssetDirectory() + 'Retail/Retail1Background.png', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Retail/Retail1Background.png',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>SAVE UP</b></div></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'iy0l' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>WINTER</b></div></div></div></div></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'il5b' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">JANUARY 25 TO 30</div></div></div></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'id9l' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>50%</b></div></div></div></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'iggw' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><i>Sale</i></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'ia9x' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'iizl', src: getTemplateAssetDirectory() + 'Retail/Line.svg', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Retail/Line.svg',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'i0kb', src: getTemplateAssetDirectory() + 'Retail/Logo.png', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Retail/Logo.png',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
  ],
  html: '',
  css: '* { box-sizing: border-box; } body {margin: 0;}.box *{pointer-events:none;}.box .box-widget-container{width:100%;height:100%;}.texts{font-size:45px;padding-top:35px;padding-right:35px;padding-bottom:35px;padding-left:35px;}#i1n4{left:0;top:0;position:absolute;z-index:0;height:100%;width:100%;}#iy0l{left:350px;top:1520px;position:absolute;z-index:1;color:white;font-family:Open Sans, sans-serif;font-size:70px;width:380px;}#iggw{left:400px;top:1650px;position:absolute;z-index:2;font-family:Open Sans, sans-serif;font-size:110px;width:300px;color:#ffffff;background-color:#f42727;height:120px;}#id9l{left:255px;top:1780px;position:absolute;z-index:3;color:white;font-family:Open Sans, sans-serif;font-size:45px;width:580px;}#il5b{left:250px;top:185px;position:absolute;z-index:4;color:#4B4B4B;font-family:Open Sans, sans-serif;font-size:110px;width:580px;}#ia9x{left:160px;top:320px;position:absolute;z-index:5;font-size:250px;font-family:signpainter_housescriptRg;width:690px;height:275px;color:#4B4B4B;transform:rotateX(0) rotateY(0) rotateZ(-5deg) scaleX(1) scaleY(1) scaleZ(1);}#iizl{left:282px;top:547px;position:absolute;z-index:6;width:500px;}#i0kb{left:390px;top:21px;position:absolute;z-index:7;}',
  thumbnail: getTemplateAssetDirectory() + '/Retail/thumbnail1.png',
};
