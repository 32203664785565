import {PlayerType} from "../services/units/content-upload-show-progress-modal.service";

export class Schedule {
  object_id: string;
  object_type: 'unit' | 'group';
  dhtmlx_json_data: string;
  draft?: Schedule;
  modified?: number;

  constructor(object_type: 'unit' | 'group', object_id: string, dhtmlx_json_data: string, draft: Schedule = null, modified?: number) {
    this.object_id = object_id;
    this.object_type = object_type;
    this.dhtmlx_json_data = dhtmlx_json_data;
    this.draft = draft;
    if (modified) {
      this.modified = modified;
    }
  }

  getDhtmlxJsonData() {
    return this.dhtmlx_json_data;
  }

  setDhtmlxJsonData(json_data: string) {
    this.dhtmlx_json_data = json_data;
  }

  setDraft(draft: Schedule) {
    this.draft = draft;
  }

  getPlayerType(): PlayerType {
    return {
      object_id: this.object_id,
      type: this.object_type,
    };
  }

  setPlayerType(playerType: PlayerType) {
    this.object_id = playerType.object_id;
    this.object_type = playerType.type;
  }
}

export function isSchedule(obj: any): obj is Schedule {
  return !!obj && (obj as Schedule).object_type !== undefined && (obj as Schedule).object_id !== undefined && (obj as Schedule).dhtmlx_json_data !== undefined;
}
