import { getTemplateAssetDirectory, Template } from '../../app/types/Template';

export const RestaurantSpecial: Template = {
  isDefaultTemplate: true,
  defaultTemplateKeywords: ['restaurant', 'special'],
  id: 'default-restaurant-special',
  renderedHTML: undefined,
  name: 'Restaurant Special',
  durationInSeconds: 30,
  highest_zIndex: 15,
  assets: [],
  styles: [
    { selectors: [{ name: 'wrapper', label: 'wrapper', type: 2, active: true, private: 1, protected: false }], wrapper: 1 },
    {
      selectors: [{ name: 'iex9', label: 'iex9', type: 2, active: true, private: 1, protected: false }],
      style: { left: 0, top: 0, position: 'absolute', 'z-index': 0, height: '100%', width: '100%' },
    },
    {
      selectors: [{ name: 'ii4n', label: 'ii4n', type: 2, active: true, private: 1, protected: false }],
      style: { left: '1295px', top: '293px', position: 'absolute', 'z-index': 4, width: '310px', en: '' },
    },
    {
      selectors: [{ name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false }],
      style: { 'font-size': '45px', 'padding-top': '35px', 'padding-right': '35px', 'padding-bottom': '35px', 'padding-left': '35px' },
    },
    {
      selectors: [{ name: 'irsk', label: 'irsk', type: 2, active: true, private: 1, protected: false }],
      style: { left: '1350px', top: '350px', position: 'absolute', 'z-index': 5, en: '', color: '#9E0004', 'font-family': 'Arial, Helvetica, sans-serif', 'font-size': '35px' },
    },
    {
      selectors: [{ name: 'ii1g', label: 'ii1g', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '1317px',
        top: '415px',
        position: 'absolute',
        'z-index': 6,
        color: '#9E0004',
        'font-size': '100px',
        en: '',
        width: '202.406px',
        height: '118px',
        'text-align': 'left',
        'font-family': 'Source Sans Pro, sans-serif',
      },
    },
    {
      selectors: [{ name: 'io53', label: 'io53', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '1429px',
        top: '401px',
        position: 'absolute',
        'z-index': 7,
        color: '#9E0004',
        'font-size': '65px',
        en: '',
        width: '202.406px',
        height: '118px',
        'text-align': 'left',
        'font-family': 'Source Sans Pro, sans-serif',
      },
    },
    {
      selectors: [{ name: 'i50u', label: 'i50u', type: 2, active: true, private: 1, protected: false }],
      style: { left: 0, top: 0, position: 'absolute', 'z-index': 1, height: '100%', width: '100%', en: '', 'Move:': '1', 'Bring-to:': '1' },
    },
    {
      selectors: [{ name: 'ibvgh', label: 'ibvgh', type: 2, active: true, private: 1, protected: false }],
      style: { left: '90px', top: '240px', position: 'absolute', 'z-index': 8, en: '', width: '580px' },
    },
    {
      selectors: [{ name: 'ia0w', label: 'ia0w', type: 2, active: true, private: 1, protected: false }],
      style: { left: '330px', top: '140px', position: 'absolute', 'z-index': 9, en: '', width: '310px', height: 'auto' },
    },
    {
      selectors: [{ name: 'ir9w8', label: 'ir9w8', type: 2, active: true, private: 1, protected: false }],
      style: { left: '641px', top: '434px', position: 'absolute', 'z-index': 3, en: '', 'Move:': '1' },
    },
    {
      selectors: [{ name: 'irqqy', label: 'irqqy', type: 2, active: true, private: 1, protected: false }],
      style: { left: '80px', top: '990px', position: 'absolute', 'z-index': 10, en: '', width: '50px' },
    },
    {
      selectors: [{ name: 'iipdl', label: 'iipdl', type: 2, active: true, private: 1, protected: false }],
      style: { left: '520px', top: '990px', position: 'absolute', 'z-index': 11, en: '', width: '50px' },
    },
    {
      selectors: [{ name: 'i3v1x', label: 'i3v1x', type: 2, active: true, private: 1, protected: false }],
      style: { left: '105.00115740740742px', top: '962.5144675925927px', position: 'absolute', 'z-index': 12, en: '', 'font-size': '30px', color: 'white' },
    },
    {
      selectors: [{ name: 'i5t8w', label: 'i5t8w', type: 2, active: true, private: 1, protected: false }],
      style: { left: '550px', top: '962px', position: 'absolute', 'z-index': 13, en: '', 'font-size': '30px', color: 'white' },
    },
    {
      selectors: [{ name: 'ixtqn', label: 'ixtqn', type: 2, active: true, private: 1, protected: false }],
      style: { left: '296px', top: '156px', position: 'absolute', 'z-index': 14, en: '', width: '270px' },
    },
    {
      selectors: [{ name: 'i1wyr', label: 'i1wyr', type: 2, active: true, private: 1, protected: false }],
      style: { left: '630px', top: '410px', position: 'absolute', 'z-index': 2, width: '1000px', en: '', 'Move:': '1' },
    },
    { selectors: [{ name: 'imn5i', label: 'imn5i', type: 2, active: true, private: 1, protected: false }], style: { left: '1595px', top: '48px', position: 'absolute', 'z-index': 15, en: '' } },
  ],
  components: [
    { content: '', attributes: { 'data-gjs-drag-mode': false }, dmode: 0 },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'iex9', src: getTemplateAssetDirectory() + 'RestaurantSpecial/Background.svg', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'RestaurantSpecial/Background.svg',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      status: 'hovered',
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { src: getTemplateAssetDirectory() + 'RestaurantSpecial/Background2.png', 'data-gjs-drag-mode': false, id: 'i50u' },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'RestaurantSpecial/Background2.png',
      activeOnRender: 0,
      scriptUpdated: 1,
      view: '',
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'ii4n', src: getTemplateAssetDirectory() + 'RestaurantSpecial/Promo.svg', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'RestaurantSpecial/Promo.svg',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">PROMO</div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'irsk' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>$9</b></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'ii1g' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>.99</b></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'io53' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      view: '',
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'ibvgh', src: getTemplateAssetDirectory() + 'RestaurantSpecial/PizzaSpecial.svg', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'RestaurantSpecial/PizzaSpecial.svg',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { src: getTemplateAssetDirectory() + 'RestaurantSpecial/Special.svg', 'data-gjs-drag-mode': false, id: 'ia0w' },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'RestaurantSpecial/Special.svg',
      activeOnRender: 0,
      view: '',
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'ir9w8', src: getTemplateAssetDirectory() + 'RestaurantSpecial/Pizza.png', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'RestaurantSpecial/Pizza.png',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'irqqy', src: getTemplateAssetDirectory() + 'RestaurantSpecial/Icon1.svg', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'RestaurantSpecial/Icon1.svg',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'iipdl', src: getTemplateAssetDirectory() + 'RestaurantSpecial/Icon2.svg', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'RestaurantSpecial/Icon2.svg',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">www.yourwebsite.com</div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'i3v1x' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">www.yourwebsite.com</div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'i5t8w' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      view: '',
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'ixtqn', src: getTemplateAssetDirectory() + 'RestaurantSpecial/Flowers.svg', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'RestaurantSpecial/Flowers.svg',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'i1wyr', src: getTemplateAssetDirectory() + 'RestaurantSpecial/Lines.svg', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'RestaurantSpecial/Lines.svg',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'imn5i', src: getTemplateAssetDirectory() + 'RestaurantSpecial/Logo.png', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'RestaurantSpecial/Logo.png',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
  ],
  html: '',
  css: '* { box-sizing: border-box; } body {margin: 0;}#iex9{left:0;top:0;position:absolute;z-index:0;height:100%;width:100%;}#ii4n{left:1295px;top:293px;position:absolute;z-index:4;width:310px;}.texts{font-size:45px;padding-top:35px;padding-right:35px;padding-bottom:35px;padding-left:35px;}#irsk{left:1350px;top:350px;position:absolute;z-index:5;color:#9E0004;font-family:Arial, Helvetica, sans-serif;font-size:35px;}#ii1g{left:1317px;top:415px;position:absolute;z-index:6;color:#9E0004;font-size:100px;width:202.406px;height:118px;text-align:left;font-family:Source Sans Pro, sans-serif;}#io53{left:1429px;top:401px;position:absolute;z-index:7;color:#9E0004;font-size:65px;width:202.406px;height:118px;text-align:left;font-family:Source Sans Pro, sans-serif;}#i50u{left:0;top:0;position:absolute;z-index:1;height:100%;width:100%;Move::1;Bring-to::1;}#ibvgh{left:90px;top:240px;position:absolute;z-index:8;width:580px;}#ia0w{left:330px;top:140px;position:absolute;z-index:9;width:310px;height:auto;}#ir9w8{left:641px;top:434px;position:absolute;z-index:3;Move::1;}#irqqy{left:80px;top:990px;position:absolute;z-index:10;width:50px;}#iipdl{left:520px;top:990px;position:absolute;z-index:11;width:50px;}#i3v1x{left:105.00115740740742px;top:962.5144675925927px;position:absolute;z-index:12;font-size:30px;color:white;}#i5t8w{left:550px;top:962px;position:absolute;z-index:13;font-size:30px;color:white;}#ixtqn{left:296px;top:156px;position:absolute;z-index:14;width:270px;}#i1wyr{left:630px;top:410px;position:absolute;z-index:2;width:1000px;Move::1;}#imn5i{left:1595px;top:48px;position:absolute;z-index:15;}',
  thumbnail: getTemplateAssetDirectory() + '/RestaurantSpecial/thumbnail.png',
};
