import { Injectable } from '@angular/core';
import {Workspace} from "../types/Workspace";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {AngularFirestoreCollection} from "@angular/fire/compat/firestore/collection/collection";
import {FireSessionService} from "./fire-session.service";
import {AlertService} from "./alert.service";
import {TranslateService} from "@ngx-translate/core";
import {Router} from "@angular/router";
import {MeteredMonthlyUsage} from "../types/MeteredMonthlyUsage";
import { increment } from '@angular/fire/firestore';
import {isMeteredCurrentUsage, MeteredCurrentUsage} from "../types/MeteredCurrentUsage";

export const DEFAULT_STORAGE_ALLOWED_PER_UNIT = 20 * 1024 * 1024 * 1024;//20GB
//export const DEFAULT_STORAGE_ALLOWED_PER_UNIT = 1 * 1024 * 1024;//1MB//DEBUG
export const DEFAULT_BANDWIDTH_ALLOWED_PER_UNIT = 25 * 1024 * 1024 * 1024;//25GB
//export const DEFAULT_BANDWIDTH_ALLOWED_PER_UNIT = 1 * 1024 * 1024;//1MB//DEBUG

@Injectable({
  providedIn: 'root'
})
export class WorkspacesService {

  constructor(private fireDB: AngularFirestore,
              private alertService: AlertService,
              private router: Router,
              private translate: TranslateService,
              private fireAuth: AngularFireAuth,
              private sessionService: FireSessionService) {

  }

  createWorkspace(workspace: Workspace) {
    workspace.usersWithAccessToWorkspace = [this.sessionService.currentFireUser.email];
    let workspaceCreationRequest = this.fireDB.collection('workspaces').doc(workspace.id).set(this.serializeObject(workspace));
    this.fireDB.collection('workspaces').doc(workspace.id).collection('workspaceUsers').doc(this.sessionService.currentFireUser.email).set({
      email: this.sessionService.currentFireUser.email,
      role: 'ADMIN'
    });
    return workspaceCreationRequest;
  }

  deleteWorkspace(workspace: Workspace) {
    return this.fireDB.collection('workspaces').doc(workspace.id).delete();
  }

  updateWorkspace(workspace: Workspace) {
    return this.fireDB.collection('workspaces').doc(workspace.id).update(this.serializeObject(workspace));
  }

  getAllWorkspacesCollection():AngularFirestoreCollection<Workspace> {
    return this.fireDB.collection<Workspace>('/workspaces', ref => ref.where('usersWithAccessToWorkspace', 'array-contains', this.sessionService.currentFireUser.email));
  }

  setCurrentWorkspace(workspace: Workspace) {
    this.sessionService.currentWorkspace.next(workspace);
  }

  getMeteredCurrentUsage():Promise<MeteredCurrentUsage> {
    let meteredCurrentUsage:MeteredCurrentUsage = {
      totalStorageUsed: 0,
      totalStorageAllowed: DEFAULT_STORAGE_ALLOWED_PER_UNIT,//this is just a default value, the DB will calculate the real value according to actual # units as fetched below
      totalBandwidthAllowed: DEFAULT_BANDWIDTH_ALLOWED_PER_UNIT//same comment here
    };

    return this.fireDB.doc<Workspace>(this.sessionService.getPathToCurrentWorkspace()).get().toPromise().then((res) => {
      try {
        if (res) {
          let data = res.data();
          if (data && typeof data.meteredCurrentUsage !== 'undefined' && isMeteredCurrentUsage(data.meteredCurrentUsage)) {
            meteredCurrentUsage = {...meteredCurrentUsage, ...data.meteredCurrentUsage};
            //avoid negative numbers, shouldn't happen but happens during testing, manually deleting files from FB
            if (typeof meteredCurrentUsage.totalStorageUsed !== 'undefined' && meteredCurrentUsage.totalStorageUsed < 0) {
              meteredCurrentUsage.totalStorageUsed = 0;
            }
          }
        }
      } catch (e) {}
      return meteredCurrentUsage;
    }).catch(() => {
      return meteredCurrentUsage;
    });

  }

  incrementMonthlyStorageUsage(amount:number) {
    const amountToIncrement = increment(amount);//maybe be positive or negative
    return this.fireDB.doc(this.sessionService.getPathToCurrentWorkspace()).set({
      meteredCurrentUsage: {
        totalStorageUsed: amountToIncrement
      }
    }, {merge: true});
  }

  getCurrentMonthMeteredUsage() {
    let meteredMonthlyUsage:MeteredMonthlyUsage = {
      bandwidthUsed: 0
    };
    return this.fireDB.doc<MeteredMonthlyUsage>(this.sessionService.getPathToCurrentWorkspaceMeteredUsage()).get().toPromise().then(res => {
      try {
        if (res && res.data()) {
          let data = res.data();
          if (data && typeof data.bandwidthUsed !== 'undefined') {
            meteredMonthlyUsage = {...meteredMonthlyUsage, ...data};
          }
        }
      } catch (e) {}

      return meteredMonthlyUsage;
    }).catch(() => {
      return meteredMonthlyUsage;
    });
  }

  /*
    incrementMonthlyStorageUsage(amount:number) {
      const amountToIncrement = increment(amount);//maybe be positive or negative
      return this.fireDB.doc<MeteredMonthlyUsage>(this.sessionService.getPathToCurrentWorkspaceMeteredUsage()).set({
        storageUsed: amountToIncrement
      }, {merge: true});
    }*/

  refreshCurrentWorkspace() {
    this.fireDB.doc<Workspace>(this.sessionService.getPathToCurrentWorkspace()).get().toPromise().then(res => {
      this.setCurrentWorkspace(res.data());
    });
  }

  getCurrentWorkspace() {
    return this.sessionService.currentWorkspace.getValue();
  }

  listenToWorkspaceChanges() {
    // console.log('listenToSidebarStatusChanges', this.sidebarShouldBeDisabled.asObservable());
    return this.sessionService.currentWorkspace.asObservable();
  }

  serializeObject(object) {
    return JSON.parse(JSON.stringify(object));
  }

  redirectToBadBillingPage() {
    this.alertService.createToast(this.translate.instant('Billing.InvalidSubscriptionToast'), 10000, 'danger');
    this.router.navigateByUrl('/billing');
  }
}
