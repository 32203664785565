import { Component } from '@angular/core';
import { User } from '@angular/fire/auth/firebase';
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Workspace} from "./types/Workspace";
import {WorkspacesService} from "./services/workspaces.service";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {SidebarManagerService} from "./services/sidebar/sidebar-manager.service";
import {AppSettingsService} from "./services/app-settings.service";
import {WorkspaceUser} from "./types/WorkspaceUser";
import {WorkspaceUsersService} from "./services/workspace-users.service";
import {DebugLogService} from "./services/debug-log.service";
import {AlertService} from "./services/alert.service";
import {AngularFireAnalytics} from "@angular/fire/compat/analytics";
import {FireSessionService} from "./services/fire-session.service";

export const NON_MOBILE_FEATURE_BREAKPOINT = 1200;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {

  currentUser: User;
  currentWorkspace: Workspace;
  currentWorkspaceUser: WorkspaceUser;

  constructor(private fireAuth: AngularFireAuth,
              private router: Router,
              private translate: TranslateService,
              public sidebarManagerService: SidebarManagerService,
              private appSettingsService: AppSettingsService,
              private fireAnalytics: AngularFireAnalytics,
              private fireSession: FireSessionService,
              private alertService: AlertService,
              private debugLogService: DebugLogService,
              private workspaceUsersService: WorkspaceUsersService,
              private workspacesService: WorkspacesService
  ) {
    this.fireAuth.onAuthStateChanged(res => {
      this.currentUser = res;

      try {
        this.fireAnalytics.setUserId(this.fireSession.currentFireUser.email);
        this.fireAnalytics.setUserProperties({user_type: 'HUMAN', firebase_uid: this.fireSession.currentFireUser.uid});
      } catch (e) {}

      if (this.currentUser) {
        if (this.router.url === '/') {
          try {
            setTimeout(() => {
              let lastWorkspaceUsed = JSON.parse(localStorage.getItem('lastWorkspaceUsed'));
              console.log('lastWorkspaceUsed', lastWorkspaceUsed);
              if (lastWorkspaceUsed) {
                this.workspacesService.setCurrentWorkspace(lastWorkspaceUsed);
              }
            });
          } catch (e) {
            console.log('e', e);
          }
        }
      }
    });

    this.workspaceUsersService.listenToCurrentLoggedInUserChanges().subscribe((res) => {
      if (res && res.email) {
        this.currentWorkspaceUser = res;

        if (this.router.url === '/' || this.router.url === '/workspaces') {
          this.router.navigateByUrl('/slides');
        }
      }
    });

    this.workspacesService.listenToWorkspaceChanges().subscribe(workspace => {
      this.currentWorkspace = workspace;

      if (workspace) {
        try {
          localStorage.setItem('lastWorkspaceUsed', JSON.stringify(workspace));
        } catch (e) {}
      }
    });

    this.initLanguages();

    this.enableDebugLog();
  }


  initLanguages() {
    this.translate.addLangs(['en', 'fr']);
    this.translate.setDefaultLang('en');
  }

  enableDebugLog() {
    document.addEventListener('keydown', (ev) => {
      if (ev) {
        if (ev.ctrlKey && ev.key === 'l') {
          try {
            ev.preventDefault();
            ev.stopPropagation();
          } catch (e) {}
          this.debugLogService.sendLog().finally(() => {
            this.alertService.createToast('Debug log submitted');
          });
        }
      }
    });
  }

  logout() {
    this.fireAuth.signOut().then(() => {}).catch(() => {}).finally(() => {
      this.workspacesService.setCurrentWorkspace(undefined);
      this.router.navigateByUrl('/login');
    });
  }
}
