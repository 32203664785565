import { Injectable } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ImportTrackService {
  song: any;
  constructor(private popoverCtrl: PopoverController) {}

  setSong(song: any): void {
    this.song = song;
  }

  getSong(): any {
    return this.song;
  }
}
