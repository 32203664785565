// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  app_version: '1.2.2',
  stripe: {
    STRIPE_PRICE_ID_MONTHLY: 'price_1MS2pHCD8LGEnG19zu4wAbrC',
    STRIPE_PRICE_ID_YEARLY: 'price_1MS2pHCD8LGEnG19Jx8RXplU'
  },
  firebase: {
    apiKey: "AIzaSyBjD-uJjIZsO4tlf7KHNe_41QkP1e-QXls",
    authDomain: "digisign-cloud.firebaseapp.com",
    projectId: "digisign-cloud",
    storageBucket: "digisign-cloud.appspot.com",
    messagingSenderId: "869175688052",
    appId: "1:869175688052:web:a16aefa6356d3cd9e10a21",
    measurementId: "G-PVVE8P7LYL",
    experimentalAutoDetectLongPolling: true
  },
  production: false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
