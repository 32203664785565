import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertService } from '../services/alert.service';
import {SchedulesService} from "../services/schedules.service";
import {TranslateService} from "@ngx-translate/core";
export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root',
})
export class LeaveObjectScheduleGuard implements CanDeactivate<CanComponentDeactivate> {
  constructor(private schedulesService: SchedulesService, private alertService: AlertService, private translateService: TranslateService) {}

  async canDeactivate(component: CanComponentDeactivate, next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    var playerType = this.schedulesService.getPlayerType();
    var scheduler = this.schedulesService.getScheduler();
    var leave: boolean;

    var savedSchedule = await this.schedulesService.getScheduleForPlayerType(playerType);
    let currentSchedule = this.schedulesService.cleanScheduler(scheduler);

    if (savedSchedule && savedSchedule.dhtmlx_json_data !== currentSchedule) {
      leave = await this.alertService.confirm(
        this.translateService.instant('Editor.AreYouSure'),
        this.translateService.instant('Editor.LeaveWithoutSaving'),
        this.translateService.instant('Common.Yes'),
        this.translateService.instant('Common.No')
      );
    } else {
      leave = true;
    }

    return leave;
  }
}
