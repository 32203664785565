import { Pipe, PipeTransform } from '@angular/core';

/**
 * more concise time format. eg. 30s, 1m30, 1h30m20s
 */

@Pipe({
  name: 'prettyTimePipe',
})
export class PrettyTimePipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    var sec_num = parseInt(value, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    let hasHours = false;
    let hasMinutes = false;
    let hasSeconds = false;

    let prettyTime = '';
    if (hours && hours > 0) {
      hasHours = true;
      prettyTime += hours + 'h';
    }

    if (minutes && minutes > 0) {
      hasMinutes = true;
      let prettyMinutes = minutes + '';
      if (hasHours && minutes < 10) {
        prettyMinutes = '0' + minutes;
      }
      prettyTime += prettyMinutes + 'm';
    }

    if (seconds && seconds > 0) {
      hasSeconds = true;
      let prettySeconds = seconds + '';

      if ((hasHours || hasMinutes) && seconds < 10) {
        prettySeconds = '0' + seconds;
      }

      prettyTime += prettySeconds;
      prettyTime += 's';
    }

    return prettyTime;
  }
}
