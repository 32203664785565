import {Component, OnInit, Type, ViewChild} from '@angular/core';
import { ModalController, IonSearchbar } from '@ionic/angular';
import { AppComponent } from '../../../app.component';
import { TemplatesService } from '../../../services/editor/templates.service';
import { AlertService } from '../../../services/alert.service';
import { AppSettingKey, LicenseType, LiteParams } from '../../../common/global-constants';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {AppSettingsService} from "../../../services/app-settings.service";
import {UserSettingsService} from "../../../services/user-settings.service";
import {Template} from "../../../types/Template";
import {StaticInjector} from "../../../utils/StaticInjector";

@Component({
  selector: 'app-templates',
  templateUrl: './templates.component.html',
  styleUrls: ['./templates.component.scss'],
})
export class TemplatesComponent implements OnInit {
  static editor: any;
  templatesSource: Template[];
  templates: Template[];
  templatesSearched: Template[];
  templateDimensions: any[];
  dimensionSelectedIndex: number;

  @ViewChild('mySearchbar', { static: false }) viewSearchbar: IonSearchbar;

  constructor(
    public modalCtrl: ModalController,
    private templatesServices: TemplatesService,
    private alertService: AlertService,
    private appSettingsService: AppSettingsService,
    private userSettingsService: UserSettingsService,
    private sanitizer: DomSanitizer
  ) {
    this.dimensionSelectedIndex = 0;
  }

  static openModal(editor: any) {
    TemplatesComponent.editor = editor;
    const modalController = StaticInjector.getInjector().get<ModalController>(ModalController as Type<ModalController>);
    modalController
      .create({
        component: TemplatesComponent,
        cssClass: 'templates-modal',
      })
      .then((modal) => {
        // Show modal
        modal.present().then(() => {
          modal.onDidDismiss().then((data) => {
            if (data.data) {
              this.editor.load((res) => {});
            }
          });
        });
      });
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.loadTemplates();
  }

  dismissModal() {
    this.modalCtrl.dismiss();
  }

  loadTemplates() {
    this.templatesServices
      .getTemplates()
      .then((templatesSource) => {
        this.templatesSource = this.sortTemplates(templatesSource);
        this.getTemplateDimensions();
        this.setDefaultTemplate();
      })
      .catch((e) => {
        this.alertService.createToast('Failed to load templates', undefined, 'danger');
        console.log('e', e);
      });
  }

  sortTemplates(templates: any[]) {
    let index = templates.findIndex((row) => {
      return row.id === 'default-blank-1';
    });

    let sortedTemplates = [templates[index]];
    templates.splice(index, 1);

    return sortedTemplates.concat(templates);
  }

  setDefaultTemplate() {
    this.userSettingsService.getValue('default_template_ratio').then((defaultRatio) => {
      if (!defaultRatio) this.dimensionSelectedIndex = 0;
      else {
        this.templateDimensions.forEach((dimension, index) => {
          if (dimension.name == defaultRatio) {
            this.dimensionSelectedIndex = index;
            return false;
          }
        });
      }

      return true;
    }).catch((e) => {
      this.dimensionSelectedIndex = 0;
      return true;

    }).then(res => {
      this.setTemplates();
    });
  }

  setTemplates() {
    let templates = this.getTemplatesByDimension(this.templateDimensions[this.dimensionSelectedIndex]);
    this.templates = templates;
    this.templatesSearched = templates;
  }

  getTemplateDimensions() {
    this.templateDimensions = [];
    this.templateDimensions.push({
      name: '16:9',
      dimensions: [1920, 1080],
    });
    let uniqueDimensions = {};
    this.templatesSource.forEach((template) => {
      let dimensions = template.dimensions;
      if (!dimensions) dimensions = [1920, 1080]; // default
      const dimensionsInches = [this.pixelsToInches(dimensions[0]), this.pixelsToInches(dimensions[1])];
      let ratio;
      // don't reduce this standard ratio
      if ((dimensionsInches[0] == 16 && dimensionsInches[1] == 10) || (dimensionsInches[0] == 10 && dimensionsInches[1] == 16)) ratio = dimensionsInches;
      else ratio = this.getRatio(dimensionsInches[0], dimensionsInches[1]);
      let dimensionName = ratio[0] + ':' + ratio[1];
      if (dimensionName == '16:9') return;
      if (uniqueDimensions[dimensionName]) return;
      uniqueDimensions[dimensionName] = 1;
      this.templateDimensions.push({
        name: dimensionName,
        dimensions,
      });
    });
  }

  // greatest common divisor: used to caluclate ratio
  gcd(x, y) {
    return y == 0 ? x : this.gcd(y, x % y);
  }

  getRatio(x, y) {
    const r = this.gcd(x, y),
      x2 = x / r,
      y2 = y / r;
    if (x2 == 5 && y2 == 8) return [10, 16];
    if (x2 == 8 && y2 == 5) return [16, 10];
    return [x2, y2];
  }

  pixelsToInches(pixels) {
    return Math.floor(pixels / 120);
  }

  getTemplatesByDimension(dimension: any) {
    let templates = [];
    this.templatesSource.forEach((template) => {
      let templateDimension;
      if (!template.dimensions) templateDimension = [1920, 1080];
      else templateDimension = template.dimensions;
      if (dimension.dimensions[0] == templateDimension[0] && dimension.dimensions[1] == templateDimension[1]) {
        templates.push(template);
      }
    });
    return templates;
  }

  filterTemplates(searchfor: any) {
    if (searchfor && searchfor.trim() != '') {
      this.templatesSearched = this.templates.filter((template) => {
        let nameMatch = template.name.toLowerCase().indexOf(searchfor.toLowerCase()) > -1;

        let keywordMatch = false;
        if (typeof template.defaultTemplateKeywords !== 'undefined' && template.defaultTemplateKeywords.length) {
          for (let i = 0; i < template.defaultTemplateKeywords.length; i++) {
            if (template.defaultTemplateKeywords[i].toLowerCase().indexOf(searchfor.toLowerCase()) > -1) {
              keywordMatch = true;
            }
          }
        }

        return nameMatch || keywordMatch;
      });
    } else {
      this.templatesSearched = this.templates;
    }
  }

  handleDimensionChange(event: any) {
    this.dimensionSelectedIndex = parseInt(event.target.value);
    this.setTemplates();
    let dimension = this.templateDimensions[this.dimensionSelectedIndex];
    this.userSettingsService.setValue('default_template_ratio', dimension.name);
  }

  handleInput(event: any) {
    this.filterTemplates(event.target.value);
  }

  deleteTemplate(template: Template) {
    this.templatesServices
      .deleteTemplate(template.id)
      .then((resp) => {
        this.templatesServices
          .getTemplates()
          .then((templates) => {
            this.templatesSource = templates;
            this.getTemplateDimensions();
            this.setTemplates();
            this.filterTemplates(this.viewSearchbar.value);
          })
          .catch((e) => {
            this.alertService.createToast('Failed to load templates', undefined, 'danger');
            console.log('e', e);
          });
      })
      .catch((e) => {
        this.alertService.createToast('failed to delete template: ', undefined, 'danger');
        console.log('e', e);
      });
  }

  loadTemplate(template: Template) {
    this.templatesServices.openFromTemplate = true;
    this.templatesServices.templateLoaded = template;
    return this.modalCtrl.dismiss(template);
  }

  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
}
