import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import {  UnitGroup } from '../../../../types/UnitGroup';
import { Unit } from '../../../../types/Unit';
import { AlertService } from '../../../alert.service';
import { Router } from '@angular/router';
import {UnitsService} from "../../../units.service";
import {UnitGroupsService} from "../../../unitGroups.service";
import {TranslateService} from "@ngx-translate/core";
import {WorkspaceUsersService} from "../../../workspace-users.service";


@Component({
  selector: 'app-add-unit-group',
  templateUrl: './add-unit-group-modal.component.html',
  styleUrls: ['./add-unit-group-modal.component.scss'],
})
export class AddUnitGroupModalComponent implements OnInit {
  @Input() units: Unit[];
  editingUnitGroup: UnitGroup;
  addUnitGroup: UnitGroup;
  selectUnits

  constructor(
    private navParams: NavParams,
    private modalController: ModalController,
    private alertCtrl: AlertController,
    private alertService: AlertService,
    private router: Router,
    private unitsService: UnitsService,
    private unitGroupsService: UnitGroupsService,
    private translate: TranslateService,
    private workspaceUsersService: WorkspaceUsersService
  ) {}

  ngOnInit() {
    if (this.navParams.get('editingUnitGroup')) {
      this.editingUnitGroup = this.navParams.get('editingUnitGroup');
    }
    else if (this.navParams.get('addUnitGroup')) {
      this.addUnitGroup = this.navParams.get('addUnitGroup');
    }
    else if (this.navParams.get('selectUnits')) {
      this.selectUnits = this.navParams.get('selectUnits');
    }

    this.unitsService.getAllUnitsUnwatched().then(async (res) => {
      console.log('res', res);
      // for offline development
      res.push(new Unit('0', '192.168.0.22', 'admin', 'admin', 'admin'))
      res.push(new Unit('1', '192.168.0.22', 'admin1', 'admin', 'admin1'))
      res.push(new Unit('2', '192.168.0.22', 'admin2', 'admin', 'admin2'))
      res.push(new Unit('3', '192.168.0.22', 'admin3', 'admin', 'admin3'))
      res.push(new Unit('4', '192.168.0.22', 'admin4', 'admin', 'admin4'))

      this.units = await this.workspaceUsersService.getUnitsWithUserPermissions(['VIEW_UNITS', 'EDIT_UNITS'], 'AND', res)

      if (this.editingUnitGroup) {
        for (let i = 0; i < this.units.length; i++) {
          if (this.units[i].group_id && this.units[i].group_id == this.editingUnitGroup.id) {
            (<any>this.units[i]).isChecked = true;
          } else {
            (<any>this.units[i]).isChecked = false;
          }
        }
      } else if (this.selectUnits) {
        for (let i = 0; i < this.units.length; i++) {
          for (let j = 0; j < this.selectUnits.length; j++) {
            if (this.units[i].id && this.units[i].id === this.selectUnits[j].id) {
              (<any>this.units[i]).isChecked = true;
              break
            } else {
              (<any>this.units[i]).isChecked = false;
            }
          }
        }
      }
      else {
        for (let i = 0; i < this.units.length; i++) {
          (<any>this.units[i]).isChecked = false;
        }
      }

      this.units = this.units.sort((a, b) => {
        let valA = 1,
          valB = 1;
        if (a.group_id && a.group_id !== this.editingUnitGroup.id) {
          valA = 0;
        }
        if (b.group_id && b.group_id !== this.editingUnitGroup.id) {
          valB = 0;
        }

        return valB - valA;
      });
    });
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  trackUnits(index, unit) {
    return unit.id; // or item.id
  }

  saveUnitGroup() {
    let unitsToSyncWithGroup: Unit[] = [];
    let syncGroup: UnitGroup;
    let allPendingPromises = [];

    if (this.selectUnits) {
      this.modalController.dismiss(this.getCheckedUnits());
    }
    else if (this.addUnitGroup) {
      this.unitGroupsService.promptUserForUniqueUnitGroupName().then((unitGroupName) => {
        let unit_ids = [];

        for (let i = 0; i < this.units.length; i++) {
          if (typeof (<any>this.units[i]).isChecked !== 'undefined' && (<any>this.units[i]).isChecked) {
            unit_ids.push(this.units[i].id);
          }
        }

        let unitGroup = new UnitGroup(<string>unitGroupName);
        this.unitGroupsService.saveUnitGroup(unitGroup).then(() => {
          this.unitsService.setGroupToUnits(unit_ids, unitGroup);
          this.modalController.dismiss(unitGroup);

        }).catch(err => {

          this.modalController.dismiss();
        });
      });
    } else if(this.editingUnitGroup) {
      //ie editing a group
      this.editingUnitGroup = new UnitGroup(this.editingUnitGroup.name, this.editingUnitGroup.id);

      this.unitGroupsService
        .saveUnitGroup(this.editingUnitGroup)
        .then((resUnitGroup) => {})
        .catch((err) => {})
        .finally(() => {
          let areUnitsBeingReassignedToAnotherGroup = false;
          syncGroup = this.editingUnitGroup;

          for (let i = 0; i < this.units.length; i++) {
            if (typeof (<any>this.units[i]).isChecked !== 'undefined' && (<any>this.units[i]).isChecked === true) {
              if (this.units[i].group_id && this.units[i].group_id !== this.editingUnitGroup.id) {
                areUnitsBeingReassignedToAnotherGroup = true;
                unitsToSyncWithGroup.push(this.units[i]);
              } else if (!this.units[i].group_id) {
                unitsToSyncWithGroup.push(this.units[i]);
              }
            }
          }

          let doSave = () => {
            for (let i = 0; i < this.units.length; i++) {
              if (typeof (<any>this.units[i]).isChecked !== 'undefined' && (<any>this.units[i]).isChecked === true) {
                this.units[i].group_id = this.editingUnitGroup.id;
              } else if (this.units[i].group_id && this.units[i].group_id == this.editingUnitGroup.id) {
                //ie it was unchecked
                this.units[i].group_id = '';
              }

              allPendingPromises.push(this.unitsService.saveUnit(this.units[i]).catch((err) => {}));
            }

            this.modalController.dismiss(this.editingUnitGroup);
            //sync over here

            if (unitsToSyncWithGroup.length && syncGroup) {
              this.alertService
                .confirm(
                  this.translate.instant('Units.AddUnitGroupModal.SyncUnits'),
                  this.translate.instant('Units.AddUnitGroupModal.UnitsHaveBeenAddedTo') +
                  syncGroup.name +
                  '.<br /><br />' +
                  this.translate.instant('Units.AddUnitGroupModal.WouldYouLikeToSyncThemToGroup') +
                  '?',
                  this.translate.instant('Units.AddUnitModal.SyncNow'),
                  this.translate.instant('Units.AddUnitGroupModal.Later')
                )
                .then((res) => {
                  if (res) {
                    Promise.all(allPendingPromises).then((res) => {
                      this.router.navigate(['/object-schedule/group/' + syncGroup.id, { syncNow: true }]);
                    });
                  }
                });
            }
          };

          if (areUnitsBeingReassignedToAnotherGroup) {
            this.alertCtrl
              .create({
                header: this.translate.instant('Units.AddUnitGroupModal.ReassignUnits') + '?',
                message: this.translate.instant('Units.AddUnitGroupModal.WouldYouLikeToReassignThemToThisGroup') + '?',
                buttons: [
                  {
                    text: this.translate.instant('General.Cancel'),
                    role: 'cancel',
                  },
                  {
                    text: this.translate.instant('Common.Yes'),
                    handler: () => {
                      doSave();
                    },
                  },
                ],
              })
              .then((alert) => alert.present());
          } else {
            doSave();
          }
        });
    }
  }

  getCheckedUnits() {
    return this.units.filter((item) => typeof (<any>item).isChecked !== 'undefined' && (<any>item).isChecked === true);
  }
}
