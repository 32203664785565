import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {FireSessionService} from "./fire-session.service";
import {Workspace} from "../types/Workspace";
import {Unit} from "../types/Unit";
import {AngularFirestoreCollection} from "@angular/fire/compat/firestore/collection/collection";
import {UnitGroup} from "../types/UnitGroup";
import {AlertController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {AlertService} from "./alert.service";

@Injectable({
  providedIn: 'root'
})
export class UnitGroupsService {

  constructor(private fireDB: AngularFirestore,
              private fireAuth: AngularFireAuth,
              private alertCtrl: AlertController,
              private translate: TranslateService,
              private alertService: AlertService,
              private fireSessionService: FireSessionService) {
  }

  saveUnitGroup(unitGroup: UnitGroup) {
    return this.fireDB.doc(this.fireSessionService.getPathToCurrentWorkspace() + '/unitGroups/' + unitGroup.id).set(this.fireSessionService.serializeObject(unitGroup));
  }

  getAllUnitGroupsUnwatched():Promise<UnitGroup[]> {
    return new Promise<UnitGroup[]>((resolve, reject) => {
      this.getAllUnitGroupsCollection().get().toPromise().then(res => {
        if (res && res.docs && res.docs.length) {
          let unitGroups = [];
          let getUnitsInGroupPromises = [];
          for (let i = 0; i < res.docs.length; i++) {
            let unitGroupData = res.docs[i].data();
            if (unitGroupData) {
              let unitGroup = this.fireSessionService.deserializeUnitGroup(unitGroupData);

              getUnitsInGroupPromises.push(this.fireDB.collection<Unit>(this.fireSessionService.getPathToCurrentWorkspace() + '/units', ref => ref.where('group_id', '==', unitGroupData.id)).get().toPromise().then(resUnits => {
                let unitsInGroup = [];

                for (let j = 0; j < resUnits.docs.length; j++) {
                  unitsInGroup.push(this.fireSessionService.deserializeUnit(resUnits.docs[j].data()));
                }

                unitGroup.provideUnits(unitsInGroup);
                unitGroups.push(unitGroup);
              }).catch(() => {}));

            }
          }

          Promise.all(getUnitsInGroupPromises).then(res => {}).catch(() => {}).finally(() => {
            resolve(unitGroups);
          });
        } else {
          resolve([]);
        }
      }).catch(err => {
        console.log('err', err);
        reject(err);
      });
    });
  }

  getAllUnitGroupsCollection():AngularFirestoreCollection<UnitGroup> {
    return this.fireDB.collection<UnitGroup>(this.fireSessionService.getPathToCurrentWorkspace() + '/unitGroups');
  }

  deleteUnitGroup(unitGroup: UnitGroup) {
    let promises:Promise<any>[] = [];

    promises.push(this.fireDB.collection(this.fireSessionService.getPathToCurrentWorkspace() + '/units', ref => ref.where('group_id', '==', unitGroup.id)).get().toPromise().then(resUnitsInGroup => {
      for (let i = 0; i < resUnitsInGroup.docs.length; i++) {
        promises.push(resUnitsInGroup.docs[i].ref.update({group_id: ''}));
      }
      return true;
    }).catch(() => {}));

    promises.push(this.fireDB.doc(this.fireSessionService.getPathToCurrentWorkspace() + '/unitGroups/' + unitGroup.id).delete());

    return Promise.all(promises);
  }


  promptUserForUniqueUnitGroupName() {
    return new Promise((resolve, reject) => {
      this.alertCtrl
        .create({
          header: this.translate.instant('Units.AddUnitGroupModal.NameYourGroup'),
          inputs: [
            {
              name: 'unitGroupName',
              type: 'text',
              value: '',
              placeholder: this.translate.instant('Units.AddUnitGroupModal.GroupName'),
            },
          ],
          buttons: [
            {
              text: this.translate.instant('General.Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                console.log('Confirm Cancel');
                reject(new Error(''));
              },
            },
            {
              text: this.translate.instant('General.Ok'),
              handler: (input) => {
                // if input empty
                if (input.unitGroupName === '' || input.unitGroupName === null || input.unitGroupName === undefined) {
                  this.alertService.createToast(this.translate.instant('Common.YouMustSpecifyAName'), undefined, 'danger');
                  return false;
                } else {
                  // check if slide exists
                  this.getUnitGroupsByName(input.unitGroupName).then((resp) => {
                    if (resp !== undefined && resp !== null && resp.length) {
                      this.alertService.createToast(this.translate.instant('Common.PleaseUseADifferentName'), undefined, 'danger');
                      return false;
                    }
                    // on success
                    else {
                      resolve(input.unitGroupName);
                    }
                  });
                }
              },
            },
          ],
        })
        .then((alert) => alert.present().then(() => AlertService.EnableEnterToConfirm()));
    });
  }

  getUnitGroupsByName(name: string): Promise<UnitGroup[]> {
    return new Promise((resolve, reject) => {
      this.getAllUnitGroupsUnwatched().then((allUnitGroups) => {
        let unitGroups: UnitGroup[] = [];

        if (allUnitGroups) {
          for (let i = 0; i < allUnitGroups.length; i++) {
            if (allUnitGroups[i].name === name) {
              unitGroups.push(allUnitGroups[i]);
            }
          }
        }

        resolve(unitGroups);
      });
    });
  }

  getUnitGroup(id: string):Promise<UnitGroup> {
    return new Promise<UnitGroup>((resolve, reject) => {
      this.fireDB.doc<UnitGroup>(this.fireSessionService.getPathToCurrentWorkspace() + '/unitGroups/' + id).get().toPromise().then(res => {
        if (res.data()) {
          let unitGroupData = res.data();

          let unitGroup = this.fireSessionService.deserializeUnitGroup(unitGroupData);

          this.fireDB.collection<Unit>(this.fireSessionService.getPathToCurrentWorkspace() + '/units', ref => ref.where('group_id', '==', unitGroupData.id)).get().toPromise().then(resUnits => {
            let unitsInGroup = [];

            for (let j = 0; j < resUnits.docs.length; j++) {
              unitsInGroup.push(this.fireSessionService.deserializeUnit(resUnits.docs[j].data()));
            }

            unitGroup.provideUnits(unitsInGroup);
            resolve(unitGroup);

          }).catch(() => {
            reject();
          });

        } else {
          reject();
        }
      }).catch(err => {
        reject();
      });
    });

  }
}
