import { Injectable } from '@angular/core';
import { Base64 } from 'js-base64';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FireSessionService} from "./fire-session.service";

@Injectable({
  providedIn: 'root',
})
export class DebugLogService {
  messages: string[] = [];

  constructor(private fireDB: AngularFirestore, private fireSession: FireSessionService) {}

  logMessage(message: string) {
    if (this.messages.length >= 150) {
      //max size of messages
      this.messages = [];
    }

    this.messages.push(message);
  }

  sendLog() {
    let ttl = new Date();
    ttl.setSeconds((ttl.getSeconds() + 60))//expire after 1m
    return this.fireDB.collection('outbound_digisign_email_queue').add({
      to: 'a.abitbol@muxlab.com,h.karayenzi@muxlab.com',
      ttl: ttl,
      message: {
        subject: 'User-Submitted Error Log ',
        html: '<div style="width:500px;max-width:100%;box-shadow: #ccc 0px 0px 7px 1px; border-radius: 3px; margin:0 auto;  font-family:sans-serif">' +
          '<br />' +
          '<b>FROM: </b> ' + this.fireSession.currentFireUser.email +
          '<br />' +
          '<p style="text-align: center;">' +
          this.messages.join('<br /><br />') +
          '</p>' +
          '<br />' +
          'END OF LOG' +
          '</div>',
      },
    })
  }
}
