export const AppErrors = {
  S_UP001: {
    code: 'S_UP001',
    title: 'Unit disconnected',
  },
  U_SC001: {
    code: 'U_SC001',
    title: 'Unable to set default screen',
  },
  D_UP001: {
    code: 'D_UP001',
    title: 'Error uploading the keyword dictionary',
  },
};

export function getAppError(e): AppError | null {
  if (!e) {
    return null;
  }

  let appError: AppError = null;
  if (typeof AppErrors[e.toString()] !== 'undefined') {
    appError = AppErrors[e.toString()];
  }

  return appError;
}

export interface AppError {
  code: string;
  title: string;
  long_description?: string;
}
