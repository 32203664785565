import { Component, OnInit } from '@angular/core';
import { Unit } from '../../../types/Unit';
import { UnitGroup } from '../../../types/UnitGroup';
import { AlertController, ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-show-unit-group',
  templateUrl: './show-unit-group.component.html',
  styleUrls: ['./show-unit-group.component.scss'],
})
export class ShowUnitGroupComponent implements OnInit {
  units: Unit[] = [];
  unitGroup: UnitGroup;

  constructor(private navParams: NavParams, private modalController: ModalController) {}

  ngOnInit() {
    this.units = [];

    if (this.navParams.get('unitGroup')) {
      this.unitGroup = this.navParams.get('unitGroup');
      this.units = this.unitGroup.getUnits();
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }

  trackUnits(index, unit) {
    return unit.id; // or item.id
  }
}
