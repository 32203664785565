

export class Template {
  id: string;
  renderedHTML: undefined;
  name: string;
  durationInSeconds: number;
  components: any[];
  html: string;
  css: string;
  highest_zIndex?: number;
  thumbnail?: string;
  assets?: any[];
  styles?: any[];
  isDefaultTemplate?: boolean;
  defaultTemplateKeywords?: string[];
  dimensions?: number[];
  isInteractiveModeOn?: boolean;
  isTutorial?: boolean;

  constructor(
    name: string,
    renderedHTML: undefined,
    durationInSeconds: number,
    components: any[],
    html: string,
    css: string,
    highest_zIndex?: number,
    thumbnail?: string,
    assets?: string[],
    styles?: string[],
    id?: null | string,
    dimensions?: number[],
    isInteractiveModeOn?: boolean,
    isTutorial?: boolean
  ) {
    //TODO generate unique ID
    if (!id) {
      this.id = Math.random().toString(36).substr(2, 5);
    } else {
      this.id = id;
    }

    this.name = name;
    this.durationInSeconds = durationInSeconds;
    this.highest_zIndex = highest_zIndex;
    this.assets = assets;
    this.styles = styles;
    this.components = components;
    this.html = html;
    this.css = css;
    this.thumbnail = thumbnail;
    this.name = name;
    this.renderedHTML = renderedHTML;
    this.durationInSeconds = durationInSeconds;
    if (dimensions) this.dimensions = dimensions;
    if (isInteractiveModeOn) this.isInteractiveModeOn = isInteractiveModeOn;
    if (isTutorial) this.isTutorial = isTutorial;
  }
}

export const getTemplateAssetDirectory = () => {
  let path = 'https://cloud.muxlab.com/digisign/assets/default-template-assets/';

  if (window.location.href.includes('localhost:4200')) {
    path = 'assets/default-template-assets/';
  }

  return path;
};

