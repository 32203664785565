import { Injectable } from '@angular/core';
import {isPlaylist, Playlist} from "../types/Playlist";
import {AlertService} from "./alert.service";
import {isSchedule, Schedule} from "../types/Schedule";
import {AlertController} from "@ionic/angular";
import {Subject} from "rxjs";
import {Observable} from "rxjs-compat";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {FireSessionService} from "./fire-session.service";
import {AngularFirestoreCollection} from "@angular/fire/compat/firestore/collection/collection";
import {Unit} from "../types/Unit";
import {getIndividualUnitsFromObjectList, UnitsService} from "./units.service";
import {UnitGroupsService} from "./unitGroups.service";
import {getAppError} from "../types/AppError";
import {
  ContentUploadProgressUpdate,
  PlayerType,
  SlideUploadPayload
} from "./units/content-upload-show-progress-modal.service";
import {Slide} from "../types/Slide";
import {PlaylistsService} from "./playlists.service";
import {SlidesService} from "./slides.service";
import {API789Service} from "./api-789.service";

@Injectable({
  providedIn: 'root',
})

/**
 * This service contains business logic and pre-processing before calling the API classes who are responsible for
 * API communication only
 */
export class SchedulesService {
  scheduler: any;
  playerType: PlayerType;
  moreOptionsPopover: any = null;

  constructor(private fireDB: AngularFirestore,
              private fireAuth: AngularFireAuth,
              private fireSessionService: FireSessionService,
              private alertCtrl: AlertController,
              private playlistsService: PlaylistsService,
              private api_789: API789Service,
              private slidesService: SlidesService) {

  }

  getPathToSchedulesInWorkspace() {
    return this.fireSessionService.getPathToCurrentWorkspace() + '/schedules'
  }

  getAllSchedulesUnwatched():Promise<Schedule[]> {
    return new Promise<Schedule[]>((resolve, reject) => {

      this.getAllSchedulesCollection().get().toPromise().then(res => {
        let schedules:Schedule[] = [];

        for (let i = 0; i < res.docs.length; i++) {
          schedules.push(this.fireSessionService.deserializeSchedule(res.docs[i].data()));
        }
        resolve(schedules);

      }).catch((err) => {
        reject();
      });
    });
  }

  getAllSchedulesCollection():AngularFirestoreCollection<Schedule> {
    return this.fireDB.collection<Schedule>(this.getPathToSchedulesInWorkspace());
  }

  getScheduleForPlayerType(playerType: PlayerType): Promise<Schedule> {
    let id;
    if (playerType.object_id) {
      id = playerType.object_id;
    } else if (playerType.object && playerType.object.id) {
      id = playerType.object.id;
    } else {
      return Promise.resolve(null);
    }

    return this.fireDB.doc<Schedule>(this.getPathToSchedulesInWorkspace() + '/' + id).get().toPromise().then(res => {
      if (res.data()) {
        return Promise.resolve(res.data());
      } else {
        return Promise.reject();
      }
    }).catch(err => {
      return Promise.resolve(undefined);
    });

  }

  uploadScheduleForPlayerType(
    playerType: PlayerType,
    dhtmlx_json_data: string,
    slidesNotExpiredReferencedInSchedule?: SlideUploadPayload[],
    playlistsNotExpiredReferencedInSchedule?: Playlist[],
    draft: Schedule = null
  ): Observable<ContentUploadProgressUpdate> {
    let progressObservable = new Subject<ContentUploadProgressUpdate>();
    let units: Unit[] = []; //all the units to push content to

    new Promise<any>((resolve, reject) => {
      let scheduleToPut = new Schedule(playerType.type, playerType.object_id, dhtmlx_json_data, draft);
      scheduleToPut.modified = Date.now();

      let id;
      if (playerType.object_id) {
        id = playerType.object_id;
      } else if (playerType.object && playerType.object.id) {
        id = playerType.object.id;
      } else {
        return Promise.resolve(null);
      }

      resolve(this.fireDB.doc(this.getPathToSchedulesInWorkspace() + '/' + id).set(this.fireSessionService.serializeObject(scheduleToPut)));
    })
      .then(() => {
        return getIndividualUnitsFromObjectList([playerType]).then((individualUnits) => {
          units = individualUnits;
          let totalRequests = units.length; //since we're at least setting the schedule itself, without the assets

          if (!slidesNotExpiredReferencedInSchedule || !slidesNotExpiredReferencedInSchedule.length) {
            slidesNotExpiredReferencedInSchedule = [];
          }

          if (!playlistsNotExpiredReferencedInSchedule || !playlistsNotExpiredReferencedInSchedule.length) {
            playlistsNotExpiredReferencedInSchedule = [];
          }

          totalRequests += units.length * slidesNotExpiredReferencedInSchedule.length + units.length * playlistsNotExpiredReferencedInSchedule.length;
          progressObservable.next({ initialized: { totalRequests: totalRequests } });
          return units;
        });
      })
      .then((result) => {
        //slide assets
        return new Promise((resolve) => {
          let slides:Slide[] = [];
          for (let i = 0; i < slidesNotExpiredReferencedInSchedule.length; i++) {
            slides.push(slidesNotExpiredReferencedInSchedule[i].slide);
          }
          this.slidesService.publishSlidesToUnits(units, false, slides).subscribe(
            (progress) => {
              //when each upload has news (started, completed)
              progressObservable.next(progress);
            },
            (err) => {
              progressObservable.next(err);
            },
            () => {
              resolve(true);
            }
          );
        });
      })
      .then((result) => {
        //playlist assets
        return new Promise((resolve) => {
          this.playlistsService.publishPlaylistsToUnits(units, playlistsNotExpiredReferencedInSchedule, false).subscribe(
            (progress) => {
              //when each upload has news (started, completed)
              progressObservable.next(progress);
            },
            (err) => {
              progressObservable.next(err);
            },
            () => {
              resolve(true);
            }
          );
        });
      })
      .then((result) => {
        //set schedules
        return new Promise((resolve) => {
          this.publishScheduleToUnits(units, new Schedule(playerType.type, playerType.object_id, dhtmlx_json_data, draft)).subscribe(
            (progress) => {
              //when each upload has news (started, completed)
              progressObservable.next(progress);
            },
            (err) => {
              progressObservable.next(err);
            },
            () => {
              resolve(true);
            }
          );
        });
      })
      .then((result) => {
        progressObservable.complete(); //when all uploads are done
      });

    return progressObservable;
  }

  deleteScheduleForPlayerType(playerType: PlayerType): Promise<void> {
    let id;
    if (playerType.object_id) {
      id = playerType.object_id;
    } else if (playerType.object && playerType.object.id) {
      id = playerType.object.id;
    } else {
      return Promise.resolve(null);
    }

    return this.fireDB.doc(this.getPathToSchedulesInWorkspace() + '/' + id).delete();
  }

  setScheduler(scheduler): void {
    this.scheduler = scheduler;
  }

  getScheduler(): any {
    return this.scheduler;
  }

  setPlayerType(playerType): void {
    this.playerType = playerType;
  }

  getPlayerType(): any {
    return this.playerType;
  }

  canDeactivate() {
    return confirm('Do you want to leave');
  }


  cleanScheduler(scheduler): string {
    let json = this.scheduler.toJSON();
    json = JSON.parse(json);
    if (json.length) {
      for (let i = 0; i < json.length; i++) {
        if (json[i].play_content) {
          json[i].play_content = this.removeExtraSlideData(json[i].play_content);
        }
      }
    }
    json = JSON.stringify(json);
    return json;
  }

  removeExtraSlideData(slide: any) {
    //remove extra data that just makes the request larger
    if (typeof slide.renderedHTML !== 'undefined') {
      delete slide.renderedHTML;
    }
    if (typeof slide.html !== 'undefined') {
      delete slide.html;
    }
    if (typeof slide.css !== 'undefined') {
      delete slide.css;
    }
    if (typeof slide.thumbnail !== 'undefined') {
      delete slide.thumbnail;
    }
    if (typeof slide.media !== 'undefined') {
      delete slide.media;
    }
    if (typeof slide.assets !== 'undefined') {
      delete slide.assets;
    }
    if (typeof slide.styles !== 'undefined') {
      delete slide.styles;
    }
    if (typeof slide.media !== 'undefined') {
      delete slide.media;
    }
    if (typeof slide.highest_zIndex !== 'undefined') {
      delete slide.highest_zIndex;
    }
    if (typeof slide.components !== 'undefined') {
      delete slide.components;
    }
    return slide;
    //done cleaning up
  }

  private publishScheduleToUnits(units: Unit[], schedule: Schedule): Observable<ContentUploadProgressUpdate> {
    let progressObservable = new Subject<ContentUploadProgressUpdate>();

    if (!units || !units.length) {
      units = [];
    }

    let uploadTasks = [];
    for (let i = 0; i < units.length; i++) {
      uploadTasks.push({ unit: units[i], uploadMode: 'update', content: schedule });
    }

    if (!units.length) {
      setTimeout(() => {
        progressObservable.complete(); //when all uploads are done
      });
    } else {
      Observable.from(uploadTasks)
        .mergeMap((uploadTask) => this.api_789.executeUploadContentToUnit(uploadTask), 4) //TODO give user option to configure concurrency
        .subscribe(
          (progress) => {
            //when each upload has news (started, completed)
            progressObservable.next(progress);
          },
          (err) => {
            progressObservable.next(err);
          },
          () => {
            progressObservable.complete(); //when all uploads are done
          }
        );
    }

    return progressObservable;
  }
/*
  private executeUploadContentToUnit = (uploadTask: UploadTask) => {
    let uploadSubject = new Subject<ContentUploadProgressUpdate>();

    setTimeout(() => {
      let uploadedContent;
      if (uploadTask && uploadTask.content) {
        if ((<any>uploadTask.content).slide) {
          uploadedContent = (<any>uploadTask.content).slide;
        } else if (isPlaylist(uploadTask.content)) {
          uploadedContent = uploadTask.content;
        } else if (isSchedule(uploadTask.content)) {
          uploadedContent = uploadTask.content;
        }
      }

      uploadSubject.next({
        progress: {
          uploadTarget: {
            target_type: 'unit',
            target: uploadTask.unit,
          },
          status: 'started',
          content: uploadedContent,
        },
      });

      if (uploadTask.uploadMode !== 'skip') {
        let apiCallPromise;
        let arrDependencies = [];
        if ((<any>uploadTask.content).slide) {
          let slideData = <SlideUploadPayload>uploadTask.content;
          if (!slideData.onlyUploadSpecificMedia || !slideData.onlyUploadSpecificMedia.length) {
            slideData.onlyUploadSpecificMedia = 'all';
          }
          apiCallPromise = this.api_789[uploadTask.uploadMode.toLowerCase() + 'Slide'](uploadTask.unit, slideData);
        } else if (isPlaylist(uploadTask.content)) {
          let playlist = <Playlist>uploadTask.content;

          apiCallPromise = this.api_789[uploadTask.uploadMode.toLowerCase() + 'Playlist'](uploadTask.unit, playlist);
        } else if (isSchedule(uploadTask.content)) {
          let schedule = <Schedule>uploadTask.content;

          apiCallPromise = this.api_789.downloadSchedule(uploadTask.unit);
        }

        apiCallPromise
          .then((res: any) => {
            if (res && res.success) {
              uploadSubject.next({
                progress: {
                  uploadTarget: {
                    target_type: 'unit',
                    target: uploadTask.unit,
                  },
                  content: uploadedContent,
                  status: 'done',
                },
              });
              uploadSubject.complete();
            } else if (!res || res.error) {
              uploadSubject.next({
                progress: {
                  uploadTarget: {
                    target_type: 'unit',
                    target: uploadTask.unit,
                  },
                  status: 'failed',
                  content: uploadedContent,
                  message: res.error ? res.error : '',
                },
              });
              uploadSubject.complete();
            }
          })
          .catch((e) => {
            let errorMessage = 'Unable to reach unit';
            if (e) {
              if (e.error && e.error.error) {
                errorMessage = e.error.error.message || e.error.error.type;
              } else {
                let appError = getAppError(e.message || '');
                if (appError) {
                  errorMessage = appError.title + ' (' + appError.code + ' )';
                } else {
                  errorMessage = e.name || e.status;
                }
              }
            }
            uploadSubject.next({
              progress: {
                uploadTarget: {
                  target_type: 'unit',
                  target: uploadTask.unit,
                },
                status: 'failed',
                content: uploadedContent,
                message: errorMessage,
              },
            });
            uploadSubject.complete();
          });
      } else {
        uploadSubject.next({
          progress: {
            uploadTarget: {
              target_type: 'unit',
              target: uploadTask.unit,
            },
            status: 'done',
            content: uploadedContent,
            message: 'skipping',
          },
        });
        uploadSubject.complete();
      }
    });

    return uploadSubject;
  };*/

}

export interface UploadTask {
  unit: Unit;
  uploadMode: 'add' | 'update' | 'skip' | 'downloadFromCloud' | 'downloadFromCloudIfExists';
  content: SlideUploadPayload | Slide | Playlist | Schedule;
}
