import { Injectable } from '@angular/core';
import { cities } from '../../../assets/data/cities-array';
import { Unit } from '../../types/Unit';
import {API789Service} from "../api-789.service";

@Injectable({
  providedIn: 'root',
})
export class UnitTimezoneService {
  static cities: CityData[] = cities;

  constructor(private api789Service: API789Service) {}
  /**
   * Search cities array for specific timezone
   * @param $event
   * @param timezoneSearchTerms "America/Toronto" format
   * @return CityData
   */
  static searchTimezone($event, timezoneSearchTerms): CityData[] {
    if (timezoneSearchTerms.length < 2) {
      return;
    }

    let searchCity = timezoneSearchTerms.split(',')[0].toLowerCase().trim();
    let searchState = '';
    try {
      searchState = timezoneSearchTerms.split(',')[1].toLowerCase().trim();
    } catch (e) {}
    let searchCountry = '';
    try {
      searchCountry = timezoneSearchTerms.split(',')[2].toLowerCase().trim();
    } catch (e) {}

    return this.cities
      .filter((city) => {
        return (
          city.city.toLowerCase().indexOf(searchCity) >= 0 &&
          (!searchState || (searchState && city.province.toLowerCase().indexOf(searchState) >= 0)) &&
          (!searchCountry || (searchCountry && city.country.toLowerCase().indexOf(searchCountry) >= 0))
        );
      })
      .slice(0, 20);
  }

  /**
   * Gets Current Location Timezone, and assigns it
   * to all recently discovered units
   */
  setUnitsTimezone(unit: Unit): void {
    try {
      // get current timezone
      var timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      // use addmodalcomponent search to find timezone
      let searchTerms = timezone.split('/')[1];
      let searchResults = UnitTimezoneService.searchTimezone('', searchTerms);

      // if found, set timezone and update all units
      if (searchResults.length === 1) {
        unit.timezone = searchResults[0].timezone; // can be replaced by another timezone like "America/Toronto" for debugging
        this.api789Service.setTimezone(unit).catch((err) => {
          console.log('tiemzone err', err);
        });
      }
    } catch (e) {
      console.log('Error setting timezones ', e);
    }
  }
}

interface CityData {
  city: string;
  city_ascii: string;
  country: string;
  province: string;
  timezone: string;
}
