import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import {AngularFireAuthGuard, redirectUnauthorizedTo} from "@angular/fire/compat/auth-guard";
import {WorkspaceSelectedGuard} from "./guards/workspace-selected.guard";
import {UserHasPermissionGuard} from "./guards/user-has-permission.guard";
import {LeaveEditorGuard} from "./guards/leave-editor.guard";
import {LeaveObjectScheduleGuard} from "./guards/leave-object-schedule.guard";
import {WorkspaceBillingInGoodStandingGuard} from "./guards/workspace-billing-in-good-standing-guard.service";
import {IsUserMuxlabAdminGuard} from "./guards/is-user-muxlab-admin.guard";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['login']);

const routes: Routes = [
  {
    path: '',
    redirectTo: 'workspaces',
    pathMatch: 'full'
  },
  {
    path: 'workspaces',
    loadChildren: () => import('./pages/workspaces/workspaces.module').then( m => m.WorkspacesPageModule),
    canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'workspace-users',
    loadChildren: () => import('./pages/workspace-users/workspace-users.module').then( m => m.WorkspaceUsersPageModule),
    canActivate: [AngularFireAuthGuard, WorkspaceSelectedGuard, WorkspaceBillingInGoodStandingGuard], data: { authGuardPipe: redirectUnauthorizedToLogin }
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'register/:email',
    loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule)
  },
  {
    path: 'slides',
    loadChildren: () => import('./pages/slides/slides.module').then( m => m.SlidesPageModule),
    canActivate: [AngularFireAuthGuard, WorkspaceSelectedGuard, WorkspaceBillingInGoodStandingGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      routeRequiredPermissions: {
        OR: ['CREATE_SLIDES', 'EDIT_SLIDES', 'VIEW_SLIDES', 'DELETE_SLIDES'],
      }
    }
  },
  {
    path: 'units',
    loadChildren: () => import('./pages/units/units.module').then( m => m.UnitsPageModule),
    canActivate: [AngularFireAuthGuard, WorkspaceSelectedGuard, UserHasPermissionGuard, WorkspaceBillingInGoodStandingGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      routeRequiredPermissions: {
        OR: ['CREATE_UNITS', 'EDIT_UNITS', 'VIEW_UNITS', 'REBOOT_UNITS', 'RESET_UNITS', 'DELETE_UNITS'],
      }
    }
  },
  {
    path: 'editor/:id',
    loadChildren: () => import('./pages/editor/editor.module').then( m => m.EditorPageModule),
    canActivate: [AngularFireAuthGuard, WorkspaceSelectedGuard, UserHasPermissionGuard, WorkspaceBillingInGoodStandingGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      routeRequiredPermissions: {
        OR: ['CREATE_SLIDES', 'EDIT_SLIDES', 'VIEW_SLIDES'],
      }
    },
    canDeactivate: [LeaveEditorGuard],
  },
  {
    path: 'schedules',
    loadChildren: () => import('./pages/schedules/schedules.module').then( m => m.SchedulesPageModule),
    canActivate: [AngularFireAuthGuard, WorkspaceSelectedGuard, UserHasPermissionGuard, WorkspaceBillingInGoodStandingGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      routeRequiredPermissions: {
        OR: ['EDIT_SCHEDULES', 'VIEW_SCHEDULES'],
      },
    },
  },
  {
    path: 'object-schedule/:object_type/:object_id',
    loadChildren: () => import('./pages/schedules/object-schedule/object-schedule.module').then( m => m.ObjectSchedulePageModule),
    canActivate: [AngularFireAuthGuard, WorkspaceSelectedGuard, UserHasPermissionGuard, WorkspaceBillingInGoodStandingGuard],
    canDeactivate: [LeaveObjectScheduleGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      routeRequiredPermissions: {
        OR: ['EDIT_SCHEDULES', 'VIEW_SCHEDULES'],
      },
    },
  },
  {
    path: 'workspace-settings',
    loadChildren: () => import('./pages/workspace-settings/workspace-settings.module').then( m => m.WorkspaceSettingsPageModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('./pages/billing/billing.module').then( m => m.BillingPageModule),
    canActivate: [AngularFireAuthGuard, WorkspaceSelectedGuard, UserHasPermissionGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
      routeRequiredPermissions: {
        OR: ['ADD_UNITS', 'DELETE_UNITS'],
      },
    },
  },
  {
    path: 'password-reset',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'password-reset/:resetCode',
    loadChildren: () => import('./pages/password-reset/password-reset.module').then( m => m.PasswordResetPageModule)
  },
  {
    path: 'fbclb',
    loadChildren: () => import('./firebase-callback-handler/firebase-callback-handler.module').then( m => m.FirebaseCallbackHandlerPageModule)
  },
  {
    path: 'muxlab-admin',
    loadChildren: () => import('./pages/muxlab-admin/muxlab-admin.module').then( m => m.MuxlabAdminPageModule),
    canActivate: [AngularFireAuthGuard, IsUserMuxlabAdminGuard],
    data: {
      authGuardPipe: redirectUnauthorizedToLogin,
    },
  },





];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, useHash: true })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
