import {Injector, NgModule} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { initializeApp,provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideAuth,getAuth } from '@angular/fire/auth';
import { provideDatabase,getDatabase } from '@angular/fire/database';
import {AngularFireModule, FIREBASE_OPTIONS} from "@angular/fire/compat";
import {StaticInjector} from "./utils/StaticInjector";
import {getFirestore, provideFirestore} from "@angular/fire/firestore";
import {HttpClient, HttpClientModule} from '@angular/common/http';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {CustomPipesModule} from "./pipes/custom-pipes/custom-pipes.module";
import {ShowUnitGroupComponent} from "./services/units/show-unit-group/show-unit-group.component";
import {
  AddUnitGroupModalComponent
} from "./services/units/addUnitGroupModal/add-unit-group/add-unit-group-modal.component";
import {EditUnitModalComponent} from "./services/units/editUnitModal/edit-unit-modal/edit-unit-modal.component";
import {AddUnitModalComponent} from "./services/units/add-unit-modal/add-unit-modal.component";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {UnitPreviewComponent} from "./services/units/unit-preview/unit-preview.component";
import {DimensionsComponent} from "./common/editor/dimensions/dimensions.component";
import {ImportTrackComponent} from "./common/editor/import-track/import-track.component";
import {TemplatesComponent} from "./common/editor/templates/templates.component";
import {TutorialsComponent} from "./common/editor/tutorials/tutorials.component";
import {
  ContentUploadShowProgressModalComponent
} from "./services/units/content-upload-modal/content-upload-show-progress-modal.component";
import {getStorage, provideStorage} from "@angular/fire/storage";
import {SelectPlayerModalComponent} from "./services/units/select-player-modal/select-player-modal.component";
import {SlideDurationComponent} from "./common/slide-duration/slide-duration.component";
import {
  PlayableContentSelectorModalComponent
} from "./services/schedules/playable-content-selector-modal/playable-content-selector-modal/playable-content-selector-modal.component";
import {CustomDirectivesModule} from "./directives/custom-directives/custom-directives.module";
import {getAnalytics, provideAnalytics} from "@angular/fire/analytics";
import {getPerformance, providePerformance} from "@angular/fire/performance";
import {AngularFireAuthModule} from "@angular/fire/compat/auth";
import {AngularFirestoreModule} from "@angular/fire/compat/firestore";
import {AngularFireStorageModule} from "@angular/fire/compat/storage";
import {
  AngularFireAnalyticsModule,
  APP_NAME, APP_VERSION,
  ScreenTrackingService,
  UserTrackingService
} from "@angular/fire/compat/analytics";
import {AngularFirePerformanceModule} from "@angular/fire/compat/performance";
import { NgxPaginationModule } from 'ngx-pagination';


@NgModule({
  declarations: [
    AppComponent,
    ShowUnitGroupComponent,
    AddUnitGroupModalComponent,
    UnitPreviewComponent,
    EditUnitModalComponent,
    AddUnitModalComponent,
    ImportTrackComponent,
    ContentUploadShowProgressModalComponent,
    SelectPlayerModalComponent,
    SlideDurationComponent,
    PlayableContentSelectorModalComponent,
    TemplatesComponent,
    TutorialsComponent,
    DimensionsComponent
  ],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    CustomDirectivesModule,
    CustomPipesModule,
    HttpClientModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    AngularFirePerformanceModule,
    //provideFirebaseApp(() => initializeApp(environment.firebase)),
    //provideAuth(() => getAuth()),
    //provideFirestore(() => getFirestore()),
    //provideStorage(() => getStorage()),
    //provideAnalytics(() => getAnalytics()),
    //providePerformance(() => getPerformance()),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    NgxPaginationModule
  ],
  entryComponents: [
    ShowUnitGroupComponent,
    AddUnitGroupModalComponent,
    AddUnitModalComponent,
    PlayableContentSelectorModalComponent,
    UnitPreviewComponent,
    EditUnitModalComponent,
    ImportTrackComponent,
    TutorialsComponent,
    ContentUploadShowProgressModalComponent,
    SlideDurationComponent,
    SelectPlayerModalComponent,
    TemplatesComponent,
    DimensionsComponent
  ],
  exports: [
    TranslateModule
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: FIREBASE_OPTIONS, useValue: environment.firebase },
    {provide: APP_NAME, useValue: 'DIGISIGN-CLOUD'},
    {provide: APP_VERSION, useValue: environment.app_version},
    ScreenTrackingService,
    UserTrackingService,
    TranslateService
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(injector: Injector) {
    StaticInjector.setInjector(injector);
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
