import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondsToHoursMinutesSeconds',
})
export class SecondsToHoursMinutesSecondsPipe implements PipeTransform {
  transform(value: any, ...args: any[]): any {
    var sec_num = parseInt(value, 10);
    var hours = Math.floor(sec_num / 3600);
    var minutes = Math.floor(sec_num / 60) % 60;
    var seconds = sec_num % 60;

    return (
      [hours, minutes, seconds]
        .map((v) => (v < 10 ? '0' + v : v))
        // .filter((v,i) => v !== "00" || i > 0)
        .join(':')
    );
    return null;
  }
}
