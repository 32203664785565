import { Slide } from './Slide';
import { PlayableContentInterface } from './PlayableContentInterface.i';

import { Type} from '@angular/core';
import {StaticInjector} from "../utils/StaticInjector";
import {AngularFirestore} from "@angular/fire/compat/firestore";

export class Playlist implements PlayableContentInterface {
  id: string;
  name: string;
  slide_ids: string[] = [];
  private slides: Slide[];
  modified: number;

  constructor(name: string, slide_ids: string[], id?: null | string, modified?: number) {
    //generate unique ID
    if (!id) {
      id = StaticInjector.getInjector().get<AngularFirestore>(AngularFirestore as Type<AngularFirestore>).createId();
    }

    this.id = id;

    this.name = name;

    if (!slide_ids || !slide_ids.length) {
      slide_ids = [];
    }

    this.slide_ids = slide_ids;
    this.modified = modified;
  }

  getName(): string {
    return this.name;
  }
  setName(name: string): void {
    this.name = name;
  }

  /**
   * ATTENTION the slide objects are to be supplied to this class via setSlides. This is should be done by the API lib when instantiating this object
   */
  getSlides(): Slide[] | null {
    if (this.slides && this.slides.length) {
      return this.slides;
    } else {
      return [];
    }
  }
  setSlides(slides: Slide[]): void {
    this.slides = slides;
  }

  getId(): string {
    return this.id;
  }

  getDurationInSeconds(): number {
    let totalDurationInSeconds = 0;
    let slides = this.getSlides();
    for (let i = 0; i < slides.length; i++) {
      totalDurationInSeconds += slides[i].getDurationInSeconds();
    }

    return totalDurationInSeconds;
  }
}

export function isPlaylist(obj: any): obj is Playlist {
  return !!obj && (obj as Playlist).slide_ids !== undefined && (obj as Playlist).id !== undefined && (obj as Playlist).name !== undefined;
}
