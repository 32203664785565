import { getTemplateAssetDirectory, Template } from '../../app/types/Template';

export const BrunchTemplate: Template = {
  isDefaultTemplate: true,
  isInteractiveModeOn: true,
  defaultTemplateKeywords: ['restaurant', 'interactive', 'interactive mode'],
  id: 'interactive-brunch-1',
  renderedHTML: undefined,
  name: 'Brunch template',
  durationInSeconds: 30,
  highest_zIndex: 26,
  assets: [],
  styles: [
    { selectors: [{ name: 'wrapper', label: 'wrapper', type: 2, active: true, private: 1, protected: false }], wrapper: 1 },
    {
      selectors: [{ name: 'iuoc', label: 'iuoc', type: 2, active: true, private: 1, protected: false }],
      style: { left: 0, top: 0, position: 'absolute', 'z-index': 0, height: '100%', width: '100%' },
    },
    {
      selectors: [{ name: 'interactive-area', label: 'interactive-area', type: 1, active: true, private: 1, protected: false }],
      style: { width: '400px', height: '400px', cursor: 'pointer', display: 'flex', 'align-items': 'center' },
    },
    {
      selectors: [
        { name: 'interactive-area', label: 'interactive-area', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      style: {
        'border-top-width': '5px',
        'border-right-width': '5px',
        'border-bottom-width': '5px',
        'border-left-width': '5px',
        'border-top-style': 'dashed',
        'border-right-style': 'dashed',
        'border-bottom-style': 'dashed',
        'border-left-style': 'dashed',
        'border-top-color': 'rgb(131, 241, 99)',
        'border-right-color': 'rgb(131, 241, 99)',
        'border-bottom-color': 'rgb(131, 241, 99)',
        'border-left-color': 'rgb(131, 241, 99)',
        'border-image-source': 'initial',
        'border-image-slice': 'initial',
        'border-image-width': 'initial',
        'border-image-outset': 'initial',
        'border-image-repeat': 'initial',
        'background-image': 'url("assets/imgs/touchIcon.png")',
        'background-position-x': 'center',
        'background-position-y': 'center',
        'background-repeat-x': 'no-repeat',
        'background-repeat-y': 'no-repeat',
        'background-attachment': 'initial',
        'background-origin': 'initial',
        'background-clip': 'initial',
        'background-color': 'rgba(131, 241, 99, 0.2)',
        'background-size': '50%',
      },
    },
    { selectors: [], selectorsAdd: '.interactive-area.editor .interactive-area-widget-container', style: { display: 'block' } },
    {
      selectors: [],
      selectorsAdd: '.interactive-area .interactive-area-widget-container',
      style: { width: '100%', height: '100%', 'max-height': '50px', 'overflow-x': 'hidden', 'overflow-y': 'hidden', 'font-size': '40px', 'text-align': 'center', display: 'none' },
    },
    { selectors: [], selectorsAdd: '.interactive-area *', style: { 'pointer-events': 'none' } },
    {
      selectors: [{ name: 'i7zg', label: 'i7zg', type: 2, active: true, private: 1, protected: false }],
      style: { left: '85px', top: '285px', position: 'absolute', 'z-index': 8, en: '', width: '534.0740740740741px', height: '676.0740740740741px' },
    },
    {
      selectors: [{ name: 'iecj', label: 'iecj', type: 2, active: true, private: 1, protected: false }],
      style: { left: '692.96875px', top: '285px', position: 'absolute', 'z-index': 9, en: '', width: '534.0740740740741px', height: '676.0740740740741px' },
    },
    {
      selectors: [{ name: 'iviu', label: 'iviu', type: 2, active: true, private: 1, protected: false }],
      style: { left: '1301px', top: '285px', position: 'absolute', 'z-index': 10, en: '', width: '534.0740740740741px', height: '676.0740740740741px' },
    },
    {
      selectors: [{ name: 'popup', label: 'popup', type: 1, active: true, private: 1, protected: false }],
      style: {
        width: '100%',
        height: '100%',
        'background-color': 'rgba(0, 0, 0, 0.5)',
        top: '0px',
        left: '0px',
        'background-repeat-x': 'no-repeat',
        'background-repeat-y': 'no-repeat',
        'background-size': 'cover',
        position: 'absolute !important',
      },
    },
    {
      selectors: [{ name: 'popup-active', label: 'popup-active', type: 1, active: true, private: 1, protected: false }],
      style: {
        'border-top-width': '20px',
        'border-right-width': '20px',
        'border-bottom-width': '20px',
        'border-left-width': '20px',
        'border-top-style': 'solid',
        'border-right-style': 'solid',
        'border-bottom-style': 'solid',
        'border-left-style': 'solid',
        'border-top-color': 'rgb(255, 211, 26)',
        'border-right-color': 'rgb(255, 211, 26)',
        'border-bottom-color': 'rgb(255, 211, 26)',
        'border-left-color': 'rgb(255, 211, 26)',
        'border-image-source': 'initial',
        'border-image-slice': 'initial',
        'border-image-width': 'initial',
        'border-image-outset': 'initial',
        'border-image-repeat': 'initial',
      },
    },
    {
      selectors: [{ name: 'yogurt', label: 'yogurt', type: 2, active: true, private: 1, protected: false }],
      style: {
        'z-index': 11,
        display: 'none',
        'background-image': "url('" + getTemplateAssetDirectory() + "/Brunch/BackgroundPopup.jpg')",
        'background-size': 'auto',
        'background-position': 'right top',
        position: 'relative',
      },
      src: getTemplateAssetDirectory() + 'Brunch/BackgroundPopup.jpg',
    },
    {
      selectors: [{ name: 'ilkwd', label: 'ilkwd', type: 2, active: true, private: 1, protected: false }],
      style: { left: '0', top: '0px', position: 'absolute', 'z-index': 14, en: '', display: 'none' },
    },
    {
      selectors: [{ name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false }],
      style: { 'font-size': '45px', 'padding-top': '35px', 'padding-right': '35px', 'padding-bottom': '35px', 'padding-left': '35px' },
    },
    {
      selectors: [{ name: 'ij10b', label: 'ij10b', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '1098.2962962962963px',
        top: '247.4814814814815px',
        position: 'absolute',
        'z-index': 15,
        width: '600.405592592593px',
        height: '201.2592592592593px',
        'text-align': 'left',
        'font-size': '80px',
        en: '',
        'font-family': 'Trebuchet MS, Helvetica, sans-serif',
        display: 'none',
      },
    },
    {
      selectors: [{ name: 'i9lfd', label: 'i9lfd', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '1098.2858796296298px',
        top: '486.7407407407409px',
        position: 'absolute',
        'z-index': 16,
        en: '',
        'font-family': 'arconregular',
        'font-size': '60px',
        display: 'none',
        'text-align': 'left',
      },
    },
    {
      selectors: [{ name: 'i537i', label: 'i537i', type: 2, active: true, private: 1, protected: false }],
      style: { left: '1130.0121527777778px', top: '739.7407407407408px', position: 'absolute', 'z-index': 17, en: '', display: 'none' },
    },
    { selectors: [{ name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false }], style: { width: '400px', height: '400px', 'background-color': 'rgb(10, 44, 52)' } },
    { selectors: [], selectorsAdd: '.box *', style: { 'pointer-events': 'none' } },
    {
      selectors: [
        { name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false },
        { name: 'fadeTop', label: 'fadeTop', type: 1, active: true, private: 1, protected: false },
      ],
      style: { '-webkit-mask-image': '-webkit-gradient(linear, 0% 100%, 0% 0%, from(rgb(0, 0, 0)), color-stop(0.75, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))' },
    },
    {
      selectors: [
        { name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false },
        { name: 'fadeLeft', label: 'fadeLeft', type: 1, active: true, private: 1, protected: false },
      ],
      style: { '-webkit-mask-image': '-webkit-gradient(linear, 100% 0%, 0% 0%, from(rgb(0, 0, 0)), color-stop(0.75, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))' },
    },
    {
      selectors: [
        { name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false },
        { name: 'fadeBottom', label: 'fadeBottom', type: 1, active: true, private: 1, protected: false },
      ],
      style: { '-webkit-mask-image': '-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(0, 0, 0)), color-stop(0.75, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))' },
    },
    {
      selectors: [
        { name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false },
        { name: 'fadeRight', label: 'fadeRight', type: 1, active: true, private: 1, protected: false },
      ],
      style: { '-webkit-mask-image': '-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgb(0, 0, 0)), color-stop(0.75, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))' },
    },
    { selectors: [], selectorsAdd: '.box .box-widget-container', style: { width: '100%', height: '100%' } },
    {
      selectors: [{ name: 'muffin', label: 'muffin', type: 2, active: true, private: 1, protected: false }],
      style: {
        'z-index': 12,
        display: 'none',
        'background-image': "url('" + getTemplateAssetDirectory() + "/Brunch/BackgroundPopup.jpg')",
        'background-size': 'auto',
        'background-position': 'right top',
      },
    },
    {
      selectors: [{ name: 'istxi', label: 'istxi', type: 2, active: true, private: 1, protected: false }],
      style: { left: '0', top: '0px', position: 'absolute', 'z-index': 18, en: '', display: 'none' },
    },
    {
      selectors: [{ name: 'ipn7g', label: 'ipn7g', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '1098.2962962962963px',
        top: '247.4814814814815px',
        position: 'absolute',
        'z-index': 19,
        width: '633.6648518518524px',
        height: '226.3703703703705px',
        'text-align': 'left',
        'font-size': '80px',
        en: '',
        'font-family': 'Trebuchet MS, Helvetica, sans-serif',
        display: 'none',
      },
    },
    {
      selectors: [{ name: 'ivk0g', label: 'ivk0g', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '1098.2858796296298px',
        top: '486.7407407407409px',
        position: 'absolute',
        'z-index': 20,
        en: '',
        'font-family': 'arconregular',
        'font-size': '60px',
        display: 'none',
        'text-align': 'left',
      },
    },
    {
      selectors: [{ name: 'isnrf', label: 'isnrf', type: 2, active: true, private: 1, protected: false }],
      style: { left: '1130.0121527777778px', top: '739.7407407407408px', position: 'absolute', 'z-index': 21, en: '', display: 'none' },
    },
    {
      selectors: [{ name: 'cereal', label: 'cereal', type: 2, active: true, private: 1, protected: false }],
      style: {
        'z-index': 13,
        display: 'none',
        'background-image': "url('" + getTemplateAssetDirectory() + "/Brunch/BackgroundPopup.jpg')",
        'background-size': 'auto',
        'background-position': 'right top',
        position: 'relative',
      },
    },
    {
      selectors: [{ name: 'irdmi', label: 'irdmi', type: 2, active: true, private: 1, protected: false }],
      style: { left: '0', top: '0px', position: 'absolute', 'z-index': 22, en: '', display: 'none' },
    },
    {
      selectors: [{ name: 'i8171', label: 'i8171', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '1098.2962962962963px',
        top: '247.4814814814815px',
        position: 'absolute',
        'z-index': 23,
        width: '633.6648518518524px',
        height: '226.3703703703705px',
        'text-align': 'left',
        'font-size': '80px',
        en: '',
        'font-family': 'Trebuchet MS, Helvetica, sans-serif',
        display: 'none',
      },
    },
    {
      selectors: [{ name: 'ikhip', label: 'ikhip', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '1098.2858796296298px',
        top: '486.7407407407409px',
        position: 'absolute',
        'z-index': 24,
        en: '',
        'font-family': 'arconregular',
        'font-size': '60px',
        display: 'none',
        'text-align': 'left',
      },
    },
    {
      selectors: [{ name: 'iv3fu', label: 'iv3fu', type: 2, active: true, private: 1, protected: false }],
      style: { left: '1130.0121527777778px', top: '739.7407407407408px', position: 'absolute', 'z-index': 25, en: '', display: 'none' },
    },
    {
      selectors: [{ name: 'i3zqz', label: 'i3zqz', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '51.84027777777794px',
        top: '24.190393518518547px',
        position: 'absolute',
        'z-index': 1,
        en: '',
        'font-size': '70px',
        'font-family': 'Trebuchet MS, Helvetica, sans-serif',
        'text-align': 'left',
      },
    },
    {
      selectors: [{ name: 'i2rls', label: 'i2rls', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '76.85821759259284px',
        top: '816.7060185185188px',
        position: 'absolute',
        'z-index': 7,
        en: '',
        'font-size': '30px',
        'font-family': 'Trebuchet MS, Helvetica, sans-serif',
        'text-align': 'left',
        'Move:': '1',
      },
    },
    {
      selectors: [{ name: 'is45l', label: 'is45l', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '687.8211805555559px',
        top: '816.7089120370374px',
        position: 'absolute',
        'z-index': 5,
        en: '',
        'font-size': '30px',
        'font-family': 'Trebuchet MS, Helvetica, sans-serif',
        'text-align': 'left',
      },
    },
    {
      selectors: [{ name: 'icnxp', label: 'icnxp', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '1295.7037037037042px',
        top: '816.7054398148153px',
        position: 'absolute',
        'z-index': 2,
        en: '',
        'font-size': '30px',
        'font-family': 'Trebuchet MS, Helvetica, sans-serif',
        'text-align': 'left',
      },
    },
    {
      selectors: [{ name: 'iheui', label: 'iheui', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '76.8449074074074px',
        top: '860.596064814815px',
        position: 'absolute',
        'z-index': 6,
        'font-size': '30px',
        'text-align': 'left',
        en: '',
        'font-family': 'arconregular',
        'Move:': '1',
      },
    },
    {
      selectors: [{ name: 'ixuwz', label: 'ixuwz', type: 2, active: true, private: 1, protected: false }],
      style: { left: '687.8234953703703px', top: '862.8923611111113px', position: 'absolute', 'z-index': 4, 'font-size': '30px', 'text-align': 'left', en: '', 'font-family': 'arconregular' },
    },
    {
      selectors: [{ name: 'iaa6n', label: 'iaa6n', type: 2, active: true, private: 1, protected: false }],
      style: { left: '1301.0127314814813px', top: '860.5966435185188px', position: 'absolute', 'z-index': 3, 'font-size': '30px', 'text-align': 'left', en: '', 'font-family': 'arconregular' },
    },
    {
      selectors: [{ name: 'iw0te', label: 'iw0te', type: 2, active: true, private: 1, protected: false }],
      style: { left: '1633.3845486111113px', top: '55.71151620370371px', position: 'absolute', 'z-index': 26, width: '250px', en: '' },
    },
  ],
  components: [
    { content: '', attributes: { 'data-gjs-drag-mode': false }, dmode: 0 },
    {
      type: 'image',
      void: true,
      status: 'hovered',
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'iuoc', src: getTemplateAssetDirectory() + 'Brunch/Background.png', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Brunch/Background.png',
      activeOnRender: 0,
      isInteractiveModeOn: 'true',
      scriptUpdated: 1,
    },
    {
      type: 'interactive-area',
      content: '',
      classes: [
        { name: 'interactive-area', label: 'interactive-area', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { 'data-gjs-drag-mode': false, id: 'i7zg' },
      dmode: 0,
      components: [
        {
          type: 'default',
          content: '',
          classes: [{ name: 'interactive-area-widget-container', label: 'interactive-area-widget-container', type: 1, active: true, private: 1, protected: false }],
          attributes: { 'data-gjs-drag-mode': false },
          dmode: 0,
          isInteractiveModeOn: 'true',
        },
      ],
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      actionsIndexes: [0],
      event0: 'click',
      action0: 'show',
      transition0: 'top',
      script0: null,
      actions: [{ event: 'click', action: 'show', transition: 'top', targets: ['yogurt'] }],
      targets0: ['yogurt'],
      actionsString: 'event,click,action,show,transition,top,targets,yogurt,script,,timer,,loop,',
    },
    {
      type: 'interactive-area',
      content: '',
      classes: [
        { name: 'interactive-area', label: 'interactive-area', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { 'data-gjs-drag-mode': false, id: 'iecj' },
      dmode: 0,
      components: [
        {
          type: 'default',
          content: '',
          classes: [{ name: 'interactive-area-widget-container', label: 'interactive-area-widget-container', type: 1, active: true, private: 1, protected: false }],
          attributes: { 'data-gjs-drag-mode': false },
          dmode: 0,
          isInteractiveModeOn: 'true',
          view: '',
        },
      ],
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      view: '',
      actionsIndexes: [0],
      event0: 'click',
      action0: 'show',
      transition0: 'top',
      script0: null,
      actions: [{ event: 'click', action: 'show', transition: 'top', targets: ['muffin'] }],
      targets0: ['muffin'],
      actionsString: 'event,click,action,show,transition,top,targets,muffin,script,,timer,,loop,',
    },
    {
      type: 'interactive-area',
      content: '',
      classes: [
        { name: 'interactive-area', label: 'interactive-area', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { 'data-gjs-drag-mode': false, id: 'iviu' },
      dmode: 0,
      components: [
        {
          type: 'default',
          content: '',
          classes: [{ name: 'interactive-area-widget-container', label: 'interactive-area-widget-container', type: 1, active: true, private: 1, protected: false }],
          attributes: { 'data-gjs-drag-mode': false },
          dmode: 0,
          isInteractiveModeOn: 'true',
          view: '',
        },
      ],
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      view: '',
      actionsIndexes: [0],
      event0: 'click',
      action0: 'show',
      transition0: 'top',
      script0: null,
      actions: [{ event: 'click', action: 'show', transition: 'top', targets: ['cereal'] }],
      targets0: ['cereal'],
      actionsString: 'event,click,action,show,transition,top,targets,cereal,script,,timer,,loop,',
    },
    {
      type: 'popup',
      content: '',
      classes: [
        { name: 'popup', label: 'popup', type: 1, active: true, private: 1, protected: false },
        { name: 'interactive-transition-hide', label: 'interactive-transition-hide', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { id: 'yogurt', name: 'Yogurt', 'data-gjs-drag-mode': false },
      dmode: 0,
      isInteractiveModeOn: 'true',
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'ilkwd', src: getTemplateAssetDirectory() + 'Brunch/yogurt.jpg', 'data-gjs-drag-mode': false, popupParent: 'yogurt' },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Brunch/yogurt.jpg',
      isInteractiveModeOn: 'true',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { src: getTemplateAssetDirectory() + 'Brunch/muffin.jpg', 'data-gjs-drag-mode': false, popupParent: 'muffin', id: 'istxi' },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Brunch/muffin.jpg',
      isInteractiveModeOn: 'true',
      activeOnRender: 0,
      scriptUpdated: 1,
      view: '',
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
        { name: 'interactive-mode-highlight', label: 'interactive-mode-highlight', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { src: getTemplateAssetDirectory() + 'Brunch/cereal.jpg', 'data-gjs-drag-mode': false, popupParent: 'cereal', id: 'irdmi' },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Brunch/cereal.jpg',
      isInteractiveModeOn: 'true',
      activeOnRender: 0,
      scriptUpdated: 1,
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>Yogurt with cranberries</b></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'ij10b', popupParent: 'yogurt' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>Portuguese cheese muffin</b></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, popupParent: 'muffin', id: 'ipn7g' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>Cereal and fruits</b></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, popupParent: 'cereal', id: 'i8171' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">$ 16.50</div></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'i9lfd', popupParent: 'yogurt' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">$ 10.50</div></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, popupParent: 'muffin', id: 'ivk0g' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">$ 12.50</div></div></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, popupParent: 'cereal', id: 'ikhip' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      view: '',
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'i537i', src: getTemplateAssetDirectory() + 'Brunch/Button.jpg', 'data-gjs-drag-mode': false, popupParent: 'yogurt' },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Brunch/Button.jpg',
      isInteractiveModeOn: 'true',
      activeOnRender: 0,
      scriptUpdated: 1,
      actionsIndexes: [0],
      event0: 'click',
      action0: 'hide',
      transition0: 'bottom',
      script0: null,
      actions: [{ event: 'click', action: 'hide', transition: 'bottom', targets: ['yogurt'] }],
      targets0: ['yogurt'],
      actionsString: 'event,click,action,hide,transition,bottom,targets,yogurt,script,,timer,,loop,',
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { src: getTemplateAssetDirectory() + 'Brunch/Button.jpg', 'data-gjs-drag-mode': false, popupParent: 'muffin', id: 'isnrf' },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Brunch/Button.jpg',
      isInteractiveModeOn: 'true',
      activeOnRender: 0,
      scriptUpdated: 1,
      view: '',
      actionsIndexes: [0],
      event0: 'click',
      action0: 'hide',
      transition0: 'bottom',
      script0: null,
      actions: [{ event: 'click', action: 'hide', transition: 'bottom', targets: ['muffin'] }],
      targets0: ['muffin'],
      actionsString: 'event,click,action,hide,transition,bottom,targets,muffin,script,,timer,,loop,',
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { src: getTemplateAssetDirectory() + 'Brunch/Button.jpg', 'data-gjs-drag-mode': false, popupParent: 'cereal', id: 'iv3fu' },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Brunch/Button.jpg',
      isInteractiveModeOn: 'true',
      activeOnRender: 0,
      scriptUpdated: 1,
      view: '',
      actionsIndexes: [0],
      event0: 'click',
      action0: 'hide',
      transition0: 'bottom',
      script0: null,
      actions: [{ event: 'click', action: 'hide', transition: 'bottom', targets: ['cereal'] }],
      targets0: ['cereal'],
      actionsString: 'event,click,action,hide,transition,bottom,targets,cereal,script,,timer,,loop,',
    },
    {
      type: 'popup',
      content: '',
      classes: [
        { name: 'popup', label: 'popup', type: 1, active: true, private: 1, protected: false },
        { name: 'interactive-transition-hide', label: 'interactive-transition-hide', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { id: 'muffin', name: 'Muffins', 'data-gjs-drag-mode': false },
      dmode: 0,
      isInteractiveModeOn: 'true',
      scriptUpdated: 1,
    },
    {
      type: 'popup',
      content: '',
      classes: [
        { name: 'popup', label: 'popup', type: 1, active: true, private: 1, protected: false },
        { name: 'interactive-transition-hide', label: 'interactive-transition-hide', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { id: 'cereal', name: 'Cereal', 'data-gjs-drag-mode': false },
      dmode: 0,
      isInteractiveModeOn: 'true',
      scriptUpdated: 1,
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>Brunch menu.</b></div><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>Chose your options</b></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'i3zqz' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>Yogurt with cranberries</b></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'i2rls' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>Portuguese cheese muffins</b></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'is45l' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>Cereal and fruits</b></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'icnxp' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">$ 16.50</div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'iheui' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">$ 10.50</div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'ixuwz' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">$ 12.50</div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'iaa6n' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      isInteractiveModeOn: 'true',
      view: '',
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'iw0te', src: getTemplateAssetDirectory() + 'Brunch/Logo.png', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Brunch/Logo.png',
      isInteractiveModeOn: 'true',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
  ],
  html: '',
  css:
    "* { box-sizing: border-box; } body {margin: 0;}#iuoc{left:0;top:0;position:absolute;z-index:0;height:100%;width:100%;}.interactive-area{width:400px;height:400px;cursor:pointer;display:flex;align-items:center;}.interactive-area.editor{border-top-width:5px;border-right-width:5px;border-bottom-width:5px;border-left-width:5px;border-top-style:dashed;border-right-style:dashed;border-bottom-style:dashed;border-left-style:dashed;border-top-color:rgb(131, 241, 99);border-right-color:rgb(131, 241, 99);border-bottom-color:rgb(131, 241, 99);border-left-color:rgb(131, 241, 99);border-image-source:initial;border-image-slice:initial;border-image-width:initial;border-image-outset:initial;border-image-repeat:initial;background-image:url('assets/imgs/touchIcon.png');background-position-x:center;background-position-y:center;background-repeat-x:no-repeat;background-repeat-y:no-repeat;background-attachment:initial;background-origin:initial;background-clip:initial;background-color:rgba(131, 241, 99, 0.2);background-size:50%;}.interactive-area.editor .interactive-area-widget-container{display:block;}.interactive-area .interactive-area-widget-container{width:100%;height:100%;max-height:50px;overflow-x:hidden;overflow-y:hidden;font-size:40px;text-align:center;display:none;}.interactive-area *{pointer-events:none;}#i7zg{left:85px;top:285px;position:absolute;z-index:8;width:534.0740740740741px;height:676.0740740740741px;}#iecj{left:692.96875px;top:285px;position:absolute;z-index:9;width:534.0740740740741px;height:676.0740740740741px;}#iviu{left:1301px;top:285px;position:absolute;z-index:10;width:534.0740740740741px;height:676.0740740740741px;}.popup{width:100%;height:100%;background-color:rgba(0, 0, 0, 0.5);top:0px;left:0px;background-repeat-x:no-repeat;background-repeat-y:no-repeat;background-size:cover;position:absolute !important;}#yogurt{z-index:11;display:none;background-image:url('" +
    getTemplateAssetDirectory() +
    "Brunch/BackgroundPopup.jpg');background-size:auto;background-position:right top;position:relative;}#ilkwd{left:0;top:0px;position:absolute;z-index:14;display:none;}.texts{font-size:45px;padding-top:35px;padding-right:35px;padding-bottom:35px;padding-left:35px;}#ij10b{left:1098.2962962962963px;top:247.4814814814815px;position:absolute;z-index:15;width:600.405592592593px;height:201.2592592592593px;text-align:left;font-size:80px;font-family:Trebuchet MS, Helvetica, sans-serif;display:none;}#i9lfd{left:1098.2858796296298px;top:486.7407407407409px;position:absolute;z-index:16;font-family:arconregular;font-size:60px;display:none;text-align:left;}#i537i{left:1130.0121527777778px;top:739.7407407407408px;position:absolute;z-index:17;display:none;}.box *{pointer-events:none;}.box .box-widget-container{width:100%;height:100%;}#muffin{z-index:12;display:none;background-image:url('" +
    getTemplateAssetDirectory() +
    "Brunch/BackgroundPopup.jpg');background-size:auto;background-position:right top;}#istxi{left:0;top:0px;position:absolute;z-index:18;display:none;}#ipn7g{left:1098.2962962962963px;top:247.4814814814815px;position:absolute;z-index:19;width:633.6648518518524px;height:226.3703703703705px;text-align:left;font-size:80px;font-family:Trebuchet MS, Helvetica, sans-serif;display:none;}#ivk0g{left:1098.2858796296298px;top:486.7407407407409px;position:absolute;z-index:20;font-family:arconregular;font-size:60px;display:none;text-align:left;}#isnrf{left:1130.0121527777778px;top:739.7407407407408px;position:absolute;z-index:21;display:none;}#cereal{z-index:13;display:none;background-image:url('" +
    getTemplateAssetDirectory() +
    "Brunch/BackgroundPopup.jpg');background-size:auto;background-position:right top;position:relative;}#irdmi{left:0;top:0px;position:absolute;z-index:22;display:none;}#i8171{left:1098.2962962962963px;top:247.4814814814815px;position:absolute;z-index:23;width:633.6648518518524px;height:226.3703703703705px;text-align:left;font-size:80px;font-family:Trebuchet MS, Helvetica, sans-serif;display:none;}#ikhip{left:1098.2858796296298px;top:486.7407407407409px;position:absolute;z-index:24;font-family:arconregular;font-size:60px;display:none;text-align:left;}#iv3fu{left:1130.0121527777778px;top:739.7407407407408px;position:absolute;z-index:25;display:none;}#i3zqz{left:51.84027777777794px;top:24.190393518518547px;position:absolute;z-index:1;font-size:70px;font-family:Trebuchet MS, Helvetica, sans-serif;text-align:left;}#i2rls{left:76.85821759259284px;top:816.7060185185188px;position:absolute;z-index:7;font-size:30px;font-family:Trebuchet MS, Helvetica, sans-serif;text-align:left;Move::1;}#is45l{left:687.8211805555559px;top:816.7089120370374px;position:absolute;z-index:5;font-size:30px;font-family:Trebuchet MS, Helvetica, sans-serif;text-align:left;}#icnxp{left:1295.7037037037042px;top:816.7054398148153px;position:absolute;z-index:2;font-size:30px;font-family:Trebuchet MS, Helvetica, sans-serif;text-align:left;}#iheui{left:76.8449074074074px;top:860.596064814815px;position:absolute;z-index:6;font-size:30px;text-align:left;font-family:arconregular;Move::1;}#ixuwz{left:687.8234953703703px;top:862.8923611111113px;position:absolute;z-index:4;font-size:30px;text-align:left;font-family:arconregular;}#iaa6n{left:1301.0127314814813px;top:860.5966435185188px;position:absolute;z-index:3;font-size:30px;text-align:left;font-family:arconregular;}#iw0te{left:1633.3845486111113px;top:55.71151620370371px;position:absolute;z-index:26;width:250px;}",
  thumbnail: getTemplateAssetDirectory() + 'Brunch/thumbnail.png',
};
