import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {DebugLogService} from "./debug-log.service";
import {TranslateService} from "@ngx-translate/core";
import {DeviceCommunicationService, isUnitQueueCommand, UnitQueueCommand} from "./device-communication.service";
import {Unit, UNIT_ETHERNET_INTERFACENAME} from "../types/Unit";
import {isSlide, Slide} from "../types/Slide";
import {isSchedule, Schedule} from "../types/Schedule";
import {isPlaylist, Playlist} from "../types/Playlist";
import {KeywordDictionaryEntry} from "../types/KeywordDictionary";
import {Tag} from "../types/Tag";
import {Customer} from "../types/Customer";
import {AppErrors, getAppError} from "../types/AppError";
import {
  ContentUploadProgressUpdate,
  PlayerType,
  SlideUploadPayload
} from "./units/content-upload-show-progress-modal.service";
import {UploadTask} from "./schedules.service";
import {Subject} from "rxjs";
import {UnitGroup} from "../types/UnitGroup";
import {UpdateDetailsPayload} from "./update.service";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FireSessionService} from "./fire-session.service";

@Injectable({
  providedIn: 'root',
})
export class API789Service {
  protected static UNIT_API_PATH = '/api';
  protected static UNIT_API_PORT = '3000';

  constructor(public httpClient: HttpClient,
              private debugLogService: DebugLogService,
              private fireSessionService: FireSessionService,
              private fireDB: AngularFirestore,
              private deviceCommunicationService: DeviceCommunicationService,
              private translateService: TranslateService) {}

  unpairUnit(unit: Unit) {
    return this.deviceCommunicationService.sendCommand(unit, 'unpair', 'immediate', {}, 10000);
  }

  getPreviewApiUrl(unit: Unit) {
    return 'https://' + unit.ip + ':' + API789Service.UNIT_API_PORT + API789Service.UNIT_API_PATH + '/preview';
  }

  setPreviewEnabled(unit: Unit, enabled: boolean) {
    return this.deviceCommunicationService.sendCommand(unit, 'ffmpeg/preview/enable', 'immediate', { enablePreview: enabled });
  }

  resetPreviewTimout(unit: Unit) {
    return this.deviceCommunicationService.sendCommand(unit, 'ffmpeg/preview/keepalive', 'immediate', {});
  }

  doFactoryReset(unit: Unit) {
    return this.deviceCommunicationService.sendCommand(unit, 'device/factory-reset', 'immediate', {});
  }

  publishScreenshot(unit: Unit) {
    return this.deviceCommunicationService.sendCommand(unit, 'device/publishScreenshot', 'immediate', {});
  }

  setTimezone(unit: Unit) {
    return this.deviceCommunicationService.sendCommand(unit, 'device/timezone/set', 'immediate', {
      timezone: unit.timezone,
      dstoff: false,
    });
  }

  setCurrentTime(unit: Unit, datetime: string) {
    return this.deviceCommunicationService.sendCommand(unit, 'device/datetime/set', 'immediate', {
      datetime: datetime,
    });
  }

  setDefaultScreen(unit: Unit, defaultScreen: Slide) {
    return this.deviceCommunicationService.sendCommand(unit, 'auth/default-screen', 'immediate', {
      slideID: defaultScreen.id,
    });
  }
/*
  setSchedule(unit: Unit, schedule: Schedule) {
    let events: any[] = [];
    let eventsJSON = JSON.parse(schedule.dhtmlx_json_data);

    for (let i = 0; i < eventsJSON.length; i++) {
      let event: any = eventsJSON[i];

      if (event.play_content) {
        if (isSlide(event.play_content)) {
          event.content_type = {
            type: 'slide',
            id: event.play_content.id,
          };
        } else if (isPlaylist(event.play_content)) {
          event.content_type = {
            type: 'playlist',
            id: event.play_content.id,
          };
        }

        events.push(event);
      }
    }
    return this.deviceCommunicationService.sendCommand(unit, 'events-list/set', 'queued', { events: events });
  }*/

  getSlide(unit: Unit, slideID: string):Promise<UnitQueueCommand> {
    return this.deviceCommunicationService.sendCommand(unit, 'getSlide', 'queued', { id: slideID }, 7000);
  }

  getPlaylist(unit: Unit, playlistID: string):Promise<UnitQueueCommand> {
    return this.deviceCommunicationService.sendCommand(unit, 'getPlaylist', 'queued', { id: playlistID });
  }

  downloadSlide(unit: Unit, slideUploadPayload: Slide, uploadMode: 'downloadFromCloud' | 'downloadFromCloudIfExists'):Promise<UnitQueueCommand> {
    return this.deviceCommunicationService.sendCommand(unit, 'downloadSlide', 'queued', {
      uploadMode: uploadMode,
      id: slideUploadPayload.id,
      last_modified: slideUploadPayload.modified,
      jsDeps: [],//if empty, 500799 will assume values from editorCanvasDependencies as of commit 9e20c13
      cssDeps: []//if empty, 500799 will assume values from editorCanvasDependencies as of commit 9e20c13
    }, 1000 * 60 * 5, false);
  }


  downloadPlaylist(unit: Unit, playlist: Playlist, uploadMode: 'downloadFromCloud' | 'downloadFromCloudIfExists') {
    return this.deviceCommunicationService.sendCommand(unit, 'downloadPlaylist', 'queued', {
      id: playlist.id, uploadMode: uploadMode
    }, 1000 * 60 * 60, false);
  }

  downloadSchedule(unit: Unit, schedule: Schedule) {
    let payload:any = {};
    if (typeof schedule !== 'undefined' && schedule.object_type === 'group' && typeof schedule.object_id !== 'undefined') {
      payload.object_type = schedule.object_type;
      payload.object_id = schedule.object_id;
    }
    return this.deviceCommunicationService.sendCommand(unit, 'downloadSchedule', 'queued', payload, 1000 * 60 * 60, false);
  }

  setDuplicateDisplay(unit: Unit, duplicate: boolean) {
    return this.deviceCommunicationService.sendCommand(unit, 'device/change-display-mode', 'immediate', {
      duplicateDisplay: duplicate,
    });
  }

  setScreenBlink(unit: Unit, blink: boolean, html?: string) {
    if (!html || !html.length) {
      html = '';
    }

    return this.deviceCommunicationService.sendCommand(unit, 'device/blink', 'immediate', { blink: blink, message: html });
  }

  getUnitInfo(unit: Unit) {
    let ttl = new Date();
    ttl.setHours((ttl.getHours() + 1))//expire after 1h
    return this.deviceCommunicationService.sendCommand(unit, 'device/info', 'immediate', {}, undefined, true, ttl);
  }

  downloadKeywordDictionary(unit: Unit, dictionary: KeywordDictionaryEntry[]) {
    /*let formData = new FormData();

    let formattedDictionary: any = dictionary;
    for (let i = 0; i < formattedDictionary.length; i++) {
      formattedDictionary[i].dictionary_target = formattedDictionary[i].dictionary_target.toLowerCase();
    }

    let dictionaryBlob = new Blob([JSON.stringify(formattedDictionary)], { type: 'application/json' });
    formData.append('dictionary', dictionaryBlob, 'dictionary.json');
    return this.doUnitAPICall('POST', unit.ip, 'dictionary/keywords', formData, unit, { 'Content-Type': 'multiplart/form-data' });
*/
    return this.deviceCommunicationService.sendCommand(unit, 'downloadKeywordDictionary', 'immediate', {});
  }

  /*addTag(unit: Unit, tag: Tag) {
    return this.doUnitAPICall(
        'POST',
        unit.ip,
        'tag/add',
        JSON.stringify({
          from_entity: tag.from_entity,
          from_id: tag.from_id,
          to_entity: tag.to_entity,
          to_id: tag.to_id,
        }),
        unit
    );
  }

  deleteTag(unit: Unit, tag: Tag) {
    return this.doUnitAPICall(
        'POST',
        unit.ip,
        'tag/delete',
        JSON.stringify({
          from_entity: tag.from_entity,
          from_id: tag.from_id,
          to_entity: tag.to_entity,
          to_id: tag.to_id,
        }),
        unit
    );
  }*/

  downloadTags(unit:Unit) {
    return this.deviceCommunicationService.sendCommand(unit, 'downloadTags', 'immediate', {});
  }

  private getMySqlDatetime(inputTime) {
    if (!inputTime) inputTime = 0;
    const timezoneOffset = new Date().getTimezoneOffset() * 60000;
    return new Date(inputTime - timezoneOffset).toISOString().slice(0, 19).replace('T', ' ');
  }

  updateCustomer(unit: Unit, customer: Customer) {
    const customerData = {
      id: customer.uid,
      charge_rate: customer.charge_rate || '0',
      billing_cycle: customer.billing_cycle || '',
      currency: customer.currency || '',
      updated_at: this.getMySqlDatetime(customer.modified),
    };
    return this.doUnitAPICall('POST', unit.ip, 'customer/update', JSON.stringify(customerData), unit);
  }

  deleteCustomer(unit: Unit, uid: String) {
    return this.doUnitAPICall('POST', unit.ip, 'customer/delete', JSON.stringify({ uid }), unit);
  }

  generateCustomerReport(unit: Unit, customer: Customer) {
    const reportData = {
      customer_id: customer.uid,
    };
    return this.doUnitAPICall('POST', unit.ip, 'customer/report', JSON.stringify(reportData), unit, {
      timeout: '999999000', // should almost never timeout because the result could be very big
    });
  }

  getCustomers(unit: Unit) {
    return this.doUnitAPICall('POST', unit.ip, 'customer/get-all', {}, unit, {
      timeout: '60', // should almost never timeout because the result could be very big
    });
  }

  updateUnit(unit: Unit) {
    var unitData = {
      location: unit.location,
      name: unit.name,
    };
    return this.doUnitAPICall('POST', unit.ip, 'unit', JSON.stringify(unitData), unit);
  }

  playSlideNow(unit: Unit, slide_id: string) {
    let ttl = new Date();
    ttl.setHours((ttl.getHours() + 1))//expire after 1h
    return this.deviceCommunicationService.sendCommand(unit, 'playSlide', 'queued', {slide_id: slide_id}, undefined, true, ttl);
  }

  playPlaylistNow(unit: Unit, playlist_id: string) {
    return this.doUnitAPICall('POST', unit.ip, 'playlist/' + playlist_id + '/play', {}, unit);
  }

  doHeartbeat(unit: Unit) {
    let ttl = new Date();
    ttl.setHours((ttl.getHours() + 1))//expire after 1h
    return this.deviceCommunicationService.sendCommand(unit, 'heartbeat', 'immediate', {}, 5000, true, ttl).then(res => {
      let resUnitInfo = JSON.parse(res.status_message);
      this.fireDB.doc<Unit>(this.fireSessionService.getPathToCurrentWorkspace() + '/units/' + unit.id).update({
        is_disconnected: false,
        api_version: resUnitInfo.api_version,
        ip: resUnitInfo.ip_address
      });
    }).catch(err => {
      this.fireDB.doc<Unit>(this.fireSessionService.getPathToCurrentWorkspace() + '/units/' + unit.id).update({is_disconnected: true});
    });
  }

  doFirmwareUpdate(unit: Unit, updateDetails: UpdateDetailsPayload) {
    let ttl = new Date();
    ttl.setHours((ttl.getHours() + 1))//expire after 1h
    return this.deviceCommunicationService.sendCommand(unit, 'doFirmwareUpdate', 'queued', updateDetails, undefined, true, ttl);
  }

  resetUnitPasswordToDefault(unit: Unit) {
    return this.doUnitAPICall('POST', unit.ip, 'auth/reset-password', {}, unit);
  }

  changeDisplayOrientation(unit: Unit) {
    return this.deviceCommunicationService.sendCommand(unit, '/device/change-display-orientation', 'immediate', {});
  }

  doReboot(unit: Unit) {
    let ttl = new Date();
    ttl.setHours((ttl.getHours() + 1))//expire after 1h
    return this.deviceCommunicationService.sendCommand(unit, '/device/reboot', 'immediate', {}, undefined, true, ttl);
  }

  doStopScheduler(unit: Unit) {
    return this.deviceCommunicationService.sendCommand(unit, '/device/scheduler-config', 'immediate', {enableEventExecution: false});
  }

  doStartScheduler(unit: Unit) {
    return this.deviceCommunicationService.sendCommand(unit, '/device/scheduler-config', 'immediate', {enableEventExecution: true});
  }

  public doUnitAPICall(
      method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE' = 'POST',
      ip: string,
      endpoint: string,
      body: any,
      unit?: Unit,
      requestHeaders?: { [key: string]: string }
  ): Promise<Response> {
    return new Promise<Response>((resolve, reject) => {
      if (unit && typeof unit.is_disconnected !== 'undefined' && unit.is_disconnected === true) {
        reject(new Error(AppErrors.S_UP001.code));
        return;
      }

      //headers
      let headers = {
        'Content-Type': 'application/json',
      };
      if (unit && unit.auth_token && unit.auth_token.length) {
        //we are allowing api calls without an auth token, since some endpoints don't require an auth token (i.e. get status, factory reset mode etc)
        headers['Authorization'] = 'Bearer ' + unit.auth_token;
      }

      if (requestHeaders) {
        headers = { ...headers, ...requestHeaders };

        //fix for angular, doesn't properly send form data when content-type is set to multiplart/form-data required for sending binary data
        if (requestHeaders['Content-Type'] && requestHeaders['Content-Type'] === 'multiplart/form-data') {
          delete headers['Content-Type'];
        }
      }

      //end headers

      if (!body) {
        body = {};
      }

      /*	if ('update/upload-application' == endpoint){
              endpoint = 'update/upload-application888';
          }*/

      const doRequestForMethod = (method: string) => {
        let path = 'https://' + ip + ':' + API789Service.UNIT_API_PORT + API789Service.UNIT_API_PATH + '/' + endpoint;
        if (method.toUpperCase() === 'POST' || method.toUpperCase() === 'PUT' || method.toUpperCase() === 'PATCH') {
          return this.httpClient[method.toLowerCase()](path, body, { headers: headers });
        } else {
          return this.httpClient[method.toLowerCase()](path, { headers: headers });
        }
      };

      doRequestForMethod(method).subscribe(
          (response: Response) => {
            resolve(response);
          },
          (error) => {
            try {
              this.debugLogService.logMessage('error with request; ' + ip + '; ' + method + '; ' + endpoint + '; ' + JSON.stringify(body) + '. ');
            } catch (e) {}

            if (error.error instanceof Error) {
              // A client-side or network error occurred. Handle it accordingly.
              console.error('An error occurred:', error.error.message);

              try {
                this.debugLogService.logMessage(error.error.message);
              } catch (e) {}
            } else {
              // The backend returned an unsuccessful response code.
              // The response body may contain clues as to what went wrong,
              console.error(`Backend returned code ${error.status}, body was:`, JSON.stringify(error.error));
              try {
                this.debugLogService.logMessage(`Backend returned code ${error.status}, body was:` + JSON.stringify(error.error));
              } catch (e) {}
            }
            reject(error);
          }
      );
    });
  }

  /**
   * Use this for small files only, such as thumbnails, since this is not so efficient
   * @param dataURI
   */
  private dataURItoBlob(dataURI) {
    if (dataURI) {
      let splitData = dataURI.split(',');
      if (splitData[1]) {
        var byteString = atob(splitData[1]);
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);
        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: 'image/png' });
      }

      return '';
    }
    return '';
  }

  executeUploadContentToUnit = (uploadTask: UploadTask) => {
    let uploadSubject = new Subject<ContentUploadProgressUpdate>();

    setTimeout(() => {
      let uploadedContent;
      if (uploadTask && uploadTask.content) {
        if (isSlide((<any>uploadTask.content))) {
          uploadedContent = (<any>uploadTask.content);
        } else if (isPlaylist(uploadTask.content)) {
          uploadedContent = uploadTask.content;
        } else if (isSchedule(uploadTask.content)) {
          uploadedContent = uploadTask.content;
        }
      }

      uploadSubject.next({
        progress: {
          uploadTarget: {
            target_type: 'unit',
            target: uploadTask.unit,
          },
          status: 'started',
          content: uploadedContent,
        },
      });

      if (uploadTask.uploadMode !== 'skip') {
        let apiCallPromise;

        if (isSlide((<any>uploadTask.content))) {
          let slideData = <Slide>uploadTask.content;

          apiCallPromise = this.downloadSlide(uploadTask.unit, slideData, (uploadTask.uploadMode === 'downloadFromCloudIfExists' ? 'downloadFromCloudIfExists' : 'downloadFromCloud'));
        } else if (isPlaylist(uploadTask.content)) {
          let playlist = <Playlist>uploadTask.content;

          apiCallPromise = this.downloadPlaylist(uploadTask.unit, playlist, (uploadTask.uploadMode === 'downloadFromCloudIfExists' ? 'downloadFromCloudIfExists' : 'downloadFromCloud'));

        } else if (isSchedule(uploadTask.content)) {
          let schedule = <Schedule>uploadTask.content;

          apiCallPromise = this.downloadSchedule(uploadTask.unit, uploadTask.content);
        }

        apiCallPromise
          .then((res: UnitQueueCommand) => {
            console.log('resLgr', res);
            if (res && res.status === "success") {
              uploadSubject.next({
                progress: {
                  uploadTarget: {
                    target_type: 'unit',
                    target: uploadTask.unit,
                  },
                  content: uploadedContent,
                  status: 'done',
                },
              });
              uploadSubject.complete();
            } else if (!res || res.status === "error") {
              uploadSubject.next({
                progress: {
                  uploadTarget: {
                    target_type: 'unit',
                    target: uploadTask.unit,
                  },
                  status: 'failed',
                  content: uploadedContent,
                  message: res.status_message ? res.status_message : '',
                },
              });
              uploadSubject.complete();
            }
          })
          .catch((e) => {
            console.log('e', e);
            if (isUnitQueueCommand(e)) {
              uploadSubject.next({
                progress: {
                  uploadTarget: {
                    target_type: 'unit',
                    target: uploadTask.unit,
                  },
                  status: 'failed',
                  content: uploadedContent,
                  message: e.status_message ? e.status_message : '',
                },
              });
              uploadSubject.complete();
            } else {
              let errorMessage = 'Unable to reach unit';
              if (e) {
                if (e.error && e.error.error) {
                  errorMessage = e.error.error.message || e.error.error.type;
                } else {
                  let appError = getAppError(e.message || '');
                  if (appError) {
                    errorMessage = appError.title + ' (' + appError.code + ' )';
                  } else {
                    errorMessage = e.name || e.status;
                  }
                }
              }
              uploadSubject.next({
                progress: {
                  uploadTarget: {
                    target_type: 'unit',
                    target: uploadTask.unit,
                  },
                  status: 'failed',
                  content: uploadedContent,
                  message: errorMessage,
                },
              });
              uploadSubject.complete();
            }
          });
      } else {
        uploadSubject.next({
          progress: {
            uploadTarget: {
              target_type: 'unit',
              target: uploadTask.unit,
            },
            status: 'done',
            content: uploadedContent,
            message: 'skipping',
          },
        });
        uploadSubject.complete();
      }
    });

    return uploadSubject;
  };
}

//
// //utility functions to replace parts of the HTML to upload, see https://stackoverflow.com/a/1144788
// const escapeRegExp = (string) => {
//     return string.replace(/[.*+\-?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
// };
//
// const replaceAll = (str, find, replace) => {
//     return str.replace(new RegExp(escapeRegExp(find), 'g'), replace);
// }

const cleanMediaPath = (path) => {
  path = path.replace('src="', '');
  path = path.replace('src=', '');
  if (path.charAt(path.length - 1) === '"' || path.charAt(path.length - 1) === "'") {
    path = path.substring(0, path.length - 1);
  }

  return path;
};
