import { HostListener, Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { AlertService } from '../alert.service';
import { Template } from '../../types/Template';
import {TranslateService} from "@ngx-translate/core";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {FireSessionService} from "../fire-session.service";
import {Unit} from "../../types/Unit";
import {AngularFirestoreCollection} from "@angular/fire/compat/firestore/collection/collection";
import {AirportFlightStatus} from "../../../assets/default-template-assets/AirportFlightStatus/AirportFlightStatus";
import {AirportLobby} from "../../../assets/default-template-assets/AirportLobby/AirportLobby";
import {BrunchTemplate} from "../../../assets/default-template-assets/BrunchTemplate";
import {ClothesTemplate} from "../../../assets/default-template-assets/ClothesTemplate";
import {CoffeBreak} from "../../../assets/default-template-assets/CoffeBreak";
import {FashionSaleTemplate} from "../../../assets/default-template-assets/FashionSale/FashionSale";
import {FastFood} from "../../../assets/default-template-assets/FastFood";
import {FastFoodVPage1} from "../../../assets/default-template-assets/FastFoodVPage1";
import {FastFoodVPage2} from "../../../assets/default-template-assets/FastFoodVPage2";
import {FriedChickenBox} from "../../../assets/default-template-assets/FriedChickenBox/FriedChickenBox";
import {InformationTemplate} from "../../../assets/default-template-assets/InformationTemplate";
import {HotelBreakfast} from "../../../assets/default-template-assets/HotelBreakfast";
import {InfoSlideshowTemplate} from "../../../assets/default-template-assets/InfoSlideshow";
import {InteractiveMap} from "../../../assets/default-template-assets/InteractiveMap";
import {MedicalCenter} from "../../../assets/default-template-assets/MedicalCenter/MedicalCenter";
import {OffAllItems} from "../../../assets/default-template-assets/OffAllItems/OffAllItems";
import {BlankTemplate} from "../../../assets/default-template-assets/BlankTemplate";
import {CarTemplate} from "../../../assets/default-template-assets/ProductSpotlight/CarTemplate";
import {InfoSlide2Template} from "../../../assets/default-template-assets/InfoSlide2";
import {RestaurantCombos} from "../../../assets/default-template-assets/RestaurantCombos";
import {RestaurantCombosInteractive} from "../../../assets/default-template-assets/RestaurantCombosInteractive";
import {RestaurantSpecial} from "../../../assets/default-template-assets/RestaurantSpecial";
import {Retail1} from "../../../assets/default-template-assets/Retail1";
import {Retail2} from "../../../assets/default-template-assets/Retail2";
import {Retail3} from "../../../assets/default-template-assets/Retail3";
import {SoupTemplate} from "../../../assets/default-template-assets/SoupTemplate";
import {TemperatureControl} from "../../../assets/default-template-assets/TemperatureControl";
import {TutorialInteractiveArea} from "../../../assets/default-template-assets/TutorialInteractiveArea";
import {TutorialInteractiveMode} from "../../../assets/default-template-assets/TutorialInteractiveMode";
import {UpcomingMeetings} from "../../../assets/default-template-assets/UpcomingMeetings";

@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  openFromTemplate = false;
  templateLoaded: Template = null;
  alertPopover: any;

  constructor(private alertCtrl: AlertController,
              private alertService: AlertService,
              private translate: TranslateService,
              private fireDB: AngularFirestore,
              private fireAuth: AngularFireAuth,
              private fireSessionService: FireSessionService
  ) {

  }

  deleteTemplate(templateId: string) {
    return this.fireDB.doc(this.getPathToTemplateInWorkspaceById(templateId)).delete();
  }

  addTemplate(template: Template): Promise<any> {
    return this.saveTemplate(template);
  }

  saveTemplate(template: Template) {
    return this.fireDB.doc(this.getPathToTemplateInWorkspaceById(template.id)).set(this.fireSessionService.serializeObject(template));
  }

  getTemplate(id):Promise<Template> {
    return this.fireDB.doc<Template>(this.getPathToTemplateInWorkspaceById(id)).get().toPromise().then(res => {
      if (res.data()) {
        let data = res.data();
        return Promise.resolve(this.fireSessionService.deserializeTemplate(data));
      } else {
        return Promise.reject();
      }
    }).catch(err => {
      return Promise.reject();
    });
  }

  checkTemplateByName(name: string): Promise<Boolean> {
    return new Promise<Boolean>(resolve => {
      this.fireDB.collection<Template>(this.getPathToTemplatesInWorkspace(), ref => ref.where('name', '==', name)).get().toPromise().then(res => {
        if (res.empty) {
          resolve(false);
        } else {
          resolve(true);
        }
      }).catch(err => {
        resolve(false);
      });
    });

  }

  getTemplates(): Promise<Template[]> {
    return new Promise<Template[]>((resolve, reject) => {
      return this.getAllTemplatesCollection().get().toPromise().then(res => {
        let templates:Template[] = [];
        for (let i = 0; i < res.docs.length; i++) {
          templates.push(this.fireSessionService.deserializeTemplate(res.docs[i].data()));
        }

        templates.push(BlankTemplate);
        templates.push(AirportFlightStatus);
        templates.push(AirportLobby);
        templates.push(BrunchTemplate);
          //templates.push(CarTemplate);//something's wrong with it's thumb it's not loading.
        templates.push(ClothesTemplate);
        templates.push(CoffeBreak);
        templates.push(FashionSaleTemplate);
        templates.push(FastFood);
        templates.push(FastFoodVPage1);
        templates.push(FastFoodVPage2);
        templates.push(FriedChickenBox);
        templates.push(InformationTemplate);
        templates.push(HotelBreakfast);
        templates.push(InfoSlideshowTemplate);
        templates.push(InfoSlide2Template);
        //templates.push(InteractiveMap);//TODO exceeds Firebase 1 MB limit per document, see if it can be reduced
        templates.push(MedicalCenter);
        templates.push(OffAllItems);
        templates.push(RestaurantCombos);
        templates.push(RestaurantCombosInteractive);
        templates.push(RestaurantSpecial);
        templates.push(Retail1);
        templates.push(Retail2);
        templates.push(Retail3);
        templates.push(SoupTemplate);
        templates.push(TemperatureControl);
        templates.push(TutorialInteractiveArea);
        templates.push(TutorialInteractiveMode);
        templates.push(UpcomingMeetings);

        resolve(templates);
      }).catch(() => {
        reject();
      });
    });
  }

  getAllTemplatesCollection():AngularFirestoreCollection<Template> {
    return this.fireDB.collection<Template>(this.getPathToTemplatesInWorkspace());
  }

  promptUserForUniqueTemplateName() {
    return new Promise((resolve, reject) => {
      this.alertCtrl
        .create({
          header: this.translate.instant('Editor.Templates.NameYourTemplate'),
          inputs: [
            {
              name: 'templateName',
              type: 'text',
              value: '',
              placeholder: this.translate.instant('Editor.Templates.TemplateName'),
            },
          ],
          buttons: [
            {
              text: this.translate.instant('General.Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                console.log('cancel');
                reject('cancel');
                this.alertPopover.dismiss('cancel');
              },
            },
            {
              text: this.translate.instant('General.Ok'),
              handler: (input) => {
                // if input empty
                if (input.templateName === '' || input.templateName === null || input.templateName === undefined) {
                  this.alertService.createToast(this.translate.instant('Common.YouMustSpecifyAName'), undefined, 'danger');
                  return false;
                } else {
                  // check if template exists
                  this.checkTemplateByName(input.templateName).then(async (resp) => {
                    if (resp) {
                      var nametakenAlert = await this.alertService.alertModal(
                        this.translate.instant('General.Error') + ' ' + this.translate.instant('Common.NameAlreadyTaken'),
                        this.translate.instant('Common.PleaseUseADifferentName')
                      );
                      nametakenAlert.onDidDismiss().then(() => {
                        reject('exists');
                        return false;
                      });
                    }
                    // on success
                    else {
                      this.alertPopover.onDidDismiss().then(() => {
                        resolve(input.templateName);
                      });
                    }
                  });
                }
              },
            },
          ],
        })
        .then((alert) => {
          this.alertPopover = alert;
          this.alertPopover.present().then(() => AlertService.EnableEnterToConfirm());
        });
    });
  }

  getPathToTemplatesInWorkspace() {
    return this.fireSessionService.getPathToCurrentWorkspace() + '/templates';
  }

  getPathToTemplateInWorkspaceById(id: string) {
    return this.fireSessionService.getPathToCurrentWorkspace() + '/templates/' + id;
  }
}
