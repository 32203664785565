import { getTemplateAssetDirectory, Template } from '../../app/types/Template';

export const Retail3: Template = {
  isDefaultTemplate: true,
  defaultTemplateKeywords: ['retail', 'sales', 'store'],
  id: 'default-retail-3',
  dimensions: [1080, 1920],
  renderedHTML: undefined,
  name: 'Retail 3',
  durationInSeconds: 30,
  highest_zIndex: 8,
  assets: [],
  styles: [
    { selectors: [{ name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false }], style: { width: '400px', height: '400px', 'background-color': 'rgb(10, 44, 52)' } },
    { selectors: [], selectorsAdd: '.box *', style: { 'pointer-events': 'none' } },
    {
      selectors: [
        { name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false },
        { name: 'fadeTop', label: 'fadeTop', type: 1, active: true, private: 1, protected: false },
      ],
      style: { '-webkit-mask-image': '-webkit-gradient(linear, 0% 100%, 0% 0%, from(rgb(0, 0, 0)), color-stop(0.75, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))' },
    },
    {
      selectors: [
        { name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false },
        { name: 'fadeLeft', label: 'fadeLeft', type: 1, active: true, private: 1, protected: false },
      ],
      style: { '-webkit-mask-image': '-webkit-gradient(linear, 100% 0%, 0% 0%, from(rgb(0, 0, 0)), color-stop(0.75, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))' },
    },
    {
      selectors: [
        { name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false },
        { name: 'fadeBottom', label: 'fadeBottom', type: 1, active: true, private: 1, protected: false },
      ],
      style: { '-webkit-mask-image': '-webkit-gradient(linear, 0% 0%, 0% 100%, from(rgb(0, 0, 0)), color-stop(0.75, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))' },
    },
    {
      selectors: [
        { name: 'box', label: 'box', type: 1, active: true, private: 1, protected: false },
        { name: 'fadeRight', label: 'fadeRight', type: 1, active: true, private: 1, protected: false },
      ],
      style: { '-webkit-mask-image': '-webkit-gradient(linear, 0% 0%, 100% 0%, from(rgb(0, 0, 0)), color-stop(0.75, rgb(0, 0, 0)), to(rgba(0, 0, 0, 0)))' },
    },
    { selectors: [], selectorsAdd: '.box .box-widget-container', style: { width: '100%', height: '100%' } },
    {
      selectors: [{ name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false }],
      style: { 'font-size': '45px', 'padding-top': '35px', 'padding-right': '35px', 'padding-bottom': '35px', 'padding-left': '35px' },
    },
    { selectors: [{ name: 'wrapper', label: 'wrapper', type: 2, active: true, private: 1, protected: false }], style: { 'background-color': 'none' }, wrapper: 1 },
    {
      selectors: [{ name: 'id1b', label: 'id1b', type: 2, active: true, private: 1, protected: false }],
      style: { left: 0, top: 0, position: 'absolute', 'z-index': 0, height: '100%', width: '100%' },
    },
    { selectors: [{ name: 'igtl', label: 'igtl', type: 2, active: true, private: 1, protected: false }], style: { left: '730px', top: '1570px', position: 'absolute', 'z-index': 2 } },
    {
      selectors: [{ name: 'inqr', label: 'inqr', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '-37px',
        top: '432px',
        position: 'absolute',
        'z-index': 3,
        color: '#035B96',
        'font-size': '80px',
        en: '',
        width: '730px',
        height: '282px',
        'line-height': '85px',
        'font-family': 'Lato, sans-serif',
      },
    },
    {
      selectors: [{ name: 'ikhf', label: 'ikhf', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '111.39561760355025px',
        top: '214.58431952662716px',
        position: 'absolute',
        'z-index': 4,
        color: '#035B96',
        'font-size': '250px',
        en: '',
        width: '432.3550295857988px',
        height: '282px',
        'line-height': '85px',
        'font-family': 'Lato, sans-serif',
      },
    },
    {
      selectors: [{ name: 'ila7', label: 'ila7', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '-19.479382396449772px',
        top: '260.48779585798786px',
        position: 'absolute',
        'z-index': 5,
        color: '#02386C',
        'font-size': '55px',
        en: '',
        width: '232.75147928994085px',
        height: '190.2011834319527px',
        'line-height': '85px',
        'font-family': 'Lato, sans-serif',
      },
    },
    {
      selectors: [{ name: 'ini4', label: 'ini4', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '450.52061760355025px',
        top: '214.58154585798786px',
        position: 'absolute',
        'z-index': 6,
        color: '#02386C',
        'font-size': '100px',
        en: '',
        width: '153.15384615384613px',
        height: '190.2011834319527px',
        'line-height': '85px',
        'font-family': 'Lato, sans-serif',
      },
    },
    {
      selectors: [{ name: 'iui1', label: 'iui1', type: 2, active: true, private: 1, protected: false }],
      style: { left: '147px', top: '113px', position: 'absolute', 'z-index': 7, width: '450px', en: '' },
    },
    {
      selectors: [{ name: 'izr9', label: 'izr9', type: 2, active: true, private: 1, protected: false }],
      style: { left: 0, top: 0, position: 'absolute', 'z-index': 1, height: '100%', width: '100%', 'Bring-to:': '1', 'Move:': '1' },
    },
    {
      selectors: [{ name: 'ibdr', label: 'ibdr', type: 2, active: true, private: 1, protected: false }],
      style: {
        left: '135px',
        top: '85px',
        position: 'absolute',
        'z-index': 8,
        color: '#FFF',
        'font-size': '80px',
        en: '',
        width: '472px',
        height: '170px',
        'line-height': '85px',
        'font-family': 'Lato, sans-serif',
      },
    },
  ],
  components: [
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'id1b', src: getTemplateAssetDirectory() + 'Retail/Retail3Background.png', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Retail/Retail3Background.png',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'igtl', src: getTemplateAssetDirectory() + 'Retail/Logo3.png', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Retail/Logo3.png',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">THE BIGGEST WINTER SALE</div></div></div></div></div></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'inqr' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">SALE</div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'ibdr' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;">Up to</div></div></div></div></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'ila7' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>%</b></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'ini4' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      view: '',
    },
    {
      type: 'text',
      content:
        '<div class="text-widget-container" data-gjs-editable="true" contenteditable="true"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><div class="text-widget-container" data-gjs-editable="true" contenteditable="true" style="pointer-events: auto;"><b>70</b></div></div></div></div></div></div></div></div></div>',
      classes: [
        { name: 'texts', label: 'texts', type: 1, active: true, private: 1, protected: false },
        { name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false },
      ],
      attributes: { keywords: { showingValues: false, replacedKeywords: [] }, 'data-gjs-drag-mode': false, id: 'ikhf' },
      dmode: 0,
      class: 'gjs-block-text',
      scriptUpdated: 1,
      view: '',
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'iui1', src: getTemplateAssetDirectory() + 'Retail/Retail3Line.svg', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Retail/Retail3Line.svg',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
    {
      type: 'image',
      void: true,
      content: '',
      classes: [{ name: 'editor', label: 'editor', type: 1, active: true, private: 1, protected: false }],
      attributes: { id: 'izr9', src: getTemplateAssetDirectory() + 'Retail/Retail3Background2.svg', 'data-gjs-drag-mode': false },
      dmode: 0,
      src: getTemplateAssetDirectory() + 'Retail/Retail3Background2.svg',
      activeOnRender: 0,
      scriptUpdated: 1,
    },
  ],
  html: '',
  css: '* { box-sizing: border-box; } body {margin: 0;}.box *{pointer-events:none;}.box .box-widget-container{width:100%;height:100%;}.texts{font-size:45px;padding-top:35px;padding-right:35px;padding-bottom:35px;padding-left:35px;}body{background-color:none;}#id1b{left:0;top:0;position:absolute;z-index:0;height:100%;width:100%;}#igtl{left:730px;top:1570px;position:absolute;z-index:2;}#inqr{left:-37px;top:432px;position:absolute;z-index:3;color:#035B96;font-size:80px;width:730px;height:282px;line-height:85px;font-family:Lato, sans-serif;}#ikhf{left:111.39561760355025px;top:214.58431952662716px;position:absolute;z-index:4;color:#035B96;font-size:250px;width:432.3550295857988px;height:282px;line-height:85px;font-family:Lato, sans-serif;}#ila7{left:-19.479382396449772px;top:260.48779585798786px;position:absolute;z-index:5;color:#02386C;font-size:55px;width:232.75147928994085px;height:190.2011834319527px;line-height:85px;font-family:Lato, sans-serif;}#ini4{left:450.52061760355025px;top:214.58154585798786px;position:absolute;z-index:6;color:#02386C;font-size:100px;width:153.15384615384613px;height:190.2011834319527px;line-height:85px;font-family:Lato, sans-serif;}#iui1{left:147px;top:113px;position:absolute;z-index:7;width:450px;}#izr9{left:0;top:0;position:absolute;z-index:1;height:100%;width:100%;Bring-to::1;Move::1;}#ibdr{left:135px;top:85px;position:absolute;z-index:8;color:#FFF;font-size:80px;width:472px;height:170px;line-height:85px;font-family:Lato, sans-serif;}',
  thumbnail: getTemplateAssetDirectory() + '/Retail/thumbnail3.png',
};
