import { Injectable } from '@angular/core';
import {AppSetting} from "../types/AppSetting";
import {ModalController} from "@ionic/angular";
import {UserSettingsService} from "./user-settings.service";
import {TranslateService} from "@ngx-translate/core";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {FireSessionService} from "./fire-session.service";

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  allSettings: AppSetting[] = [];

  public availableLanguages = ['en', 'fr'];

  constructor(private modalController: ModalController,
              private userSettingsService: UserSettingsService,
              private fireSessionService: FireSessionService,
              private fireDB: AngularFirestore,
              private translate: TranslateService) {

  }

  getSetting(key: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.getAll().then((res) => {
        if (res) {
          this.allSettings = res;

          let result = this.allSettings.find((item) => item.key == key);
          if (result && typeof result.value !== 'undefined') {
            resolve(result.value);
          } else {
            resolve('');
          }
        }
      }).catch(e => {
        reject('Failed to getAll(): ' + e)
      })
    })


  }

  setValue(key: string, value: string): Promise<any> {
    let dataToSave = {};
    dataToSave[key] = value;
    return this.fireDB.doc(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceSettings/' + key).set(dataToSave);
  }

  getValue(key: string) {
    return new Promise<any>((resolve, reject) => {
      this.fireDB.doc(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceSettings/' + key).get().toPromise().then(res => {
        let data = res.data();
        if (data && data[key]) {
          resolve(data[key]);
        } else {
          reject();
        }
      }).catch(err => {
        reject();
      })
    });
  }

  getAll():Promise<AppSetting[]> {
    return new Promise<any>((resolve, reject) => {
      this.fireDB.collection(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceSettings').get().toPromise().then(res => {

        let data = res.docs;
        if (data && data.length) {
          let settings = [];
          for (let i = 0; i < data.length; i++) {
            settings.push(data[i].data());
          }
          resolve(settings);
        } else {
          reject();
        }
      }).catch(err => {
        reject();
      })
    });
  }

  getAppLanguage(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      this.getValue('language').then((res) => {
        if (typeof res === 'string' && res.length && this.availableLanguages.indexOf(res) >= 0) {
          resolve(res);
        } else {
          resolve('en');
        }
      });
    });
  }

  useLanguage(lang: string = null) {
    if (lang) {
      this.translate.use(lang);
    } else {
      this.userSettingsService.getValue('language').then((res) => {
        if (typeof res === 'string' && res.length && this.availableLanguages.indexOf(res) >= 0) {
          this.translate.use(res);
          this.setDefaultLanguage(res);
        } else {
          this.getValue('language')
            .then((res) => {
              if (typeof res === 'string' && res.length && this.availableLanguages.indexOf(res) >= 0) {
                this.translate.use(res);
              } else {
                this.translate.use('en');
              }
            })
            .catch((e) => {
              this.translate.use('en');
            });
        }
      }).catch(() => {
        this.translate.use('en');
      });
    }
  }

  setDefaultLanguage(language: string) {
    this.setValue('language', language);
  }
}
