import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFirestoreCollection} from "@angular/fire/compat/firestore/collection/collection";
import {WorkspaceUser} from "../types/WorkspaceUser";
import {Permissions} from "../types/Permissions";
import {WorkspaceUserRole} from "../types/WorkspaceUserRole";
import {FireSessionService} from "./fire-session.service";
import {Unit} from "../types/Unit";

@Injectable({
  providedIn: 'root'
})
export class WorkspaceUsersService {

  cachedWorkspaceUserRoles = {};

  constructor(private fireDB: AngularFirestore, private fireSessionService: FireSessionService) {

  }

  saveWorkspaceUser(user: WorkspaceUser, isNew?: boolean) {
    let promises = [];
    try {
      if (isNew) {
        let currentWorkspace = this.fireSessionService.currentWorkspace.value;
        let usersWithAccessToWorkspace = [];
        if (typeof currentWorkspace.usersWithAccessToWorkspace !== 'undefined' && currentWorkspace.usersWithAccessToWorkspace && currentWorkspace.usersWithAccessToWorkspace.length) {
          usersWithAccessToWorkspace = currentWorkspace.usersWithAccessToWorkspace;
        }

        usersWithAccessToWorkspace.push(user.email);
        promises.push(this.fireDB.doc(this.fireSessionService.getPathToCurrentWorkspace()).update({usersWithAccessToWorkspace: usersWithAccessToWorkspace}));
      }
    } catch (e) {}

    promises.push(this.fireDB.collection(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUsers').doc(user.email).set(this.fireSessionService.serializeObject(user)));

    return Promise.all(promises);
  }

  getWorkspaceUser(email: string):Promise<WorkspaceUser> {
    return new Promise<WorkspaceUser>((resolve, reject) => {
      this.fireDB.doc<WorkspaceUser>(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUsers/' + email).get().subscribe(res => {
        resolve(res.data());
      }, error => {
        reject(error);
      });
    });
  }

  deleteWorkspaceUser(email: string): Promise<any> {
    if (email === this.fireSessionService.currentWorkspace.value.owner_email) {
      return Promise.reject('cannot delete owner user');
    }

    let promises = [];
    try {
      let usersWithAccessToWorkspace = this.fireSessionService.currentWorkspace.value.usersWithAccessToWorkspace;
      usersWithAccessToWorkspace = usersWithAccessToWorkspace.filter(e => e !== email);

      promises.push(this.fireDB.doc(this.fireSessionService.getPathToCurrentWorkspace()).update({usersWithAccessToWorkspace: usersWithAccessToWorkspace}));
    } catch (e) {
      console.log('e', e);}

    promises.push(this.fireDB.doc(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUsers/' + email).delete());
    return Promise.all(promises);
  }

  getAllWorkspaceUsersCollection():AngularFirestoreCollection<WorkspaceUser> {
    return this.fireDB.collection<WorkspaceUser>(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUsers');
  }

  getCurrentLoggedInUser():WorkspaceUser {
    let sessionUserData = this.fireSessionService.currentWorkspaceUser.getValue();
    if (sessionUserData) {
      return new WorkspaceUser(sessionUserData.workspace_id, sessionUserData.email, sessionUserData.role, 'active');
      //TODO isActive hardcoded to true maybe that's good? since he logged in?
    }

    return undefined;
  }

  listenToCurrentLoggedInUserChanges() {
    return this.fireSessionService.currentWorkspaceUser.asObservable();
  }

  getWorkspaceUserRole(name: string, fromCache = false):Promise<WorkspaceUserRole> {
    let currentWorkspace = this.fireSessionService.currentWorkspace.value;
    if (fromCache && typeof this.cachedWorkspaceUserRoles[currentWorkspace.id] !== 'undefined' &&
        typeof this.cachedWorkspaceUserRoles[currentWorkspace.id][name.toLowerCase()] !== 'undefined') {
      return Promise.resolve(this.cachedWorkspaceUserRoles[currentWorkspace.id][name.toLowerCase()]);

    } else {
      return new Promise<WorkspaceUserRole>((resolve, reject) => {
        this.fireDB.doc<WorkspaceUserRole>(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUserRoles/' + name.toLowerCase()).get().subscribe(res => {
          if (typeof this.cachedWorkspaceUserRoles[currentWorkspace.id] === 'undefined') {
            this.cachedWorkspaceUserRoles[currentWorkspace.id] = {};
          }
          this.cachedWorkspaceUserRoles[currentWorkspace.id][name.toLowerCase()] = res.data();
          resolve(res.data());
        }, error => {
          reject(error);
        });
      });
    }

  }

  getWorkspaceUserRolesCollection(): AngularFirestoreCollection<WorkspaceUserRole> {
    return this.fireDB.collection<WorkspaceUserRole>(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUserRoles')
  }

  getWorkspaceUserRoles(): Promise<WorkspaceUserRole[]> {
    return new Promise<WorkspaceUserRole[]>((resolve, reject) => {
      this.fireDB.collection<WorkspaceUserRole>(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUserRoles').get().subscribe(res => {
        if (res.docs && res.docs.length) {
          let workspaceUserRoles = [];
          for (let i = 0; i < res.docs.length; i++) {
            workspaceUserRoles.push(res.docs[i].data());
          }
          resolve(workspaceUserRoles);
        } else {
          resolve([]);
        }
      }, error => {
        reject(error);
      });
    });
  }

  addWorkspaceUserRole(workspaceUserRole: WorkspaceUserRole): Promise<void> {
    return this.saveWorkspaceUserRole(workspaceUserRole);
  }

  private saveWorkspaceUserRole(workspaceUserRole: WorkspaceUserRole): Promise<void> {
    this.cachedWorkspaceUserRoles = {};//clear the cache
    return this.fireDB.doc(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUserRoles/' + workspaceUserRole.name.toLowerCase()).set(this.fireSessionService.serializeObject(workspaceUserRole));
  }

  deleteWorkspaceUserRole(name: string): Promise<void> {
    return this.fireDB.doc(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUserRoles/' + name).delete()
  }

  isWorkspaceUserRoleInUse(name: string): Promise<boolean> {
    return new Promise<boolean>(resolve => {
      this.fireDB.collection(this.fireSessionService.getPathToCurrentWorkspace() + '/workspaceUsers', ref => ref.where('role', '==', name)).get().subscribe(val => {
        if (val.size) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  }

  updateWorkspaceUserRole(workspaceUserRole: WorkspaceUserRole): Promise<void> {
    return this.addWorkspaceUserRole(workspaceUserRole);
  }

  currentUserHasPermission(permissionName: keyof typeof Permissions, unit ?: Unit): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let currentLoggedInUser = this.getCurrentLoggedInUser();

      if (!currentLoggedInUser.isUserActive()) {
        resolve(false);
        return;
      }

      permissionName = permissionName.toString();

      const userRole = currentLoggedInUser.getRole();

      if (userRole === 'ADMIN') {
        resolve(true);
      } else if (userRole === 'OPERATOR') {
        if (typeof Permissions[permissionName] !== 'undefined' && Permissions[permissionName].includedInDefaultRoles.indexOf('OPERATOR') > -1) {
          resolve(true);
        } else {
          resolve(false);
        }
      } else {
        //custom user role

        this.getWorkspaceUserRole(userRole)
          .then((customUserRoleDefinition) => {
            if (
              customUserRoleDefinition &&
              typeof customUserRoleDefinition.permissions !== 'undefined' &&
              customUserRoleDefinition.permissions.length &&
              customUserRoleDefinition.permissions.indexOf(permissionName) >= 0
            ) {

              /*
              * Check if unit permissions are set for the user role
               */
              if (permissionName.toString() ==='EDIT_UNITS' || permissionName.toString() ==='VIEW_UNITS' ||
                permissionName.toString() ==='RESET_UNITS' || permissionName.toString() ==='REBOOT_UNITS' ||
                permissionName.toString() ==='VIEW_SCHEDULES' || permissionName.toString() ==='EDIT_SCHEDULES'){
                let UNIT_PERMISSIONS = customUserRoleDefinition.permissions.find((item): item is any => typeof item === 'object' && 'UNIT_PERMISSIONS' in item)?.UNIT_PERMISSIONS || null

                if (UNIT_PERMISSIONS) {
                  if (UNIT_PERMISSIONS === 'all') {
                    resolve(true);
                  } else {
                    if (unit  && unit.id) {
                      let unitId = unit.id
                      // check to see if Unit_Permissions contains the unitId
                      let unitHasPermission = UNIT_PERMISSIONS.filter((u) => {
                        return u.id === unitId
                      })

                      resolve(unitHasPermission.length > 0);
                    }
                  }
                }
              }

              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            resolve(false);
          });
      }
    });
  }

  currentUserHasPermissions(requestedPermissions: Array<keyof typeof Permissions>, operation: 'AND' | 'OR', unit ?: Unit): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      if (this.getCurrentLoggedInUser()) {
        let allPermissionCheckPromises: Array<Promise<boolean>> = [];

        for (let i = 0; i < requestedPermissions.length; i++) {
          allPermissionCheckPromises.push(this.currentUserHasPermission(requestedPermissions[i], unit));
        }

        Promise.all(allPermissionCheckPromises).then((allPermissionCheckResults) => {
          if (operation === 'AND') {
            let userHasPermission = true;

            for (let i = 0; i < allPermissionCheckResults.length; i++) {
              if (!allPermissionCheckResults[i]) {
                userHasPermission = false;
              }
            }

            resolve(userHasPermission);
          } else if (operation === 'OR') {
            let userHasPermission = false;
            for (let i = 0; i < allPermissionCheckResults.length; i++) {
              if (allPermissionCheckResults[i]) {
                userHasPermission = true;
              }
            }

            resolve(userHasPermission);
          }
        });
      } else {
        resolve(false);
      }
    });
  }

  givenUserHasPermissions(requestedPermissions: Array<keyof typeof Permissions>, operation: 'AND' | 'OR', workspaceUser: WorkspaceUser, requestTimestamp: number, unit?: Unit): Promise<{hasPermission: boolean, request_timestamp: number}> {
    return new Promise<{hasPermission: boolean, request_timestamp: number}>((resolve) => {
      if (workspaceUser) {
        let allPermissionCheckPromises: Array<Promise<boolean>> = [];

        for (let i = 0; i < requestedPermissions.length; i++) {
          allPermissionCheckPromises.push(this.givenUserHasPermission(requestedPermissions[i], workspaceUser, unit));
        }

        Promise.all(allPermissionCheckPromises).then((allPermissionCheckResults) => {
          if (operation === 'AND') {
            let userHasPermission = true;

            for (let i = 0; i < allPermissionCheckResults.length; i++) {
              if (!allPermissionCheckResults[i]) {
                userHasPermission = false;
              }
            }

            resolve({
              hasPermission: userHasPermission,
              request_timestamp: requestTimestamp
            });
          } else if (operation === 'OR') {
            let userHasPermission = false;
            for (let i = 0; i < allPermissionCheckResults.length; i++) {
              if (allPermissionCheckResults[i]) {
                userHasPermission = true;
              }
            }

            resolve({
              hasPermission: userHasPermission,
              request_timestamp: requestTimestamp
            });
          }
        });
      } else {
        resolve({
          hasPermission: false,
          request_timestamp: requestTimestamp
        });
      }
    });
  }

  givenUserHasPermission(permissionName: keyof typeof Permissions, workspaceUser: WorkspaceUser, unit ?: Unit): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      let currentLoggedInUser = workspaceUser;

      if (!currentLoggedInUser.isUserActive()) {
        resolve(false);
        return;
      }

      permissionName = permissionName.toString();

      const userRole = currentLoggedInUser.getRole().toUpperCase();

      if (userRole === 'ADMIN') {
        resolve(true);
      } else if (userRole === 'OPERATOR') {
        if (typeof Permissions[permissionName] !== 'undefined' && Permissions[permissionName].includedInDefaultRoles.indexOf('OPERATOR') > -1) {
          resolve(true);
        } else {
          resolve(false);
        }
      } else {
        //custom user role
        this.getWorkspaceUserRole(userRole, true)
          .then((customUserRoleDefinition) => {
            if (
              customUserRoleDefinition &&
              typeof customUserRoleDefinition.permissions !== 'undefined' &&
              customUserRoleDefinition.permissions.length &&
              customUserRoleDefinition.permissions.indexOf(permissionName) >= 0
            ) {
              if (unit && permissionName.toString() ==='EDIT_UNITS' || permissionName.toString() ==='VIEW_UNITS' ||
                permissionName.toString() ==='RESET_UNITS' || permissionName.toString() === 'REBOOT_UNITS'||
                permissionName.toString() ==='VIEW_SCHEDULES' || permissionName.toString() ==='EDIT_SCHEDULES') {
                let UNIT_PERMISSIONS = customUserRoleDefinition.permissions.find((item): item is any => typeof item === 'object' && 'UNIT_PERMISSIONS' in item)?.UNIT_PERMISSIONS || null

                if (UNIT_PERMISSIONS) {
                  if (UNIT_PERMISSIONS === 'all') {
                    resolve(true);
                  } else {
                    if (unit  && unit.id) {
                      let unitId = unit.id
                      // check to see if Unit_Permissions contains the unitId
                      let unitHasPermission = UNIT_PERMISSIONS.filter((u) => {
                        return u.id === unitId
                      })

                      resolve(unitHasPermission.length > 0);
                    }

                  }
                }
              }
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            console.warn('getUserRole failed:', err);
            resolve(false);
          });
      }
    });
  }

  getUnitsWithUserPermissions(requestedPermissions: Array<keyof typeof Permissions>, operation: 'AND' | 'OR', units: Unit[]): Promise<Unit[]> {
    return new Promise<any>(async (resolve) => {
      let unitsUserHasPermissionTo = []
      console.log('getUnitsWithUserPermissions units', units);
      for(let i = 0; i < units.length; i++) {
        let unit = units[i]
        let hasPermission = await this.currentUserHasPermissions(requestedPermissions, operation, unit)
        if (hasPermission) {
          unitsUserHasPermissionTo.push(unit)
        }
      }
      resolve(unitsUserHasPermissionTo);
    });
  }

  // units dont seem to apply to unit groups
  // getUnitGroupsWithUserPermissions(requestedPermissions: Array<keyof typeof Permissions>, operation: 'AND' | 'OR', unitGroups: UnitGroup[]): Promise<UnitGroup[]> {
  //   return new Promise<any>(async (resolve) => {
  //     let unitGroupsUserHasPermissionTo = []
  //
  //     for (let i = 0; i < unitGroups.length; i++) {
  //       let allUnitsHavePermission = true;
  //       for (let j = 0; j < unitGroups[i].units.length; j++) {
  //         let unit = unitGroups[i].units[j]
  //         let hasPermission = await this.currentUserHasPermissions(requestedPermissions, operation, unit)
  //         if (!hasPermission) {
  //           allUnitsHavePermission = false;
  //           break
  //         }
  //       }
  //
  //       if (allUnitsHavePermission)
  //         unitGroupsUserHasPermissionTo.push(unitGroups[i])
  //     }
  //
  //     resolve(unitGroupsUserHasPermissionTo);
  //   })
  // }

  emailInvitedUser(user: WorkspaceUser) {
    let currentLoggedInUser = this.fireSessionService.currentWorkspaceUser.value;

    let ttl = new Date();
    ttl.setSeconds((ttl.getSeconds() + 60))//expire after 1m

    return this.fireDB.collection('outbound_digisign_email_queue').add({
      to: user.email,
      ttl: ttl,
      message: {
        subject: 'Invitation from ' + currentLoggedInUser.getName() + ' to DigiSign Cloud',
        html: '<div style="width:500px;max-width:100%;box-shadow: #ccc 0px 0px 7px 1px; border-radius: 3px; margin:0 auto;  font-family:sans-serif">' +
        '<br />' +
        '<p style="text-align: center;">' +
        '<img src="https://digisignportal.muxlab.com/wp-content/uploads/2022/03/cropped-Hnet.com-image.png"><br /><br />' +
          '<b>Workspace Invitation</b>' +
      '</p>' +
      '<br />' +
      '<p style="padding:20px">' +
        'Hi,<br />You\'ve been invited to ' + currentLoggedInUser.getName() + '\'s workspace <b>' + this.fireSessionService.currentWorkspace.value.name + '</b>.<br /><br />' +
    '<a style="display:inline-block;background-color:#3aa9de; font-size:20px; color:#FFF;text-transform: uppercase;padding:10px 20px;" href="https://cloud.muxlab.com/digisign/#register/' + user.email + '">Register</a> or <a style="display:inline-block;background-color:#3aa9de; font-size:20px; color:#FFF;text-transform: uppercase;padding:10px 20px;" href="https://cloud.muxlab.com/digisign/#login/">login</a> to get started!<br /><br />- The DigiSign Cloud Team,<br />MuxLab Inc,'+
    '</p>'+
    '</div>',
      },
    })
  }
}
