import {FieldValue} from "@angular/fire/firestore";
import {Schedule} from "./Schedule";

export type MeteredCurrentUsage = {
  totalStorageUsed?: number | FieldValue;
  totalStorageAllowed?: number;
  totalBandwidthAllowed?: number;
  //bandwidth used is stored in /meteredUsage/month-name/ in firestore. see MeteredMonthlyUsage.ts
};

export function isMeteredCurrentUsage(obj: any): obj is MeteredCurrentUsage {
  return !!obj && (
    (obj as MeteredCurrentUsage).totalStorageUsed !== undefined ||
    (obj as MeteredCurrentUsage).totalStorageAllowed !== undefined ||
    (obj as MeteredCurrentUsage).totalBandwidthAllowed !== undefined
  );
}
