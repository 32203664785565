import { getTemplateAssetDirectory, Template } from '../../app/types/Template';

export const BlankTemplate: Template = {
  isDefaultTemplate: true,
  defaultTemplateKeywords: [],
  id: 'default-blank-1',
  renderedHTML: undefined,
  name: 'New / Blank',
  durationInSeconds: 30,
  highest_zIndex: 0,
  assets: [],
  styles: [],
  components: [{}],
  html: '',
  css: '',
  thumbnail: getTemplateAssetDirectory() + '/thumbnail.png',
};
