import {Directive, ElementRef, Input, OnChanges, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import { Permissions } from '../types/Permissions';
import {WorkspaceUsersService} from "../services/workspace-users.service";
import {WorkspaceUser} from "../types/WorkspaceUser";
import {Unit} from "../types/Unit";

@Directive({
  selector: '[userHasPermission]',
})
export class UserHasPermissionDirective implements OnInit {

  private requestedPermissions: Array<keyof typeof Permissions> = [];
  private operation: 'AND' | 'OR' = 'AND';
  private unit: Unit
  /**
   * workspaceUser was added here otherwise on workspace change, some directives don't get updated
   * Also Angular calls a rerendering of the directives at different times.
   * An issue that arose was when changing workspaces, the directive would get refreshed just before the workspace changed
   * and righ after. Since this directive will fetch role info from the cloud, in some cases, the first requests with the old
   * role and workspace would finish after the newer requests, leading to the wrong behavior.
   */
  private workspaceUser: WorkspaceUser;
  private lastRequestTimestamp:number;

  private embeddedViewRef = null;//to keep track of whether the view has been rendered already or not

  @Input()
  set userHasPermission(val: Array<keyof typeof Permissions>) {
    this.requestedPermissions = val;
    this.updateView();
  }

  @Input()
  set userHasPermissionOperation(op) {
    this.operation = op;
    this.updateView();
  }

  @Input()
  set userHasPermissionWorkspaceUser(user) {
    this.workspaceUser = user;
    this.updateView();

    /*if (!this.workspaceUser) {
      this.workspaceUser = user;
      this.updateView();

    } else {
      let oldWorkspaceUser = this.workspaceUser;
      this.workspaceUser = user;

      if (this.workspaceUser && typeof this.workspaceUser.workspace_id !== 'undefined'
        && this.workspaceUser.workspace_id != oldWorkspaceUser.workspace_id
        && this.workspaceUser.role.toUpperCase() != oldWorkspaceUser.role.toUpperCase()) {
        this.updateView();
      }
    }*/
  }
  @Input()

  set userHasPermissionUnit(unit) {
    this.unit = unit;
    this.updateView();
  }

  constructor(
    private element: ElementRef,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private WorkspaceUsersService: WorkspaceUsersService,
  ) {}

  ngOnInit() {
    this.updateView();
  }

  private updateView() {

    if (!this.workspaceUser) {
      this.workspaceUser = this.WorkspaceUsersService.getCurrentLoggedInUser();
    }

    if (!this.operation || !this.requestedPermissions.length) {
      this.viewContainer.clear();
      this.embeddedViewRef = null;
    } else {

      this.lastRequestTimestamp = Date.now();

      this.WorkspaceUsersService.givenUserHasPermissions(this.requestedPermissions, this.operation, this.workspaceUser, this.lastRequestTimestamp, this.unit).then((userPermission) => {
        if (userPermission.request_timestamp !== this.lastRequestTimestamp) {//to prevent slower but older requests when they resolve after more recent requests
          return;
        }
        if (userPermission.hasPermission === true) {
          if (!this.embeddedViewRef) {
            this.viewContainer.clear();
            this.embeddedViewRef = this.viewContainer.createEmbeddedView(this.templateRef);
            if (this.element && this.element.nativeElement && this.element.nativeElement.style) {
              this.element.nativeElement.style.display = 'none !important';
              this.element.nativeElement.style.opacity = '0 !important';
            }
          }
        } else {
          this.viewContainer.clear();
          this.embeddedViewRef = null;
          if (this.element && this.element.nativeElement && this.element.nativeElement.style) {
            this.element.nativeElement.style.display = '';
            this.element.nativeElement.style.opacity = '';
          }
        }

        /*if (!this.viewContainer.length) {
          if (hasPermission === true) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        } else {
          if (this.element && this.element.nativeElement && this.element.nativeElement.style) {
            if (hasPermission === true) {
              this.element.nativeElement.style.display = 'none !important';
              this.element.nativeElement.style.opacity = '0 !important';
            } else {
              this.element.nativeElement.style.display = '';
              this.element.nativeElement.style.opacity = '';
            }
          }
        }*/
      });
    }
  }
}
