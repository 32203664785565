import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserHasPermissionDirective } from '../user-has-permission.directive';

@NgModule({
  declarations: [UserHasPermissionDirective],
  imports: [CommonModule],
  exports: [UserHasPermissionDirective],
})
export class CustomDirectivesModule {}
