import { Unit } from './Unit';
import { Slide } from './Slide';
import { Playlist } from './Playlist';
import {StaticInjector} from "../utils/StaticInjector";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Type} from "@angular/core";

export class UnitGroup {
  id: string;
  name: string;
  units: Unit[];
  private type = 'unitGroup';
  timezone?: string;

  constructor(name: string, id?: string) {
    if (id) {
      this.id = id;
    } else {
      this.id = StaticInjector.getInjector().get<AngularFirestore>(AngularFirestore as Type<AngularFirestore>).createId();
    }

    this.name = name;
  }

  getName(): string {
    return this.name;
  }
  setName(name: string): void {
    this.name = name;
  }

  /**
   * ATTENTION the unit objects are to be supplied to this class via setUnits. This is should be done by the API lib when instantiating this object
   */
  getUnits(): Unit[] {
    if (this.units.length) {
      return this.units;
    } else {
      return [];
    }
  }

  /**
   * the word "provide" here was used to make it more clear that unit groups don't store the unit ID's in their own table, so we aren't "Setting"
   * the id's, we're just setting the data here for convenience when in fact the group_id is stored in each unit in the units table
   * @param units
   */
  provideUnits(units: Unit[]): void {
    this.units = units;
  }

  getId(): string {
    return this.id;
  }
}

export function isUnitGroup(obj: any): obj is UnitGroup {
  return !!obj && (obj as any).type == 'unitGroup' && (obj as UnitGroup).id !== undefined && (obj as UnitGroup).name !== undefined;
}
