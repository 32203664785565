import {StaticInjector} from "../utils/StaticInjector";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Type} from "@angular/core";

export class WorkspaceUser {
  workspace_id: string = '';
  email: string = '';
  password?: string = '';
  role: string = '';
  status: 'active' | 'disabled' = 'active';
  isRemembered: boolean = false;
  passwordExpiration?: string;
  first_name?: string = '';
  last_name?: string = '';
  occupation?: string = '';
  organization?: string = '';
  phone_number?: string = '';
  state?: string = '';
  address?: string = '';
  zip?: string = '';
  country?: string = '';

  constructor(
    workspace_id: string,
    email: string,
    role: string,
    status: 'active' | 'disabled',
    passwordExpiration?: string,
    password?: string,
    isRemembered?: boolean,
    first_name?: string,
    last_name?: string,
    occupation?: string,
    organization?: string,
    phone_number?: string,
    state?: string,
    address?: string,
    zip?: string,
    country?: string,
  ) {

    this.workspace_id = workspace_id;

    this.email = email;
    if (password) {
      this.password = password;
    }
    if (isRemembered) {
      this.isRemembered = isRemembered;
    }

    this.role = role;

    if (!status) {
      status = 'active';
    }
    this.status = status;

    if (this.passwordExpiration) {
      this.passwordExpiration = passwordExpiration;
    }
  }

  setRole(role: keyof typeof WorkspaceUserRoles) {
    this.role = role;
  }

  getRole(): string {
    return this.role.toUpperCase();
  }

  isUserActive(): boolean {
    return this.status !== 'disabled';
  }

  setPassword(password: string) {
    this.password = password;
  }

  getPassword(): string {
    return this.password;
  }

  getName(): string {
    if (this.first_name || this.last_name) {
      return this.first_name + ' ' + this.last_name;
    } else {
      return this.email;
    }
  }

  getLabel(): string {
    return this.occupation || this.organization || this.role;
  }
}

export enum WorkspaceUserRoles {
  ADMIN = 'ADMIN',
  OPERATOR = 'OPERATOR',
}

export function isUser(obj: any): obj is WorkspaceUser {
  return !!obj && (obj as WorkspaceUser).email !== undefined && (obj as WorkspaceUser).role !== undefined;
}
