import { Injectable } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import {TranslateService} from "@ngx-translate/core";
import {BetterAlert, BetterAlertsService} from "../common/better-alerts/better-alerts.service";

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  toastIsPresent = false;

  constructor(private alertCtrl: AlertController, private toastCtrl: ToastController, private translate: TranslateService,
              private BetterAlertsService: BetterAlertsService) {}

  /**
   * Allows user to press enter key instead of clicking ok
   * to confirm alert prompts
   */
  static EnableEnterToConfirm() {
    const input: any = document.querySelector('ion-alert input');
    if (input) {
      input.focus();
      input.addEventListener('keyup', function (event) {
        // Number 13 is the "Enter" key on the keyboard
        if (event.keyCode === 13) {
          const okButton: any = document.querySelectorAll('ion-alert button')[1];
          console.log("document.querySelectorAll('ion-alert button')", document.querySelectorAll('ion-alert button'));
          okButton.click();
        }
      });
    }
  }

  async alert(header: string, message?: string, duration?: number, cssClass?: string) {
    console.log('alert');
    return new Promise((resolve, reject) => {
      console.log('alert2');

      const betterAlert: BetterAlert = {
        label: header || 'General Alert',
        description: message,
        duration: duration || 6000,
        cssClass: cssClass  || ''
      };
      let alert = this.BetterAlertsService.add(betterAlert);
      resolve(alert)
    })

  }

  async alertModal(header: string, message?: string, subtitle?: string, id?: string, cssClass?: string) {
    const alert = await this.alertCtrl.create({
      cssClass: cssClass,
      header: header,
      id: id,
      subHeader: subtitle,
      message: message,
      buttons: ['OK']
    });

    await alert.present();
    return alert;
  }

  confirm(header: string, message?: string, confirm?: string, cancel?: string): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.alertCtrl
          .create({
            header: header,
            message: message,
            buttons: [
              {
                text: cancel || this.translate.instant('General.Cancel'),
                handler: () => {
                  resolve(false);
                },
              },
              {
                text: confirm || this.translate.instant('General.Confirm'),
                handler: () => {
                  resolve(true);
                },
              },
            ],
          })
          .then((alert) => {
            alert.present().then(() => AlertService.EnableEnterToConfirm());
          });
    });
  }

  /**
   * Creates createToast with message and duration in milliseconds
   * @param message
   * @param duration
   */
  async createToast(message: string, duration: number = 5000, color: string = 'success', title?: string) {
    // dismisses any current instance to avoid having several instances declared at once
    this.toastCtrl
      .dismiss()
      .then((obj) => {})
      .catch(() => {})
      .finally(() => {
        this.toastCtrl
          .create({
            message: message,
            duration: duration,
            color: color,
          })
          .then((toast) => {
            toast.present();
          });
      });
  }
}
