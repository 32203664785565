import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {Slide} from "../types/Slide";
import {FireSessionService} from "./fire-session.service";
import {AngularFirestoreCollection} from "@angular/fire/compat/firestore/collection/collection";
import {Observable, Subject} from "rxjs";
import {AlertController} from "@ionic/angular";
import {TranslateService} from "@ngx-translate/core";
import {AlertService} from "./alert.service";
import {Unit} from "../types/Unit";
import {UnitQueueCommand} from "./device-communication.service";
import {isPlaylist, Playlist} from "../types/Playlist";
import {
  ContentUploadProgressUpdate,
  PlayerType,
  SlideUploadPayload
} from "./units/content-upload-show-progress-modal.service";
import {UploadTask} from "./schedules.service";
import {API789Service} from "./api-789.service";
import {UnitGroup} from "../types/UnitGroup";
import {isSchedule, Schedule} from "../types/Schedule";
import {getAppError} from "../types/AppError";
import {getIndividualUnitsFromObjectList, UnitsService} from "./units.service";
import {DebugLogService} from "./debug-log.service";

@Injectable({
  providedIn: 'root'
})
export class SlidesService {
  minSeconds: number;
  alertPopover: any;

  constructor(private fireDB: AngularFirestore,
              private fireAuth: AngularFireAuth,
              private alertCtrl: AlertController,
              private alertService: AlertService,
              private debugLogService: DebugLogService,
              private unitsService: UnitsService,
              private api_789: API789Service,
              private translate: TranslateService,
              private fireSessionService: FireSessionService) {
    this.minSeconds = 10;
  }

  getMinSeconds(): number {
    return this.minSeconds;
  }

  addSlide(slide: Slide): Subject<ContentUploadProgressUpdate> {
    slide.modified = Date.now();
    let progressUpdateSubject = new Subject<ContentUploadProgressUpdate>();

    setTimeout(() => {
      progressUpdateSubject.next({initialized: {totalRequests: 1}});

      progressUpdateSubject.next({
        progress: {
          status: 'started',
          content: slide,
          uploadTarget: { target_type: 'local' },
        },
      });

      this.fireDB.doc(this.getPathToSlideInWorkspace(slide.id)).set(this.fireSessionService.serializeObject(slide)).then(() => {
        progressUpdateSubject.next({
          result: slide,
          progress: {
            status: 'done',
            content: slide,
            uploadTarget: { target_type: 'local' },
          },
        });
        progressUpdateSubject.complete();
      }).catch((err) => {
        console.log('err', err);
        this.debugLogService.logMessage(err);
        progressUpdateSubject.next({
          result: err,
          progress: {
            status: 'failed',
            content: slide,
            uploadTarget: { target_type: 'local' },
          },
        });
        progressUpdateSubject.complete();
      });
    });

    return progressUpdateSubject;
  }

  deleteSlide(slide: Slide) {
    return this.fireDB.doc(this.getPathToSlideInWorkspace(slide.id)).delete();
  }

  getAllSlidesUnwatched():Promise<Slide[]> {
    return new Promise<Slide[]>((resolve, reject) => {
      return this.getAllSlidesCollection().get().toPromise().then(res => {
        let slides:Slide[] = [];
        for (let i = 0; i < res.docs.length; i++) {
          slides.push(this.fireSessionService.deserializeSlide(res.docs[i].data()));
        }
        resolve(slides);
      }).catch(() => {
        reject();
      });
    });
  }

  getSlide(id):Promise<Slide> {
    return this.fireDB.doc<Slide>(this.getPathToSlideInWorkspace(id)).get().toPromise().then(res => {
      if (res.data()) {
        let data = res.data();
        return Promise.resolve(this.fireSessionService.deserializeSlide(data));
      } else {
        return Promise.reject();
      }
    }).catch(err => {
      return Promise.reject();
    });
  }

  getSlidesByName(name):Promise<Slide[]> {
    return new Promise<Slide[]>((resolve, reject) => {
      return this.fireDB.collection<Slide>(this.getPathToSlidesInWorkspace(), ref => ref.where('name', '==', name)).get().toPromise().then(res => {
        let slides:Slide[] = [];
        for (let i = 0; i < res.docs.length; i++) {
          slides.push(this.fireSessionService.deserializeSlide(res.docs[i].data()));
        }
        resolve(slides);
      }).catch(() => {
        reject();
      });
    });
  }

  getAllSlidesCollection():AngularFirestoreCollection<Slide> {
    return this.fireDB.collection<Slide>(this.getPathToSlidesInWorkspace());
  }

  getPathToSlidesInWorkspace() {
    return this.fireSessionService.getPathToCurrentWorkspace() + '/slides';
  }
  getPathToSlideInWorkspace(id: string) {
    return this.fireSessionService.getPathToCurrentWorkspace() + '/slides/' + id;
  }

  getSecondsFromHMS(hms) {
    let slideDuration = hms;

    var durationStart = slideDuration.indexOf(':') - 2;
    var durationEnd = slideDuration.lastIndexOf(':') + 3;
    let durationStr = slideDuration.slice(durationStart, durationEnd);

    let time = durationStr.split(':');
    let hours = +time[0];
    let minutes = +time[1];
    let seconds = +time[2];

    let totalSeconds = seconds + minutes * 60 + hours * 60 * 60;

    return totalSeconds;
  }

  promptUserForUniqueSlideName() {
    return new Promise((resolve, reject) => {
      this.alertCtrl
        .create({
          header: this.translate.instant('Slides.NameYourSlide'),
          inputs: [
            {
              name: 'slideName',
              type: 'text',
              value: '',
              placeholder: this.translate.instant('Slides.EditSlide.SlideName'),
            },
          ],
          buttons: [
            {
              text: this.translate.instant('General.Cancel'),
              role: 'cancel',
              cssClass: 'secondary',
              handler: () => {
                reject('cancel');
                this.alertPopover.dismiss('cancel');
              },
            },
            {
              text: this.translate.instant('General.Ok'),
              handler: (input) => {
                // if input empty
                if (input.slideName === '' || input.slideName === null || input.slideName === undefined) {
                  this.alertService.createToast(this.translate.instant('Common.YouMustSpecifyAName'), undefined, 'danger');
                  return false;
                } else {
                  // check if slide exists
                  this.getSlidesByName(input.slideName).then(async (resp) => {
                    if (resp !== undefined && resp !== null && resp.length > 0) {
                      var nametakenAlert = await this.alertService.alertModal(
                        this.translate.instant('General.Error') + ',' + this.translate.instant('Common.NameAlreadyTaken'),
                        this.translate.instant('Common.PleaseUseADifferentName')
                      );
                      nametakenAlert.onDidDismiss().then(() => {
                        reject('exists');
                        return false;
                      });
                    }
                    // on success
                    else {
                      this.alertPopover.dismiss();
                      resolve(input.slideName);
                      /*
                    this.alertPopover.onDidDismiss().then(() => {
                      resolve(input.slideName);
                    });
                    */
                    }
                  });
                }
              },
            },
          ],
        })
        .then((alert) => {
          this.alertPopover = alert;
          this.alertPopover.present().then(() => AlertService.EnableEnterToConfirm());
        });
    });
  }

  addSlideToPlayer(slide: Slide, playerType: PlayerType): Observable<ContentUploadProgressUpdate> {
    return this.addSlidesToPlayer(playerType, [slide]);
  }

  addSlidesToPlayer(playerType: PlayerType, slides: Slide[]): Observable<ContentUploadProgressUpdate> {
    let progressObservable = new Subject<ContentUploadProgressUpdate>();
    let units: Unit[] = []; //all the units to push content to

    getIndividualUnitsFromObjectList([playerType])
      .then((individualUnits) => {
        units = individualUnits;

        let totalRequests = units.length * slides.length;
        progressObservable.next({ initialized: { totalRequests: totalRequests } });
        return units;
      })
      .then((result) => {

        return new Promise((resolve) => {
          this.publishSlidesToUnits(units, false, slides).subscribe(
            (progress) => {
              //when each upload has news (started, completed)
              progressObservable.next(progress);
            },
            (err) => {
              progressObservable.next(err);
            },
            () => {
              resolve(true);
            }
          );
        });
      })
      .then((result) => {
        progressObservable.complete(); //when all uploads are done
      });

    return progressObservable;
  }

  publishSlidesToUnits(units: Unit[], update_slide_where_exists: boolean = false, slides: Slide[]): Observable<ContentUploadProgressUpdate> {
    let progressObservable = new Subject<ContentUploadProgressUpdate>();

    setTimeout(() => {

      if (!units || !units.length) {
        units = [];
      }

      let uploadTasks = []
      for (let i = 0; i < units.length; i++) {
        for (let j = 0; j < slides.length; j++) {
          let uploadTask: UploadTask = { unit: units[i], uploadMode: (update_slide_where_exists ? 'downloadFromCloud' : 'downloadFromCloudIfExists'), content: slides[j] };
          uploadTasks.push(uploadTask);
        }
      }

      if (!units.length) {
        progressObservable.complete(); //when all uploads are done
      } else {
        Observable.from(uploadTasks)
          .mergeMap((uploadTask) => this.api_789.executeUploadContentToUnit(uploadTask), 4)
          .subscribe(
            (progress) => {
              //when each upload has news (started, completed)
              progressObservable.next(progress);
            },
            (err) => {
              progressObservable.next(err);
            },
            () => {
              progressObservable.complete(); //when all uploads are done
            }
          );
      }
    });

    return progressObservable;
  }



  updateSlide(slide: Slide): Observable<ContentUploadProgressUpdate> {
    slide.modified = Date.now();
    let progressUpdateSubject = new Subject<ContentUploadProgressUpdate>();

    let totalRequests = 1; //for local storage is at least 1

    let unitsHavingSlide: Unit[] = [];

    this.getUnitsHavingSlide(slide.id).then((units) => {
      console.log('getUnitsHavingSlide()', units);

      if (units && units.length) {
        unitsHavingSlide = units;
      }
      totalRequests += unitsHavingSlide.length;
      progressUpdateSubject.next({ initialized: { totalRequests: totalRequests } });

      this.fireDB.doc(this.getPathToSlideInWorkspace(slide.id)).set(this.fireSessionService.serializeObject(slide))
        .then((res) => {

          progressUpdateSubject.next({
            progress: {
              status: 'done',
              uploadTarget: { target_type: 'local' },
              content: slide,
              message: 'Saved successfully',
            },
          });
          //upload to units if necessary

          new Promise((resolveUnitsUploaded) => {
            //upload slide to any units necessary, if necessary
            if (unitsHavingSlide.length) {
              this.publishSlidesToUnits(units, true, [slide]).subscribe(
                (progress) => {
                  //when each upload has news (started, completed)
                  progressUpdateSubject.next(progress);
                },
                (err) => {
                  console.log('publishSlidesToUnits err', err);
                  progressUpdateSubject.next(err);
                  resolveUnitsUploaded(err);

                },
                () => {
                  console.log('publishSlidesToUnits complete');
                  resolveUnitsUploaded(true);
                }
              );
            } else {
              //no units to upload to
              resolveUnitsUploaded(true);
            }
          }).then((res) => {
            progressUpdateSubject.next(res);
            progressUpdateSubject.complete();

          });
        })
        .catch((e) => {
          progressUpdateSubject.next({
            progress: {
              status: 'failed',
              uploadTarget: { target_type: 'local' },
              content: slide,
              message: 'Failed to save',
            },
            result: null,
          });
          progressUpdateSubject.complete();
        });
    }).catch((e) => {
      console.log('Failed at getUnitsHavingSlide: ', e)
    });

    return progressUpdateSubject;
  }


  getUnitsHavingSlide(slide_id: string): Promise<Unit[]> {
    return new Promise<Unit[]>((resolveUnitsHavingSlide) => {
      this.unitsService.getAllUnitsUnwatched()
        .then((units) => {
          let allUnitHasSlidePromises = [];
          for (let i = 0; i < units.length; i++) {
            allUnitHasSlidePromises.push(
              new Promise((resolveUnitHasSlide) => {
                if (typeof units[i].is_disconnected !== 'undefined' && units[i].is_disconnected) {
                  resolveUnitHasSlide(false);
                } else {
                  this.api_789
                    .getSlide(units[i], slide_id)
                    .then((resSlideExists: UnitQueueCommand) => {
                      if (resSlideExists && resSlideExists.status === 'success') {
                        resolveUnitHasSlide(units[i]);
                      } else {
                        resolveUnitHasSlide(false);
                      }
                    })
                    .catch((res) => {
                      resolveUnitHasSlide(false);
                    });
                }

              })
            );
          }

          Promise.all(allUnitHasSlidePromises)
            .then((results) => {
              let unitsHavingSlide = [];
              for (let i = 0; i < results.length; i++) {
                if (results[i] && results[i].id) {
                  unitsHavingSlide.push(results[i]);
                }
              }

              resolveUnitsHavingSlide(unitsHavingSlide);
            })
            .catch((e) => {
              console.log(e);
              resolveUnitsHavingSlide([]);
            });
        })
        .catch((e) => {
          console.log(e);
          resolveUnitsHavingSlide([]);
        });
    });
  }
}
