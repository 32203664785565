import {Component, ElementRef, OnInit, SecurityContext, ViewChild} from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";
import {EditorService} from "../../../services/editor/editor.service";
import {TranslateService} from "@ngx-translate/core";
import {DebugLogService} from "../../../services/debug-log.service";
import {DomSanitizer} from "@angular/platform-browser";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {FireSessionService} from "../../../services/fire-session.service";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-slide-preview',
  templateUrl: './slide-preview.component.html',
  styleUrls: ['./slide-preview.component.scss'],
})
export class SlidePreviewComponent implements OnInit {

  @ViewChild('iframePreview', {static: false}) iframePreview: ElementRef;

  editor:any;
  slideData:any;
  html;
  css;
  htmlDocument;
  dimensions;
  iframeURL;

  constructor(private navParams: NavParams,
              private editorService: EditorService,
              private debugLogService: DebugLogService,
              private translateService: TranslateService,
              private fireStorage: AngularFireStorage,
              private fireSession: FireSessionService,
              private sanitizer: DomSanitizer,
              private modalController: ModalController) { }

  ngOnInit() {
    if (this.navParams.get('editor')) {
      this.editor = this.navParams.get('editor');
      this.slideData = this.navParams.get('slideData');
      this.html = this.navParams.get('html');
      this.css = this.navParams.get('css');

      this.editorService.generateSlideRenderedHTML(
        this.html,
        this.css,
        this.slideData,
        {
          target: this.translateService.instant('Slides.Slide').toUpperCase(),
          target_id: (this.slideData.id ? this.slideData.id : undefined),
        },
        'preview'
      ).then(html => {

        if (typeof this.slideData.dimensions !== 'undefined') {
          this.dimensions = {
            x: this.slideData.dimensions[0],
            y: this.slideData.dimensions[1]
          };

        }


        let safeHTML = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(html));

        const filePath = this.fireSession.currentWorkspace.value.id + '/previewFile.html';
        const fileRef = this.fireStorage.ref(filePath);
        const task = fileRef.putString(html, undefined, {
          contentType: 'text/html',
          contentEncoding: 'utf8',
          customMetadata: {
            workspaceID: this.fireSession.currentWorkspace.value.id
          }
        });

        task.then(res => {
          console.log('fileRef.getDownloadURL()', fileRef.getDownloadURL().toPromise().then(resUploadedFilePath => {
            console.log('resUploadedFilePath', resUploadedFilePath);
            //this.iframeURL = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(resUploadedFilePath));
            this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(resUploadedFilePath);
          }));
        });


        /*this.htmlDocument = this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(html)) ;
        console.log('this.iframePreview', this.iframePreview);
        let doc = this.iframePreview.nativeElement.contentDocument || this.iframePreview.nativeElement.contentWindow.document;
        doc.body.innerHTML = this.htmlDocument;*/
      }).catch(err => {
        console.log('err', err);
        this.debugLogService.logMessage(err.toString());
      });
    }
  }

  dismissModal() {
    this.modalController.dismiss();
  }
}
