import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidebarManagerService {
  sidebarShouldBeDisabled = new BehaviorSubject<boolean>(true);

  constructor() {}

  disableSidebar() {
    this.sidebarShouldBeDisabled.next(true);
  }

  enableSidebar() {
    this.sidebarShouldBeDisabled.next(false);
  }

  listenToSidebarStatusChanges() {
    // console.log('listenToSidebarStatusChanges', this.sidebarShouldBeDisabled.asObservable());
    return this.sidebarShouldBeDisabled.asObservable();
  }
}
