import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {FireSessionService} from "./fire-session.service";
import {Unit} from "../types/Unit";
import {UnitGroup} from "../types/UnitGroup";
import {WorkspaceUser} from "../types/WorkspaceUser";
import {Slide} from "../types/Slide";
import {Playlist} from "../types/Playlist";
import {Template} from "../types/Template";
import {Schedule} from "../types/Schedule";

export const MAX_TIMEOUT = 45000;//45sec max timeout

@Injectable({
  providedIn: 'root'
})
export class DeviceCommunicationService {

  constructor(private fireDB: AngularFirestore, private fireAuth: AngularFireAuth, private fireSessionService: FireSessionService) {
  }

  sendCommand(unit: Unit, command: string, execution: 'immediate' | 'queued', payload?: any, timeout?: number, deleteOnTimeout: boolean = false, ttl?:Date):Promise<UnitQueueCommand> {
    if (!ttl) {
      ttl = new Date();
      ttl.setHours((ttl.getHours() + 720))//expire after 30 days
    }

    let unitQueueCommand:UnitQueueCommand = {
      command_id: this.fireDB.createId(),
      unit_id: unit.id,
      command_name: command,
      payload: payload || {},
      timestamp: Date.now(),
      user_email: this.fireSessionService.currentWorkspaceUser.value.email,
      user_name: this.fireSessionService.currentWorkspaceUser.value.getName(),
      status: 'pending',
      execution: execution,
      ttl: ttl
    };
    return new Promise<UnitQueueCommand>((resolve, reject) => {
      //send command
      //listen for completion of it
      //check response, on success, resolve
        //on error, reject
      //unsubscribe from changes
      let commandDoc = this.fireDB.doc<UnitQueueCommand>(this.getPathToUnitCommandQueue(unit) + '/' + unitQueueCommand.command_id);
      commandDoc.set(unitQueueCommand).then(() => {
        //let commandDoc = this.fireDB.doc<UnitQueueCommand>(this.getPathToUnitCommandQueue(unit) + '/' + unitQueueCommand.command_id);
        let subscription = commandDoc.valueChanges().subscribe((res) => {
          if (res) {
            if (res.status == 'success' || res.status == 'error') {
              subscription.unsubscribe();
              commandDoc.delete();
            }

            if (res.status == 'success') {
              resolve(res);
            } else if (res.status == 'error') {
              reject(res);
            }
          }
        });

        setTimeout(() => {//MAX TIMEOUT
          subscription.unsubscribe();
          if (deleteOnTimeout) {
            commandDoc.delete();
          }
          reject(undefined);
          }, timeout ? timeout : MAX_TIMEOUT);
      }).catch(() => {
        reject('failed to send command');
        commandDoc.delete();
      });
    });

  }

  getUnitCommandsQueue() {

  }

  getPathToUnitCommandQueue(unit: Unit) {
    return this.fireSessionService.getPathToCurrentWorkspace() + '/units/' + unit.id + '/commandQueue';
  }
}

export interface UnitQueueCommand {
  command_id: string,
  unit_id: string,
  command_name: string,
  payload: any,
  timestamp: number,
  user_email: string,
  user_name: string,
  status: 'success' | 'error' | 'pending' | 'in_progress',
  status_message?: string,
  execution: 'immediate' | 'queued',
  ttl?: Date;
}
export function isUnitQueueCommand(obj: any): obj is UnitQueueCommand {
  return !!obj && typeof (obj as any).command_id != 'undefined';
}
