import { Injectable } from '@angular/core';
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {FireSessionService} from "./fire-session.service";
import {Workspace} from "../types/Workspace";
import {Unit} from "../types/Unit";
import {AngularFirestoreCollection} from "@angular/fire/compat/firestore/collection/collection";
import {PairingInfo} from "../types/PairingInfo";
import {UnitGroup} from "../types/UnitGroup";
import {PlayerType} from "./units/content-upload-show-progress-modal.service";
import {API789Service} from "./api-789.service";
import {DebugLogService} from "./debug-log.service";
import {AlertService} from "./alert.service";
import {TranslateService} from "@ngx-translate/core";
import {DistributorLicenseKey} from "../types/DistributorLicenseKey";

@Injectable({
  providedIn: 'root'
})
export class UnitsService {

  constructor(private fireDB: AngularFirestore,
              private api_789: API789Service,
              private fireAuth: AngularFireAuth,
              private alertService: AlertService,
              private debugLogService: DebugLogService,
              private translateService: TranslateService,
              private fireSessionService: FireSessionService) {
  }

  updatePartial(unit: Unit, partialFields: any) {
    return this.fireDB.doc(this.getPathToUnitInWorkspace(unit)).update(partialFields);
  }

  saveUnit(unit: Unit) {
    return this.fireDB.doc(this.getPathToUnitInWorkspace(unit)).set(this.fireSessionService.serializeObject(unit));
  }

  getAllUnitsUnwatched():Promise<Unit[]> {
    return new Promise<Unit[]>((resolve, reject) => {
      return this.getAllUnitsCollection().get().toPromise().then(res => {
        let units:Unit[] = [];
        for (let i = 0; i < res.docs.length; i++) {
          units.push(this.fireSessionService.deserializeUnit(res.docs[i].data()));
        }
        resolve(units);
      }).catch(() => {
        reject();
      });
    });
  }

  getAllUnitsCollection():AngularFirestoreCollection<Unit> {
    return this.fireDB.collection<Unit>(this.getPathToUnitsInWorkspace());
  }

  getUnit(id):Promise<Unit> {
    return this.fireDB.doc<Unit>(this.getPathToUnitInWorkspaceById(id)).get().toPromise().then(res => {
      if (res.data()) {
        let data = res.data();
        return Promise.resolve(this.fireSessionService.deserializeUnit(data));
      } else {
        return Promise.reject();
      }
    }).catch(err => {
      return Promise.reject();
    });
  }

  getUnitsInGroupUnwatched(group_id: string):Promise<Unit[]> {
    return this.fireDB.collection<Unit>(this.getPathToUnitsInWorkspace(), ref => ref.where('group_id', '==', group_id)).get().toPromise().then(resUnits => {
      let unitsInGroup:Unit[] = [];

      for (let i = 0; i < resUnits.docs.length; i++) {
        unitsInGroup.push(this.fireSessionService.deserializeUnit(resUnits.docs[i].data()));
      }

      return unitsInGroup;
    }).catch(() => {return undefined;});
  }

  unpairUnit(unit: Unit) {
    if (unit.is_disconnected) {
      return this.fireDB.doc(this.getPathToUnitInWorkspaceById(unit.id)).delete();
    } else {
      return this.api_789.unpairUnit(unit).then(res => {
      }).catch(err => {

        console.log('err', err);
        try {
          this.debugLogService.logMessage(err.toString());
        } catch (e) {}

      }).finally(() => {
        return this.fireDB.doc(this.getPathToUnitInWorkspaceById(unit.id)).delete();
      });
    }
  }

  deleteUnit(id: string) {
    return this.fireDB.doc(this.getPathToUnitInWorkspaceById(id)).delete();
  }

  getPathToUnitsInWorkspace() {
    return this.fireSessionService.getPathToCurrentWorkspace() + '/units';
  }

  getUnitInfo(unitId: string) {
    return this.fireDB.doc(this.getPathToUnitInWorkspaceById(unitId)).get().toPromise().then(res => {
      let resData:any = res.data();
      if (resData && typeof resData.unitInfo !== 'undefined') {
        return Promise.resolve(resData.unitInfo);
      } else {
        return Promise.reject();
      }
    }).catch(err => {
      return Promise.reject(err);
    });
  }

  getUnitPreview(unitId: string) {
    return this.fireDB.doc(this.getPathToUnitInWorkspaceById(unitId)).get().toPromise().then(res => {
      let resData:any = res.data();
      if (resData && typeof resData.unitPreview !== 'undefined') {
        return Promise.resolve(resData.unitPreview.data);
      } else {
        return Promise.reject();
      }
    }).catch(err => {
      return Promise.reject(err);
    });
  }


  getPathToUnitInWorkspace(unit: Unit) {
    return this.fireSessionService.getPathToCurrentWorkspace() + '/units/' + unit.id;
  }
  getPathToUnitInWorkspaceById(id: string) {
    return this.fireSessionService.getPathToCurrentWorkspace() + '/units/' + id;
  }

  pairUnit(pairingCode: string) {
    return new Promise<boolean | string>((resolve, reject) => {
      //look for the pairing code
      let pairingInfoDoc = this.fireDB.doc<PairingInfo>('/unitPairingCodes/' + pairingCode);
      pairingInfoDoc.get().toPromise().then(res => {
        if (res && res.data()) {
          let pairingInfo = res.data();
          if (pairingInfo && typeof pairingInfo.unit_id !== 'undefined') {//get the unit ID
            let unit = new Unit(pairingInfo.unit_id, '', '', '');
            pairingInfo.workspacePairedTo = this.fireSessionService.currentWorkspace.value.id;

            //add it to the workspace
            this.fireDB.doc(this.getPathToUnitInWorkspaceById(pairingInfo.unit_id)).set(this.fireSessionService.serializeObject(unit)).then(() => {
              //update the pairing info so that the unit can listen to it and start acting inside this workspace
              let subscription = pairingInfoDoc.valueChanges().subscribe((val) => {
                if (typeof val.pairingComplete !== 'undefined' && val.pairingComplete) {
                  resolve(pairingInfo.unit_id);
                  subscription.unsubscribe();
                }
              }, (err) => {
                reject(err);
              });

              pairingInfoDoc.set(pairingInfo);

            }).catch(err => {//unable to save new unit to workspace
              reject(err);
            });

          } else {//invalid pairing document
            reject();
          }

        } else {//pairing document not found
          reject();
        }
      }).catch(err => {//pairing document not found
        reject(err);
      });

    });
  }

  setGroupToUnits(unitIDs: string[], group: UnitGroup) {
    return this.fireDB.collection(this.fireSessionService.getPathToCurrentWorkspace() + '/units', ref => ref.where('id', 'in', unitIDs)).get().toPromise().then(resUnits => {
      for (let i = 0; i < resUnits.docs.length; i++) {
        resUnits.docs[i].ref.update({group_id: group.id});
      }
    });
  }

  doHeartbeatAllUnits() {
    this.getAllUnitsUnwatched().then(resUnits => {
      resUnits.forEach(unit => {this.api_789.doHeartbeat(unit);});
    }).catch(err => {
      this.debugLogService.logMessage(err.toString());
    });
  }


  clearUnitCommandsQueue(unit:Unit) {
    return this.fireDB.collection(this.fireSessionService.getPathToCurrentWorkspace() + '/units/' + unit.id + '/commandQueue').get().toPromise().then(res => {
      if (res.size < 500) {
        res.forEach((command) => {
          command.ref.delete();
        });
      } else {
        Promise.reject('Too many commands, please contact support.')
      }
    })
  }

  factoryResetUnit(unit: Unit): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      this.alertService
        .confirm(
          this.translateService.instant('Units.FactoryReset.FactoryResetTitle'),
          this.translateService.instant('Units.FactoryReset.FactoryResetConfirmationText'),
          this.translateService.instant('Units.FactoryReset.FactoryResetButtonLabel')
        )
        .then((resResetConfirm) => {
          if (resResetConfirm) {
            this.api_789.doFactoryReset(unit).then(() => {

              setTimeout(() => {
                this.api_789.doReboot(unit);
              }, 1000);

            }).catch((e) => {
                this.alertService.createToast(this.translateService.instant('Units.FactoryReset.FactoryResetFailure'), undefined, 'danger');
                resolve(false);

            }).finally(async () => {
              this.deleteUnit(unit.id);
            });
          }
        });
    });
  }

  checkLicenseKeyValidity(licenseKey: string): Promise<'invalid' | 'used' | 'available'> {
    return new Promise<'invalid' | 'used' | 'available'>((resolve) => {
      console.log('getPathToDistributorLicenseKey(licenseKey)', getPathToDistributorLicenseKey(licenseKey));
      this.fireDB.doc<DistributorLicenseKey>(getPathToDistributorLicenseKey(licenseKey)).get().toPromise().then(res => {
        console.log('res', res);
        console.log('res.data()', res.data());
        if (res && res.data()) {
          let licenseData = res.data();
          if (typeof licenseData.status !== 'undefined' && licenseData.status === 'used') {
            resolve('used');
          } else {
            resolve('available');
          }
        } else {
          resolve('invalid');
        }
      }).catch(err => {
        console.log('err', err);
        resolve('invalid');
      });
    });
  }

  applyLicenseKeysToWorkspace(licenseKeys: string[]):Promise<any> {
    let allPromises = [];
    for (let i = 0; i < licenseKeys.length; i++) {
      let licenseKeyDoc = this.fireDB.doc(getPathToDistributorLicenseKey(licenseKeys[i]));
      allPromises.push(this.fireDB.doc(getPathToDistributorLicenseKey(licenseKeys[i])).update({
        status: 'used',
        redeemedByEmail: this.fireSessionService.currentFireUser.email,
        redeemDate: Date.now(),
        redeemedInWorkspaceID: this.fireSessionService.currentWorkspace.value.id,
        redeemedInWorkspaceOwnerEmail: this.fireSessionService.currentWorkspace.value.owner_email
      }));
    }

    return Promise.all(allPromises);
  }

  getLicenseKeysAppliedToWorkspace(): Promise<DistributorLicenseKey[]> {
    return this.fireDB.collection<DistributorLicenseKey>(getPathToDistributorLicenseKeys(),
      ref => ref.where('redeemedInWorkspaceID', '==', this.fireSessionService.currentWorkspace.value.id)).get().toPromise().then((snapshot) => {
        const licenseKeys: DistributorLicenseKey[] = [];
        snapshot.forEach((doc) => {
          licenseKeys.push(doc.data() as DistributorLicenseKey);
        });
        return licenseKeys;
      });
  }
}

export function getIndividualUnitsFromObjectList(objects: PlayerType[]): Promise<Unit[]> {
  if (!objects || !objects.length) {
    objects = [];
  }

  let units: Unit[] = []; //all the units to push content to

  for (let i = 0; i < objects.length; i++) {
    if (objects[i].type && objects[i].type === 'unit') {
      units.push(<Unit>objects[i].object);
    } else if (objects[i].type && objects[i].type === 'group') {
      let unitGroup = <UnitGroup>objects[i].object;
      if (unitGroup.units && unitGroup.units.length) {
        units.push(...unitGroup.units);
      }
    }
  }

  //TODO warning this was rewritten from NoCloudLib and is untested - if stuff bugs out during upload check here!!

  return Promise.resolve(units);
}

export function getPathToDistributorLicenseKeys() {
  return '/distributorLicenses';
}
export function getPathToDistributorLicenseKey(key:string) {
  return '/distributorLicenses/' + key;
}
