import { Component, OnInit } from '@angular/core';
import {ModalController, NavParams} from "@ionic/angular";

@Component({
  selector: 'app-slide-duration',
  templateUrl: './slide-duration.component.html',
  styleUrls: ['./slide-duration.component.scss'],
})
export class SlideDurationComponent implements OnInit {

  durationInSeconds: number = 30;

  hours: number;
  minutes: number;
  seconds: number;

  constructor(private navParams: NavParams,
              private modalController: ModalController,) { }

  ngOnInit() {
    if (this.navParams.get('durationInSeconds')) {
      this.durationInSeconds = this.navParams.get('durationInSeconds')
    }

    let date = new Date(this.durationInSeconds * 1000).toISOString().substring(11, 19)
    this.hours = parseInt(date.substring(0, 2));
    this.minutes = parseInt(date.substring(3, 5));
    this.seconds = parseInt(date.substring(6, 8));
  }

  dismiss() {
    let durationInSeconds = (this.hours * 60 * 60) + (this.minutes * 60) + this.seconds;
    this.modalController.dismiss(durationInSeconds);
  }

}
