import { WorkspaceUserRoles } from './WorkspaceUser';
import {Unit} from "./Unit";

export interface PermissionDetails {
  name: string;
  description: string;
  includedInDefaultRoles: (keyof typeof WorkspaceUserRoles)[];
  unitsAllowed?: Unit[] | 'all';
}

export const Permissions: { [key: string]: PermissionDetails } = {
  //slides
  CREATE_SLIDES: {
    name: 'Create Slides',
    description: 'Create new slides',
    includedInDefaultRoles: [],
  },
  EDIT_SLIDES: {
    name: 'Edit Slides',
    description: 'Edit existing slides',
    includedInDefaultRoles: [],
  },
  DELETE_SLIDES: {
    name: 'Delete Slides',
    description: 'Delete existing slides',
    includedInDefaultRoles: [],
  },
  VIEW_SLIDES: {
    name: 'View Slides',
    description: 'Open slides and see their contents',
    includedInDefaultRoles: [WorkspaceUserRoles[WorkspaceUserRoles.OPERATOR.toUpperCase()]],
  },

  //schedules
  EDIT_SCHEDULES: {
    name: 'Edit Schedules',
    description: 'Add slides and playlists to be scheduled playing',
    includedInDefaultRoles: [],
  },
  VIEW_SCHEDULES: {
    name: 'View Schedules',
    description: 'View the existing schedules',
    includedInDefaultRoles: [WorkspaceUserRoles[WorkspaceUserRoles.OPERATOR.toUpperCase()]],
  },

  //units
  UNIT_PERMISSIONS: {
    name: 'Unit Permissions',
    description: 'Permissions related to units',
    includedInDefaultRoles: [],
    unitsAllowed: 'all'
  },
  CREATE_UNITS: {
    name: 'Add Units',
    description: 'Add units by autodiscovery or manually, and create unit groups',
    includedInDefaultRoles: [],
  },
  EDIT_UNITS: {
    name: 'Edit Units',
    description: 'Edit units and manage their groups',
    includedInDefaultRoles: [],
  },
  DELETE_UNITS: {
    name: 'Delete Units',
    description: 'Delete existing units or unit groups',
    includedInDefaultRoles: [],
  },
  RESET_UNITS: {
    name: 'Reset Units',
    description: 'Factory reset existing units',
    includedInDefaultRoles: [],
  },
  REBOOT_UNITS: {
    name: 'Reboot Units',
    description: 'Reboot existing units',
    includedInDefaultRoles: [],
  },
  VIEW_UNITS: {
    name: 'View Units',
    description: 'View list of units and which groups they belong to',
    includedInDefaultRoles: [WorkspaceUserRoles[WorkspaceUserRoles.OPERATOR.toUpperCase()]],
  },
};
