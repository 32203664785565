import {StaticInjector} from "../utils/StaticInjector";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Type} from "@angular/core";

export class Unit {
  id: string;
  name?: string;
  location?: string;
  ip: string;
  username: string;
  password: string;
  group_id?: string;
  auth_token?: string;
  is_disconnected?: boolean;
  dhcp_on = true;
  network_mask?: string;
  network_gateway?: string;
  dns_primary?: string;
  dns_secondary?: string;
  timezone?: string;
  api_version?: string;
  scheduler_enabled?: boolean;

  constructor(
    id: string,
    ip: string,
    username: string,
    password: string,
    name?: string,
    group_id?: string,
    dhcp_on?: boolean,
    network_mask?: string,
    network_gateway?: string,
    dns_primary?: string,
    dns_secondary?: string,
    timezone?: string,
    scheduler_enabled?: boolean,
    location?: string
  ) {

    this.id = id;

    if (name) {
      this.name = name;
    } else {
      this.name = '';
    }

    if (location) {
      this.location = location;
    } else {
      this.location = '';
    }

    if (group_id) {
      this.group_id = group_id;
    } else {
      this.group_id = '';
    }

    if (dns_primary) {
      this.dns_primary = dns_primary;
    } else {
      this.dns_primary = '';
    }

    if (dns_secondary) {
      this.dns_secondary = dns_secondary;
    } else {
      this.dns_secondary = '';
    }

    this.ip = ip;

    this.username = username;
    this.password = password;

    if (typeof dhcp_on !== 'undefined') {
      this.dhcp_on = dhcp_on;
    }

    if (typeof network_mask !== 'undefined') {
      this.network_mask = network_mask;
    }

    if (typeof network_gateway !== 'undefined') {
      this.network_gateway = network_gateway;
    }

    if (typeof timezone !== 'undefined') {
      this.timezone = timezone;
    }

    if (typeof scheduler_enabled !== 'undefined') {
      this.scheduler_enabled = scheduler_enabled;
    }
  }

  setSchedulerEnabled(scheduler_enabled: boolean) {
    this.scheduler_enabled = scheduler_enabled;
  }

  getUsername() {
    return this.username;
  }

  getPassword() {
    return this.password;
  }

  setUsername(username: string) {
    this.username = username;
  }

  setPassword(password: string) {
    this.password = password;
  }

  getIP() {
    return this.ip;
  }

  getName() {
    return this.name;
  }

  getLocation() {
    return this.location;
  }

  getID() {
    return this.id;
  }

  getGroupId() {
    return this.group_id;
  }

  setName(name: string) {
    this.name = name;
  }

  setLocation(location: string) {
    this.location = location;
  }

  setGroupId(id: string) {
    this.group_id = id;
  }

  setIP(ip: string) {
    this.ip = ip;
  }

  getAuthToken() {
    return this.auth_token;
  }

  setAuthToken(auth_token) {
    this.auth_token = auth_token;
  }
}

export function isUnit(obj: any): obj is Unit {
  return !!obj && (obj as Unit).ip !== undefined && (obj as Unit).id !== undefined;
}

export function parseUnitIdFromDiscoveryName(discoveryName: string): string {
  return discoveryName.split('-').pop().replace(/\s/g, '');
}

export const UNIT_ETHERNET_INTERFACENAME = 'Ethernet 2';

export function isoDatetimeToString(isoDatetime, timezone?: string) {
  let unitDateAndTime = new Date(isoDatetime);
  if (timezone) {
    unitDateAndTime = new Date(unitDateAndTime.toLocaleString('en-US', { timeZone: timezone }));
  }
  let unitDateAndTimeString =
    (<any>unitDateAndTime).toLocaleString('default', { month: 'long' }) +
    ' ' +
    unitDateAndTime.getDate() +
    ' ' +
    unitDateAndTime.getFullYear() +
    ' ' +
    unitDateAndTime.getHours().toString().padStart(2, '0') +
    ':' +
    unitDateAndTime.getMinutes().toString().padStart(2, '0') +
    ':' +
    unitDateAndTime.getSeconds().toString().padStart(2, '0');

  return unitDateAndTimeString;
}
