import { Component, OnInit } from '@angular/core';
import {Unit} from "../../../types/Unit";
import {ModalController, NavParams} from "@ionic/angular";
import {API789Service} from "../../api-789.service";
import {Subscription} from "rxjs";
import {UnitsService} from "../../units.service";

@Component({
  selector: 'app-unit-preview',
  templateUrl: './unit-preview.component.html',
  styleUrls: ['./unit-preview.component.scss'],
})
export class UnitPreviewComponent implements OnInit {

  unit: Unit;
  refreshing = true;
  image: any;

  constructor(private navParams: NavParams,
              private api_789: API789Service,
              private unitsService: UnitsService,
              private modalController: ModalController
              ) { }

  ngOnInit() {}

  ionViewWillEnter() {
    if (this.navParams.get('unit')) {
      this.unit = this.navParams.get('unit');
    }

    this.refreshScreenshot();
  }

  refreshScreenshot() {
    this.refreshing = true;
    this.api_789.publishScreenshot(this.unit).then(() => {
      this.unitsService.getUnitPreview(this.unit.id).then((res:any) => {
        if (res) {
          this.image = res
          this.refreshing = false;
        }
      });
    });
  }

  dismissModal() {
    this.modalController.dismiss();
  }

}
