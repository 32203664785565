import { Injectable } from '@angular/core';
import { isPlaylist, Playlist } from '../../types/Playlist';
import { isSlide, Slide } from '../../types/Slide';
import { PlayableContentSelectorModalComponent } from './playable-content-selector-modal/playable-content-selector-modal/playable-content-selector-modal.component';
import { ModalController } from '@ionic/angular';
import { type } from 'os';

@Injectable({
  providedIn: 'root',
})
export class PlayableContentSelectorModalService {
  constructor(private modalController: ModalController) {}

  showSelectPlayableContentModal(slidesOnly?: boolean): Promise<Slide | Playlist> {
    return new Promise<Slide | Playlist>((resolve, reject) => {
      this.modalController
        .create({
          component: PlayableContentSelectorModalComponent,
          cssClass: 'select-playable-content-modal',
          animated: false,
          componentProps: {
            slidesOnly: slidesOnly && slidesOnly === true,
          },
        })
        .then((modal) => {
          modal.onDidDismiss().then((result) => {
            if (result && result.data && result.data.item) {
              const item = result.data.item;
              if (isSlide(item)) {
                resolve(<Slide>item);
              } else if (isPlaylist(item)) {
                resolve(<Playlist>item);
              } else {
                resolve(null);
              }
            } else {
              resolve(null);
            }
          });
          modal.present();
        });
    });
  }
}
