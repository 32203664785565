import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import {WorkspacesService} from "../services/workspaces.service";

@Injectable({
  providedIn: 'root'
})
export class WorkspaceBillingInGoodStandingGuard implements CanActivate {

  constructor(private workspacesService: WorkspacesService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    let currentWorkspace = this.workspacesService.getCurrentWorkspace();

    //check if billingInGoodStanding is defined - if it's undefined then it means it's in good standing. Only serves as a flag if set and set to false
    if (currentWorkspace && typeof currentWorkspace.billingInGoodStanding !== 'undefined' && currentWorkspace.billingInGoodStanding === false) {
      this.workspacesService.redirectToBadBillingPage();
      return false;
    } else if (currentWorkspace) {
      return true;
    }

    return false;
  }

}

