import { Injectable } from '@angular/core';
import {User} from "@angular/fire/auth/firebase";
import {BehaviorSubject} from "rxjs";
import {Workspace} from "../types/Workspace";
import {WorkspaceUser} from "../types/WorkspaceUser";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {AngularFireAuth} from "@angular/fire/compat/auth";
import {SidebarManagerService} from "./sidebar/sidebar-manager.service";
import {Unit} from "../types/Unit";
import {UnitGroup} from "../types/UnitGroup";
import {Schedule} from "../types/Schedule";
import {Slide} from "../types/Slide";
import {Playlist} from "../types/Playlist";
import {Template} from "../types/Template";

@Injectable({
  providedIn: 'root'
})
export class FireSessionService {

  currentFireUser: User;
  currentWorkspace = new BehaviorSubject<Workspace>(undefined);
  currentWorkspaceUser = new BehaviorSubject<WorkspaceUser>(undefined);

  constructor(private fireDB: AngularFirestore,
              private fireAuth: AngularFireAuth,
              private sidebarManagerService: SidebarManagerService
  ) {
    this.fireAuth.onAuthStateChanged(res => {
      this.currentFireUser = res;
      this.sidebarManagerService.enableSidebar();
    });

    this.currentWorkspace.subscribe(newWorkspace => {
      if (newWorkspace && typeof newWorkspace.id !== 'undefined') {
        this.fireDB.doc<WorkspaceUser>(this.getPathToCurrentWorkspace() + '/workspaceUsers/' + this.currentFireUser.email).get().subscribe(resCurrentWorkspaceUser => {
          let userData = resCurrentWorkspaceUser.data();
          this.currentWorkspaceUser.next(this.deserializeUser(userData));
        });
      }
    });
  }

  serializeObject(object) {
    return JSON.parse(JSON.stringify(object));
  }

  getPathToCurrentWorkspace() {
    return 'workspaces/' + this.currentWorkspace.getValue().id;
  }

  getPathToCurrentWorkspaceMeteredUsage() {
    let date = new Date();
    let timePath = date.getFullYear() + '_' + (date.getMonth() + 1);
    return this.getPathToCurrentWorkspace() + '/meteredUsage/' + timePath;
  }

  getCurrentFireUser() {
    return this.currentFireUser;
  }

  deserializeUnit(unitData:Unit):Unit {
    let unit: Unit = new Unit(unitData.id,
      unitData.ip,
      unitData.username || '',
      unitData.password || '',
      unitData.name || '',
      unitData.group_id || '',
      unitData.dhcp_on || true,
      unitData.network_mask || '',
      unitData.network_gateway || '',
      unitData.dns_primary || '',
      unitData.dns_secondary || '',
      unitData.timezone || '',
      unitData.scheduler_enabled || true,
      unitData.location || '');

    unit.is_disconnected = (typeof unitData.is_disconnected !== 'undefined' ? unitData.is_disconnected : false);

    return unit;
  }

  deserializeUnitGroup(unitGroupData:UnitGroup):UnitGroup {
    return new UnitGroup(
      unitGroupData.name || '',
      unitGroupData.id || '');
  }

  deserializeUser(userData: WorkspaceUser) {
    return new WorkspaceUser(
      userData.workspace_id || this.currentWorkspace.value.id,
      userData.email || '',
      userData.role || '',
      userData.status || 'active',
      userData.passwordExpiration || '',
      userData.password || '',
      userData.isRemembered || false,
      userData.first_name || '',
      userData.last_name || '',
      userData.occupation || '',
      userData.organization || '',
      userData.phone_number || '',
      userData.state || '',
      userData.address || '',
      userData.zip || '',
      userData.country || ''
    )
  }

  deserializeSchedule(scheduleData: Schedule) {
    return new Schedule(
      scheduleData.object_type || undefined,
      scheduleData.object_id || '',
      scheduleData.dhtmlx_json_data || undefined,
      scheduleData.draft || undefined,
      scheduleData.modified || undefined
    )
  }

  deserializeSlide(slideData:Slide):Slide {
    return new Slide(
      slideData.name || '',
      slideData.renderedHTML || undefined,
      slideData.durationInSeconds || undefined,
      slideData.components || [],
      slideData.html || '',
      slideData.css || '',
      slideData.id || '',
      slideData.highest_zIndex || undefined,
      slideData.thumbnail || '',
      slideData.assets || [],
      slideData.styles || [],
      slideData.dimensions || undefined,
      slideData.draft || undefined,
      slideData.interactiveMode || false,
      slideData.modified || undefined,
    );
  }

  deserializePlaylist(playlistData:Playlist):Playlist {
    return new Playlist(
      playlistData.name || '',
      playlistData.slide_ids  || [],
      playlistData.id || '',
      playlistData.modified || undefined
    );

  }

  deserializeTemplate(templateData:Template):Template {
    return new Template(
      templateData.name,
      templateData.renderedHTML,
      templateData.durationInSeconds,
      templateData.components,
      templateData.html,
      templateData.css,
      templateData.highest_zIndex,
      templateData.thumbnail,
      templateData.assets,
      templateData.styles,
      templateData.id,
      templateData.dimensions,
      templateData.isInteractiveModeOn,
      templateData.isTutorial
    );
  }
}
